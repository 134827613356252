import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { logo } from "assets";
import "./HeaderWhite.scss";

interface IProps {
  title?: string;
}
export class HeaderWhite extends Component<IProps> {
  render() {
    const {
      props: { title },
    } = this;
    return (
      <div className="opt-out-header">
        <Link to="/auth/login">
          <img className="logo" src={logo} alt="Intent.ai" />
        </Link>
        {title && (
          <h1 className="title">
            <FormattedMessage
              id={title || ""}
              defaultMessage="Terms and Conditions"
            />
          </h1>
        )}
      </div>
    );
  }
}

// Header.displayName = "HeaderWhite";
