import React from 'react';
import {
  ng_banner_ads,
  ng_banner_ads_mobile, ng_banner_ads_mobile_webp, ng_banner_ads_webp,
  ng_dynamic_ads,
  ng_dynamic_ads_mobile, ng_dynamic_ads_mobile_webp, ng_dynamic_ads_webp,
  ng_html_ads,
  ng_html_ads_mobile, ng_html_ads_mobile_webp, ng_html_ads_webp,
} from "../../../assets";
import { ng_voice_ads } from 'assets';
import {
  tn_banner_avif,
  tn_banner_mobile_avif,
  tn_banner_mobile_png,
  tn_banner_mobile_webp,
  tn_banner_png,
  tn_banner_webp,
  tn_dynamic_ads_avif,
  tn_dynamic_ads_mobile_avif,
  tn_dynamic_ads_mobile_png,
  tn_dynamic_ads_mobile_webp,
  tn_dynamic_ads_png,
  tn_dynamic_ads_webp,
  tn_html_avif,
  tn_html_mobile_avif,
  tn_html_mobile_png,
  tn_html_mobile_webp,
  tn_html_png,
  tn_html_webp,
} from "../../../assets/tn";
import {
  sa_airtel,
  sa_banner_png, sa_betway, sa_google,
  sa_html_png,
  sa_kimprinski, sa_menu,
  sa_native_png,
  sa_ooredoo, sa_pepsi, sa_pubnative, sa_smatoo, sa_taboola, sa_verve,
  sa_video_png,
} from "../../../assets/sa";

export const FEATURES = [
  {
    id: 0,
    title: 'No Cookie dependency',
    description: 'Reduce the guesswork around targeting criteria and the miss match of user persona and targeting criteria other tools provide.',
    label: '01',
  },
  {
    id: 1,
    title: "Competitors' clients are just a click away",
    description: 'Win your competitors users, run loyalty and retention campaigns, segment users by their interests and the websites they browse.',
    label: '02',
  },
  {
    id: 2,
    title: '20% Cheaper than all other ad platforms',
    description: 'Deploy the latest innovations in the field of AI in Ad tech for the benefit your campaigns. Save advertising dollars and create highly valuable campaigns',
    label: '03',
  },
  {
    id: 3,
    title: 'Integration and Reporting API',
    description: 'Our API is available for integration with any analytics and reporting platform you may be using',
    label: '04',
  },
  {
    id: 4,
    title: 'Pay-as-you-go',
    description: 'Our pay-as-you-go model makes it easy for businesses of all sizes to access our expert ad technology and enjoy maximum ROI without breaking the bank.',
    label: '05',
  },
  {
    id: 5,
    title: <>360<span>&#176;</span> Support</>,
    description: 'We are committed to providing our clients with the best possible experience. Whether you need help setting up your account, optimizing your ads, or troubleshooting any issues, we are here to help.',
    label: '06',
  }
];


export const NG_FEATURES = [
  {
    id: 0,
    title: 'No Cookie dependency',
    description: 'Reduce the guesswork around targeting criteria and the miss match of user persona and targeting criteria other tools provide.',
    label: '01',
  },
  {
    id: 1,
    title: "Competitors' clients are just a click away",
    description: 'Win your competitors users, run loyalty and retention campaigns, segment users by their interests and the websites they browse.',
    label: '02',
  },
  {
    id: 2,
    title: 'The power of programmatic with the connection of voice',
    description: 'For the first time you can also target feature phone users with digital precision, with Airtel Ads’ exclusive Rewarded Voice Ads',
    label: '03',
  },
  {
    id: 3,
    title: 'Integration and Reporting API',
    description: 'Our API is available for integration with any analytics and reporting platform you may be using',
    label: '04',
  },
  {
    id: 4,
    title: 'Pay-as-you-go',
    description: 'Our pay-as-you-go model makes it easy for businesses of all sizes to access our expert ad technology and enjoy maximum ROI without breaking the bank.',
    label: '05',
  },
  {
    id: 5,
    title: <>360<span>&#176;</span> Support</>,
    description: 'We are committed to providing our clients with the best possible experience. Whether you need help setting up your account, optimizing your ads, or troubleshooting any issues, we are here to help.',
    label: '06',
  }
];

export const AD_TYPES = [
  {
    id: 0,
    title: 'Banner Ads',
    description: 'Use JPEG or PNG files for each of the sizes recommended',
    image: ng_banner_ads,
    imageWebp: ng_banner_ads_webp,
    alt: 'Banner Ads',
    mobImage: ng_banner_ads_mobile,
    mobImageWebp: ng_banner_ads_mobile_webp,
    size: 183,
    mobSize: 120,
  },
  {
    id: 1,
    title: 'HTML',
    description: 'Create and upload animated banners',
    image: ng_html_ads,
    imageWebp: ng_html_ads_webp,
    alt: 'HTML Ads',
    mobImage: ng_html_ads_mobile,
    mobImageWebp: ng_html_ads_mobile_webp,
    size: 183,
    mobSize: 120,
  },
  {
    id: 2,
    title: 'Dynamic Ads',
    description: 'Reduce the manual work, input all the details and enjoy your dynamic ads generated for all the sizes.',
    image: ng_dynamic_ads,
    imageWebp: ng_dynamic_ads_webp,
    alt: 'Dynamic Ads',
    mobImage: ng_dynamic_ads_mobile,
    mobImageWebp: ng_dynamic_ads_mobile_webp,
    size: 183,
    mobSize: 120,
  }
]

export const TN_AD_TYPES = [
  {
    id: 0,
    title: 'Banner Ads',
    description: 'Use JPEG or PNG files for each of the sizes recommended',
    image: tn_banner_png,
    imageWebp: tn_banner_webp,
    imageAvif: tn_banner_avif,
    alt: 'Banner Ads',
    mobImage: tn_banner_mobile_png,
    mobImageWebp: tn_banner_mobile_webp,
    mobImageAvif: tn_banner_mobile_avif,
    size: 183,
    mobSize: 120,
  },
  {
    id: 1,
    title: 'HTML',
    description: 'Create and upload animated banners',
    image: tn_html_png,
    imageWebp: tn_html_webp,
    imageAvif: tn_html_avif,
    alt: 'HTML Ads',
    mobImage: tn_html_mobile_png,
    mobImageWebp: tn_html_mobile_webp,
    mobImageAvif: tn_html_mobile_avif,
    size: 183,
    mobSize: 120,
  },
  {
    id: 2,
    title: 'Dynamic Ads',
    description: 'Reduce the manual work, input all the details and enjoy your dynamic ads generated for all the sizes.',
    image: tn_dynamic_ads_png,
    imageWebp: tn_dynamic_ads_webp,
    imageAvif: tn_dynamic_ads_avif,
    alt: 'Dynamic Ads',
    mobImage: tn_dynamic_ads_mobile_png,
    mobImageWebp: tn_dynamic_ads_mobile_webp,
    mobImageAvif: tn_dynamic_ads_mobile_avif,
    size: 183,
    mobSize: 120,
  }
];

export const NG_AD_TYPES = [
  {
    id: 0,
    title: 'Banner Ads',
    description: 'Use JPEG or PNG files for each of the sizes recommended',
    image: tn_banner_png,
    imageWebp: tn_banner_webp,
    imageAvif: tn_banner_avif,
    alt: 'Banner Ads',
    mobImage: tn_banner_mobile_png,
    mobImageWebp: tn_banner_mobile_webp,
    mobImageAvif: tn_banner_mobile_avif,
    size: 183,
    mobSize: 120,
  },
  {
    id: 1,
    title: 'HTML',
    description: 'Create and upload animated banners',
    image: tn_html_png,
    imageWebp: tn_html_webp,
    imageAvif: tn_html_avif,
    alt: 'HTML Ads',
    mobImage: tn_html_mobile_png,
    mobImageWebp: tn_html_mobile_webp,
    mobImageAvif: tn_html_mobile_avif,
    size: 183,
    mobSize: 120,
  },
  {
    id: 2,
    title: 'Dynamic Ads',
    description: 'Reduce the manual work, input all the details and enjoy your dynamic ads generated for all the sizes.',
    image: tn_dynamic_ads_png,
    imageWebp: tn_dynamic_ads_webp,
    imageAvif: tn_dynamic_ads_avif,
    alt: 'Dynamic Ads',
    mobImage: tn_dynamic_ads_mobile_png,
    mobImageWebp: tn_dynamic_ads_mobile_webp,
    mobImageAvif: tn_dynamic_ads_mobile_avif,
    size: 183,
    mobSize: 120,
  },
  {
    id: 3,
    title: 'Rewarded Voice Ads',
    description: 'Now, you have the ability to precisely target feature phone users using digital technology for the first time, thanks to Airtel Ads',
    image: tn_dynamic_ads_png,
    imageWebp: tn_dynamic_ads_webp,
    imageAvif: tn_dynamic_ads_avif,
    mobImage: tn_dynamic_ads_mobile_png,
    mobImageWebp: tn_dynamic_ads_mobile_webp,
    mobImageAvif: tn_dynamic_ads_mobile_avif,
    alt: 'Voice Ads',
    size: 183,
    mobSize: 120,
  },
];

export const SA_AD_TYPES = [
  {
    id: 0,
    title: 'Native Ads',
    description: 'Seamlessly integrate your ads with the content, providing a natural and engaging user experience.',
    image: sa_native_png,
    imageWebp: sa_native_png,
    imageAvif: sa_native_png,
    alt: 'Native Ads',
    mobImage: sa_native_png,
    mobImageWebp: sa_native_png,
    mobImageAvif: sa_native_png,
    size: 183,
    mobSize: 120,
  },
  {
    id: 1,
    title: 'Video Ads',
    description: 'Capture attention with visually compelling and dynamic video content that tells your brand’s story.',
    image: sa_video_png,
    imageWebp: sa_video_png,
    imageAvif: sa_video_png,
    alt: 'Video Ads',
    mobImage: sa_video_png,
    mobImageWebp: sa_video_png,
    mobImageAvif: sa_video_png,
    size: 183,
    mobSize: 120,
  },
  {
    id: 2,
    title: 'Banner Ads',
    description: 'Utilize eye-catching designs to attract potential customers and drive clicks.',
    image: sa_banner_png,
    imageWebp: sa_banner_png,
    imageAvif: sa_banner_png,
    alt: 'Banner Ads',
    mobImage: sa_banner_png,
    mobImageWebp: sa_banner_png,
    mobImageAvif: sa_banner_png,
    size: 183,
    mobSize: 120,
  },
  {
    id: 3,
    title: 'HTML Ads',
    description: 'Customize and create interactive ads that stand out and engage users with unique features.',
    image: sa_html_png,
    imageWebp: sa_html_png,
    imageAvif: sa_html_png,
    mobImage: sa_html_png,
    mobImageWebp: sa_html_png,
    mobImageAvif: sa_html_png,
    alt: 'HTML Ads',
    size: 183,
    mobSize: 120,
  },
];

export const TN_FEATURES = [
  {
    id: 0,
    title: 'No Cookie dependency',
    description: 'Reduce the guesswork around targeting criteria and the miss match of user persona and targeting criteria other tools provide.',
    label: '01',
  },
  {
    id: 1,
    title: "Competitors' clients are just a click away",
    description: 'Win your competitors users, run loyalty and retention campaigns, segment users by their interests.',
    label: '02',
  },
  {
    id: 2,
    title: '20% Cheaper than all other ad platforms',
    description: 'Deploy the latest innovations in the field of AI in Ad tech for the benefit your campaigns. Save advertising dollars and create highly valuable campaigns.',
    label: '03',
  },
  {
    id: 3,
    title: 'Integration and Reporting API',
    description: 'Our API is available for integration with any analytics and reporting platform you may be using.',
    label: '04',
  },
  {
    id: 4,
    title: 'Pay-as-you-go',
    description: 'Our pay-as-you-go model makes it easy for businesses of all sizes to access our expert ad technology and enjoy maximum ROI without breaking the bank.',
    label: '05',
  },
  {
    id: 5,
    title: <>360<span>&#176;</span> Support</>,
    description: 'We are committed to providing our clients with the best possible experience. Whether you need help setting up your account, optimizing your ads, or troubleshooting any issues, we are here to help.',
    label: '06',
  }
];
export const SA_FEATURES = [
  {
    id: 0,
    title: 'Premium Traffic, Wider Reach',
    description: 'Top-tier traffic to engage the right audience effectively.',
    label: '01',
  },
  {
    id: 1,
    title: "Native Language Support",
    description: 'Engage the Saudi market with ads tailored in Arabic, enhancing communication and connection.',
    label: '02',
  },
  {
    id: 2,
    title: 'Dedicated Account Manager',
    description: ' Personalized attention from an expert who optimizes your strategies.',
    label: '03',
  },
  {
    id: 3,
    title: <>Comprehensive 360<span>&#176;</span> Support</>,
    description: 'From planning to execution, we’re with you at every stage.',
    label: '04',
  },
  {
    id: 4,
    title: 'Detailed Reporting and Analytics',
    description: 'Gain insights and refine strategies with clear reports.',
    label: '05',
  },
  {
    id: 5,
    title: 'Expert Design Support',
    description: ' Visually appealing ads crafted by our talented designers.',
    label: '06',
  }
];

export const SA_ADV_PARTNERS = [
  {
    image: sa_airtel,
    alt: 'airtel logo',
  },
  {
    image: sa_ooredoo,
    alt: 'ooredoo logo',
  },
  {
    image: sa_kimprinski,
    alt: 'kimprinski logo',
  },
  {
    image: sa_pepsi,
    alt: 'pepsi logo',
  },
  {
    image: sa_betway,
    alt: 'betway logo',
  },
  {
    image: sa_menu,
    alt: 'menu logo',
  },
]
export const SA_AD_EXCHANGE_PARTNERS = [
  {
    image: sa_google,
    alt: 'google logo',
  },
  {
    image: sa_verve,
    alt: 'verve logo',
  },
  {
    image: sa_smatoo,
    alt: 'smatoo logo',
  },
  {
    image: sa_pubnative,
    alt: 'pubnative logo',
  },
  {
    image: sa_taboola,
    alt: 'taboola logo',
  },
]
