import { FetchUtils } from "@intent-ai/mandal-npm-lib-v2/dist/utils";

const { createRequest } = FetchUtils;

export const getCampaignTargetingInfo = (campaignId: string) =>
  createRequest(`/campaign/target/${campaignId}`);

export const sendReprotAdRequest = (
  campaignId: string,
  reportReasonKey: number
) => createRequest(`/campaign/report/${campaignId}/${reportReasonKey}`);
