export const RT_OK = 0;
export const RT_BACKEND_EXCEPTION = 1;
export const RT_INVALID_ARGUMENT = 2;
export const RT_ILLEGAL_ACTION = 3;
export const RT_TOKEN_EXPIRED = 4;
export const RT_TOKEN_INVALID = 5;
export const RT_USER_NOT_FOUND = 6;
export const RT_USER_ACCOUNT_NOT_FOUND = 7;
export const RT_DATE_FORMAT_INVALID = 11;
export const RT_JWT_SPACE_ID_NOT_FOUND = 20;

// Space related

export const RT_REGISTRATION_MAIL_EXIST = 160;
export const RT_REGISTRATION_NOT_FOUND = 161;
export const RT_REGISTERED_USER_VERIFIED = 162;
export const RT_REGISTERED_USER_NOT_VERIFIED = 163;
