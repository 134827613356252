// @ts-nocheck
export { default as flag } from './images/tn/flag.png';
export { default as tn_main_jpg } from './images/tn/main.jpg';
export { default as tn_main_webp } from './images/tn/main.webp';
export { default as tn_main_avif } from './images/tn/main.avif';
export { default as tn_main_mobile_jpg } from './images/tn/main_mobile.jpg';
export { default as tn_main_mobile_webp } from './images/tn/main_mobile.webp';
export { default as tn_main_mobile_avif } from './images/tn/main_mobile.avif';
export { default as tn_solution_1_webp } from './images/tn/solution-1.webp';
export { default as tn_solution_1_avif } from './images/tn/solution-1.avif';
export { default as tn_solution_2_webp } from './images/tn/solution-2.webp';
export { default as tn_solution_2_avif } from './images/tn/solution-2.avif';
export { default as tn_solution_3_webp } from './images/tn/solution-3.webp';
export { default as tn_solution_3_avif } from './images/tn/solution-3.avif';
export { default as tn_solution_4_webp } from './images/tn/solution-4.webp';
export { default as tn_solution_4_avif } from './images/tn/solution-4.avif';
export { default as tn_banner_png } from './images/tn/banner.png';
export { default as tn_banner_webp } from './images/tn/banner.webp';
export { default as tn_banner_avif } from './images/tn/banner.avif';
export { default as tn_banner_mobile_png } from './images/tn/banner_mobile.png';
export { default as tn_banner_mobile_webp } from './images/tn/banner_mobile.webp';
export { default as tn_banner_mobile_avif} from './images/tn/banner_mobile.avif';
export { default as tn_html_png } from './images/tn/html.png';
export { default as tn_html_webp } from './images/tn/html.webp';
export { default as tn_html_avif } from './images/tn/html.avif';
export { default as tn_html_mobile_png } from './images/tn/html_mobile.png';
export { default as tn_html_mobile_webp } from './images/tn/html_mobile.webp';
export { default as tn_html_mobile_avif } from './images/tn/html_mobile.avif';
export { default as tn_dynamic_ads_png } from './images/tn/dynamic_ads.png';
export { default as tn_dynamic_ads_webp } from './images/tn/dynamic_ads.webp';
export { default as tn_dynamic_ads_avif } from './images/tn/dynamic_ads.avif';
export { default as tn_dynamic_ads_mobile_png } from './images/tn/dynamic_ads_mobile.png';
export { default as tn_dynamic_ads_mobile_webp } from './images/tn/dynamic_ads_mobile.webp';
export { default as tn_dynamic_ads_mobile_avif } from './images/tn/dynamic_ads_mobile.avif';




