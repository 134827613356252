import nprogress from "nprogress";

nprogress.configure({ showSpinner: false, parent: "#root" });

export const NprogressMiddleware = (store: any) => (next: any) => (
  action: any
) => {
  if (action.type.endsWith("_START")) {
    nprogress.start();
  }
  if (action.type.endsWith("_SUCCESS") || action.type.endsWith("_FAIL")) {
    nprogress.done();
  }
  next(action);
};
