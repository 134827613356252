import React from "react";
import "./style.scss";

const OrderDemoDSP = () => {
  return (
    <section className="dsp-sizes-wrapper">
      <div className="dsp-sizes-inner">
        <ul className="dsp-sizes-list">
          <li>
            <p>
              <span className="dsp-size-icon" />
              5.41<span>mln</span>
            </p>
            <p>Users to tailor your audience segments from</p>
          </li>
          <li>
            <p>
              <span className="dsp-size-icon" /> 735<span>mln</span>
            </p>
            <p>Impressions served monthly and still counting </p>
          </li>
          <li>
            <p
              className="dsp-size-largest"
              // style={{
              //   fontSize: "100px",
              // }}
            >
              All
            </p>
            <p>Display Advertising Platforms in one tool</p>
          </li>
        </ul>
      </div>
    </section>
  );
};

export default OrderDemoDSP;
