import * as React from "react";
import { SVGProps, memo } from "react";

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={"1em"}
    height={"1em"}
    viewBox="0 0 60 60"
    {...props}
  >
    <title>{"icon/service/behavioral@3x"}</title>
    <defs>
      <linearGradient id="a" x1="50%" y1="0%" x2="50%" y2="100%">
        <stop offset="0%" stop-color="#7A5FFF">
          <animate
            attributeName="stop-color"
            values="#7A5FFF; #01FF89; #7A5FFF"
            dur="4s"
            repeatCount="indefinite"
          ></animate>
        </stop>

        <stop offset="100%" stop-color="#01FF89">
          <animate
            attributeName="stop-color"
            values="#62A8F4; #336EE0; #62A8F4"
            dur="4s"
            repeatCount="indefinite"
          ></animate>
        </stop>
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd">
      <circle fill="#FFF" cx={30} cy={26} r={14} />
      <path d="M0 0h60v60H0z" />
      <path
        className="icon-path"
        d="M25.316 19.975c.031 2.515 2.218 4.657 4.682 4.588 2.49-.071 4.546-2.192 4.518-4.662-.028-2.483-2.135-4.59-4.59-4.589-2.5.001-4.64 2.168-4.61 4.663m4.665 7.919c-1.61 0-3.22-.007-4.83.002-1.641.01-2.84.785-3.627 2.201-.405.73-.807 1.474-1.09 2.255-.122.339-.082.903.128 1.17 1.78 2.256 4.07 3.714 6.908 4.277 4.778.947 8.66-.596 11.814-4.222.365-.419.42-.789.132-1.288-.417-.721-.73-1.503-1.134-2.232-.771-1.392-1.968-2.132-3.56-2.157-1.58-.025-3.16-.005-4.741-.006M46 26.038c-.112 4.905-1.983 8.867-5.766 11.921-.79.638-1.55 1.315-2.356 1.933-2.503 1.921-4.213 4.366-4.976 7.456-.072.289-.188.567-.285.85-.517 1.506-1.01 1.818-2.845 1.801-1.327-.011-2.074-.477-2.385-1.841-.878-3.853-3.087-6.715-6.215-9.004-1.857-1.358-3.596-2.873-4.89-4.827-1.88-2.84-2.474-6.011-2.23-9.339.366-4.997 2.552-9.054 6.624-11.982 3.439-2.471 7.291-3.48 11.506-2.797 6.497 1.055 10.766 4.816 12.882 11.004.525 1.535.635 3.213.936 4.825"
      />
    </g>
  </svg>
);

const SvgComponent2 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={"1em"}
    height={"1em"}
    viewBox="0 0 60 60"
    {...props}
  >
    <title>{"icon/service/location seleced@3x"}</title>
    <defs>
      <linearGradient
        x1="70.456%"
        y1="-24.385%"
        x2="28.127%"
        y2="106.589%"
        id="b"
      >
        <stop stopColor="#62A8F4" offset="0%" />
        <stop stopColor="#336EE0" offset="100%" />
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd">
      <circle fill="#FFF" cx={30} cy={26} r={14} />
      <path d="M0 0h60v60H0z" />
      <path
        d="M46 26.038c-.112 4.905-1.983 8.867-5.766 11.921-.79.638-1.55 1.315-2.356 1.933-2.503 1.921-4.213 4.366-4.976 7.456-.072.289-.188.567-.285.85-.517 1.506-1.01 1.818-2.845 1.801-1.327-.011-2.074-.477-2.385-1.841-.878-3.853-3.087-6.715-6.215-9.004-1.857-1.358-3.596-2.873-4.89-4.827-1.88-2.84-2.474-6.011-2.23-9.339.366-4.997 2.552-9.054 6.624-11.982 3.439-2.471 7.291-3.48 11.506-2.797 6.497 1.055 10.766 4.816 12.882 11.004.525 1.535.635 3.213.936 4.825"
        // fill="url(#a)"
      />
      <path
        d="m27.494 30.709-4.205-4.292a1.03 1.03 0 0 1 0-1.43l.351-.359a.98.98 0 0 1 1.401 0l3.209 3.275 6.709-6.608a.98.98 0 0 1 1.4 0l.352.358a1.03 1.03 0 0 1 0 1.43l-7.705 7.626a.972.972 0 0 1-.756.29.974.974 0 0 1-.756-.29Z"
        fill="#FFF"
      />
    </g>
  </svg>
);
const SvgComponent3 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={"1em"}
    height={"1em"}
    viewBox="0 0 60 60"
    {...props}
  >
    <title>{"icon/service/location targeted@3x"}</title>
    <defs>
      <linearGradient
        x1="70.456%"
        y1="-24.385%"
        x2="28.127%"
        y2="106.589%"
        id="c"
      >
        <stop stopColor="#62A8F4" offset="0%" />
        <stop stopColor="#336EE0" offset="100%" />
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd">
      <circle fill="#FFF" cx={30} cy={26} r={14} />
      <path d="M0 0h60v60H0z" />
      <path
        d="m27.494 30.709-4.205-4.292a1.03 1.03 0 0 1 0-1.43l.351-.359a.98.98 0 0 1 1.401 0l3.209 3.275 6.709-6.608a.98.98 0 0 1 1.4 0l.352.358a1.03 1.03 0 0 1 0 1.43l-7.705 7.626a.972.972 0 0 1-.756.29.974.974 0 0 1-.756-.29Z"
        fill="#FFF"
      />
      <path
        className="icon-path-target"
        d="M20.677 13.006c3.438-2.471 7.29-3.48 11.505-2.797 6.497 1.055 10.766 4.815 12.882 11.004.525 1.535.635 3.213.936 4.825-.112 4.905-1.982 8.867-5.766 11.921-.79.638-1.55 1.315-2.355 1.933-2.504 1.921-4.214 4.367-4.977 7.456-.071.289-.188.566-.285.849-.517 1.507-1.01 1.819-2.845 1.803-1.327-.012-2.074-.478-2.385-1.842-.878-3.853-3.087-6.715-6.215-9.004-1.857-1.358-3.596-2.874-4.89-4.827-1.88-2.84-2.474-6.011-2.23-9.34.366-4.996 2.553-9.053 6.625-11.98Zm10.31 3.807c.004-1.286-.066-1.354-1.346-1.291-.424.02-.624.216-.636.646-.012.376-.013.753-.002 1.13.008.276-.107.384-.378.448-.61.145-1.234.279-1.804.527-2.584 1.124-4.156 3.113-4.814 5.844-.162.675-.146.68-.848.68h-.277c-1.093.007-1.152.119-1.104 1.303.02.489.196.668.697.682.345.01.69.009 1.035-.001.286-.009.416.096.477.4.107.542.228 1.091.436 1.599 1.085 2.646 3.042 4.29 5.796 4.993.697.178.773.2.78.792v.391c.002 1.085.089 1.123 1.27 1.097h.045c.465-.017.655-.2.667-.659.01-.361.013-.723 0-1.084-.009-.287.09-.417.392-.485.61-.138 1.234-.276 1.805-.524 2.586-1.127 4.146-3.127 4.805-5.855.158-.657.131-.664.816-.664h.326c1.08-.009 1.147-.131 1.097-1.313-.02-.458-.206-.657-.663-.674-.375-.013-.75-.005-1.125 0-.245.003-.374-.085-.424-.352-.09-.469-.185-.945-.353-1.39-1.102-2.917-3.184-4.696-6.237-5.302-.307-.06-.465-.16-.437-.486.013-.15.003-.301.004-.452Zm-1.013 2.765c3.46-.004 6.204 2.735 6.21 6.198a6.176 6.176 0 0 1-6.192 6.221c-3.44 0-6.195-2.767-6.192-6.222.004-3.427 2.76-6.194 6.174-6.197Zm.02 4.189c-1.068 0-2.004.944-2.003 2.021.002 1.083.933 2.02 2.004 2.02 1.07-.002 2.002-.944 2.002-2.023.001-1.081-.928-2.018-2.003-2.018Z"
        // fill="url(#c)"
      />
    </g>
  </svg>
);
const HumanIcon = memo(SvgComponent);
const CheckIcon = memo(SvgComponent2);
const TargetIcon = memo(SvgComponent3);

export { HumanIcon, CheckIcon, TargetIcon };
