import React from 'react';
import styles from './index.module.css';
import classnames from "classnames";

type Props = {
  redirectLink?: string;
  classes?: {
    bookDemo?: string;
    button?: string;
  }
}

const BookDemo = ({
  classes = {}, redirectLink = ''
                  }: Props) => {
  return (
    <div className={classnames(styles.bookDemo, classes.bookDemo)}>
      <h4 className={styles.headerText}>Ready to see our product in action?</h4>
      <a
        href={redirectLink}
        className={classnames(styles.bookDemoButton, classes.button)}
        target='_blank'
      >Book a Demo</a>
    </div>
  );
}

export default BookDemo;
