import React, { FunctionComponent, ReactNode } from "react";
import './style.scss';
import { logo_white } from "../../assets";
import { FacebookIcon, LinkedInIcon } from "../Icons";
import classNames from "classnames";
import classnames from "classnames";

interface IProps {
  classes?: Partial<{
    footer: string;
    socialIcon?: string;
  }>;
  facebookURL: string;
  linkedInURL: string;
  phone?: string;
  privacyPolicyURL: string;
  termsOfServiceURL: string;
  companyDetailsPerCountry?: ReactNode;
  formattedPhoneNum?: string;
  compact?: boolean;
}

const Footer: FunctionComponent<IProps> = ({
  classes = {},
  facebookURL,
  linkedInURL,
  phone,
  privacyPolicyURL,
  termsOfServiceURL,
  companyDetailsPerCountry = null,
  formattedPhoneNum,
  compact = false,
}) => {
  return (
    <footer className={classNames(classes.footer, 'footer', compact && 'footer-compact')}>
      <div style={compact ? { display: 'flex', alignItems: 'center' } : {}}>
        <div className='footer-company-info'>
          {!compact && (
            <div className='footer-company-details'>
              <img width={130} height={40} src={logo_white} alt="Logo" />
              <p>
                Intent.ai, a company founded in 2018 in the United States,
                specializes in big data and AI.
                The company has presence in USA, Nigeria, Tunisia and Armenia.
              </p>
              {companyDetailsPerCountry}
            </div>
          )}
          <div className='footer-company-social'>
            <div className='footer-company-social-icons'>
              <a
                href={facebookURL}
                target="_blank"
                className={classnames('privacy', classes.socialIcon)}
                rel="noopener noreferrer"
              >
                <FacebookIcon />
              </a>
              <a
                href={linkedInURL}
                target="_blank"
                className={classnames('privacy', classes.socialIcon)}
                rel="noopener noreferrer"
              >
                <LinkedInIcon />
              </a>
            </div>
            <p className="footer-rights">
              © {new Date().getFullYear()} Intent LLC. All Rights Reserved
            </p>
          </div>
        </div>
        <div className='footer-company-contact'>
          {!compact && (
            <div>
              <div className='footer-company-contact-details'>
                <p>
                  Drop us a line
                </p>
                <a href="mailto: demo@intent.ai">info@intent.ai</a>
              </div>
              {phone && (<div className="footer-company-contact-details footer-company-phone">
                <p>Call us</p>
                <a href={`tel:${formattedPhoneNum || phone}`}>{phone}</a>
              </div>)}
            </div>
          )}
          <div className='footer-company-social footer-company-social-mobile'>
            <div className='footer-company-social-icons'>
              <a
                href={facebookURL}
                target="_blank"
                className={classnames('privacy', classes.socialIcon)}
                rel="noopener noreferrer"
              >
                <FacebookIcon />
              </a>
              <a
                href={linkedInURL}
                target="_blank"
                className={classnames('privacy', classes.socialIcon)}
                rel="noopener noreferrer"
              >
                <LinkedInIcon />
              </a>
            </div>
          </div>
          <div className='footer-company-security'>
            <a
              href={privacyPolicyURL}
              target="_blank"
              className="privacy"
              rel="noopener noreferrer"
            >Privacy Policy</a>
            ・
            <a
              href={termsOfServiceURL}
              target="_blank"
              className="privacy"
              rel="noopener noreferrer"
            >Terms of Service</a>
            ・
            <a
              href='https://about.intent.ai/'
              target="_blank"
              className="privacy"
              rel="noopener noreferrer"
            >About Intent.ai</a>
          </div>
        </div>
        <p className="footer-rights-mobile">
          © {new Date().getFullYear()} Intent LLC. All Rights Reserved
        </p>
      </div>
    </footer>
  );
}

export default Footer;
