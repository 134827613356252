import React, { ReactNode, useCallback, useMemo, useState } from "react";
import { BigArrowLeftIcon, BigArrowRightIcon } from "../../../../components/Icons";
import classnames from "classnames";
import styles from './index.module.css';
import { FEATURES } from "../../utils";

type Props = {
  title?: string
  description?: string
  classes?: {
    features?: string;
    featureItemDesktop?: string;
    featureItemSelected?: string;
    countrySymbol?: string;
  };
  features?: Array<{
    id: number;
    title: string | ReactNode;
    description: string;
    label: string;
  }>;
}

const Features = function ({
  title,
  description,
  classes = {},
  features = FEATURES,
}: Props) {
  const [selected, setSelected] = useState(0);

  const selectionInfo = useMemo(() => ({
    firstSelected: features[0].id === selected,
    lastSelected: features[features.length - 1].id === selected,
  }), [selected]);

  const handleArrowRightClick = useCallback(() => {
    if (!selectionInfo.lastSelected) {
      setSelected(selected => selected + 1);
    }
  }, [selectionInfo.lastSelected]);

  const handleArrowLeftClick = useCallback(() => {
    if (!selectionInfo.firstSelected) {
      setSelected(selected => selected - 1);
    }
  }, [selectionInfo.firstSelected]);

  return (
    <>
      <section className={classnames(styles.ngDspAbout, classes.features)}>
        <div className={styles.trafficWrapperInner}>
          <div className={styles.trafficTitle}>
            {title ? title : 'Our Standout Features'}
          </div>
          {description ? (
            <p className={styles.featuresDescription}>{description}</p>
          ): null}
          <div className={styles.trafficBoxContainer} style={{ transform: `translateX(-${(selected * 300)}px)` }}>
            {features.map(feature => (
              <div className={classnames(styles.trafficBox, styles.trafficBoxDesktop, {
                [styles.trafficBoxSelected]: selected === feature.id,
                [classes.featureItemSelected || '']: selected == feature.id,
              }, classes.featureItemDesktop)} key={feature.id}>
                <p className={styles.trafficBoxLabel}>{feature.label}</p>
                <p className={styles.trafficBoxTitle}>{feature.title}</p>
                <p className={styles.trafficBoxDescription}>
                  {feature.description}
                </p>
              </div>
            ))}
          </div>
          <div className={styles.arrowIconsWrapper}>
            <BigArrowLeftIcon active={!selectionInfo.firstSelected} className={classnames({
              [styles.active]: !selectionInfo.firstSelected,
            })} onClick={handleArrowLeftClick} />
            <BigArrowRightIcon active={!selectionInfo.lastSelected} className={classnames({
              [styles.active]: !selectionInfo.lastSelected,
            })} onClick={handleArrowRightClick} />
          </div>
        </div>
      </section>
      <div className={classnames(styles.nigSymbol, classes.countrySymbol)}/>
    </>
  );
}

export default Features;
