import React from "react";
import { IntlProvider } from "react-intl";
import localeData from "./i18n/data.json";
import packageJson from "../package.json";
import "nprogress/nprogress.css";
import { MainContainer } from "containers";
import { BrowserRouter } from "react-router-dom";
import "./App.scss";

console.log("Current version: " + packageJson.version);

const App = () => {
  const messages = localeData.en;
  return (
    <IntlProvider locale={"en"} messages={messages}>
      <BrowserRouter>
        <MainContainer />
      </BrowserRouter>
    </IntlProvider>
  );
};

export default App;
