import React from "react";
import "./style.scss";
import { img_background } from "../../assets";

const NGSizeSection = () => {
  return (
    <section className="ng-dsp-sizes-wrapper">
      <div className="dsp-sizes-inner">
        <ul className="dsp-sizes-list">
          <li>
            <p>
              <span className="dsp-size-icon" />
              87<span>%</span>
            </p>
            <div className='statistic'>
              <p className='statistic-title'>Precision</p>
              <p className='statistic-description'>
                Our patented matching and profiling technology provides unparalleled accuracy and effectiveness for your ad campaigns.
              </p>
            </div>
          </li>
          <li>
            <p>
              <span className="dsp-size-icon" /> 100<span>bln</span>
            </p>
            <div className='statistic'>
              <p className='statistic-title'>Ad Appearances Monthly</p>
              <p className='statistic-description'>
                Don't miss out on the potential of billions of monthly ad impressions - trust intent.ai to help you reach your marketing goals.              </p>
            </div>
          </li>
          <li>
            <p
            >
              <span className="dsp-size-icon" />
              69<span>mln</span>
            </p>
            <div className='statistic'>
              <p className='statistic-title'>Reachable Users</p>
              <p className='statistic-description'>
                With intent.ai, you can trust that your ad campaigns will reach the right audience, leading to increased ROI and business growth.
              </p>
            </div>
          </li>
          <li>
            <p>
              <span className="dsp-size-icon" /> 90<span>mln</span>
            </p>
            <div className='statistic'>
              <p className='statistic-title'>Opportunities to talk to your customer</p>
              <p className='statistic-description'>
                Experience the unparalleled effectiveness of our one-of-a-kind voice ad platform in Africa, boasting a 100% listening rate. Elevate your brand's message and ensure it resonates with your audience like never before.              </p>
            </div>
          </li>
        </ul>
      </div>
      <img src={img_background} alt="" loading='lazy' className='background-image' />
    </section>
  );
};

export default NGSizeSection;
