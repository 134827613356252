import React, { Component } from "react";
import { injectIntl, InjectedIntl, FormattedMessage } from "react-intl";
import { HeaderWhite } from "components/HeaderWhite";

interface IProps {
  intl: InjectedIntl;
}

class WrappedTermsAndConditionsSSP extends Component<IProps> {
  render() {
    return (
      <div className="sections-header-wrapper tos-wrapper">
        <HeaderWhite title="TermsConditions.title" />
        <div className="main-sections">
          <section className="sections">
            <p>
              <FormattedMessage
                id="TermsConditionsSSPstUpdated"
                defaultMessage="Թարմացվել է՝ Հուլիսի 13, 2020"
              />
            </p>
            <section className="section-description">
              <h1 className="section-header">
                <FormattedMessage
                  id="TermsConditionsSSP.sectionHeader1"
                  defaultMessage="1. Պայմանագրում օգտագործվող հիմնական հասկացություններ"
                />
              </h1>
              <ul className="tos-concepts">
                <li>
                  <div>
                    <span>1.1</span>
                  </div>
                  <div>
                    <span>
                      <FormattedMessage
                        id="TermsConditionsSSP.concept1header"
                        defaultMessage="Կատարող - "
                      />
                    </span>
                    <FormattedMessage
                      id="TermsConditionsSSP.concept1description"
                      defaultMessage="«Մանդալ Էյ Այ» սահմանափակ պատասխանատվությամբ ընկերություն"
                    />
                  </div>
                </li>
                <li>
                  <div>
                    <span>1.2</span>
                  </div>
                  <div>
                    <span>
                      <FormattedMessage
                        id="TermsConditionsSSP.concept2header"
                        defaultMessage="Պատվիրատու -  "
                      />
                    </span>
                    <FormattedMessage
                      id="TermsConditionsSSP.concept2description"
                      defaultMessage="իրավաբանական անձ, որը տրամադրելով` համապատասխան միջոցներ, տեղաբաշխում և տարածում է գովազդը:
                      "
                    />
                  </div>
                </li>
                <li>
                  <div>
                    <span>1.3</span>
                  </div>
                  <div>
                    <span>
                      <FormattedMessage
                        id="TermsConditionsSSP.concept3header"
                        defaultMessage="Գովազդատու -  "
                      />
                    </span>
                    <FormattedMessage
                      id="TermsConditionsSSP.concept3description"
                      defaultMessage="համակարգում գրանցված ցանկացած ֆիզիկական կամ իրավաբանական անձ, ով ցանկանում է տեղադրել և տարածել իր գովազդը:"
                    />
                  </div>
                </li>
                <li>
                  <div>
                    <span>1.4</span>
                  </div>
                  <div>
                    <span>
                      <FormattedMessage
                        id="TermsConditionsSSP.concept3header"
                        defaultMessage="Համակարգ -  "
                      />
                    </span>
                    <FormattedMessage
                      id="TermsConditionsSSP.concept3description"
                      defaultMessage="Պատվիրատուի ինտերնետային կայք (www. intent.ai), որը հնարավորություն է ընձեռնում Կատարողներին և Գովազդատուներին գրանցվելու, և Գովազդատուներին հնարավորություն է տալիս ներբեռնելու իրենց գովազդը, որը ավտոմատ կերպով ցուցադրվում է համակարգում գրանցված Կատարողների Գովազդային հատվածում։
                      "
                    />
                  </div>
                </li>
                <li>
                  <div>
                    <span>1.5</span>
                  </div>
                  <div>
                    <span>
                      <FormattedMessage
                        id="TermsConditionsSSP.concept4header"
                        defaultMessage="Գովազդային հատված` -  "
                      />
                    </span>
                    <FormattedMessage
                      id="TermsConditionsSSP.concept4description"
                      defaultMessage="Կատարողների  ինտերնետային կայքում Գովազդի ցուցադրման համար տրամադրվող տիրույթ։"
                    />
                  </div>
                </li>
                <li>
                  <div>
                    <span>1.6</span>
                  </div>
                  <div>
                    <span>
                      <FormattedMessage
                        id="TermsConditionsSSPncept4header"
                        defaultMessage="Գովազդի ցուցադրման արժեք  -  "
                      />
                    </span>
                    <FormattedMessage
                      id="TermsConditionsSSPncept4description"
                      defaultMessage="Պատվիրատուի կողմից յուրաքանչյուր գովազդի համար Գովազդատուին առաջարկվող գումարի չափ:
                      "
                    />
                  </div>
                </li>
                <li>
                  <div>
                    <span>1.7</span>
                  </div>
                  <div>
                    <span>
                      <FormattedMessage
                        id="TermsConditionsSSPncept5header"
                        defaultMessage="Գովազդ`  -  "
                      />
                    </span>
                    <FormattedMessage
                      id="TermsConditionsSSPncept5description"
                      defaultMessage="Գովազդատուի կողմից համակարգում տեղադրվող ապրանքների, գաղափարների կամ նախաձեռնությունների մասին տեղեկություն, որը տարածվում (ցուցադրվում) է Կատարողների կայքերի համապատասխան Գովազդային հատվածներում, անորոշ թվով  կամ թիրախավորված անձանց շրջանում, և կոչված է ձևավորելու կամ պահպանելու հետաքրքրությունը տվյալ ֆիզիկական և իրավաբանական անձի, ապրանքների կամ նախաձեռնությունների նկատմամբ:
                      "
                    />
                  </div>
                </li>
              </ul>
            </section>
            <section className="section-description">
              <h1 className="section-header">
                <FormattedMessage
                  id="TermsConditionsSSP.sectionHeader2"
                  defaultMessage="2. Պայմանագրի առարկան"
                />
              </h1>
              <ul className="tos-concepts">
                <li>
                  <div>
                    <span>2.1</span>
                  </div>
                  <div>
                    <FormattedMessage
                      id="TermsConditionsSSP.subjectOfTheContract1"
                      defaultMessage="Սույն պայմանագրով` Կատարողը պարտավորվում է մատուցել 2.4. կետում նշված ծառայությունները, իսկ Պատվիրատուն պարտավորվում է վճարել այդ ծառայությունների համար:"
                    />
                  </div>
                </li>
                <li>
                  <div>
                    <span>2.2</span>
                  </div>
                  <div>
                    <FormattedMessage
                      id="TermsConditionsSSP.subjectOfTheContract2"
                      defaultMessage="Համակարգում գրանցվելուց հետո Կատարողը ավելացնում է իր կայքը (կայքերը):
                      "
                    />
                  </div>
                </li>
                <li>
                  <div>
                    <span>2.3</span>
                  </div>
                  <div>
                    <FormattedMessage
                      id="TermsConditionsSSP.subjectOfTheContract3"
                      defaultMessage="Կատարողը իր կայքում (կայքերում) ստեղծում է Գովազդի ցուցադրման համար նախատեսված հատվածներ, որից հետո պարտավորվում է համակարգի կողմից տրամադրված HTML կոդը տեղադրել իր կայքում (կայքերում):"
                    />
                  </div>
                </li>
                <li>
                  <div>
                    <span>2.4</span>
                  </div>
                  <div>
                    <FormattedMessage
                      id="TermsConditionsSSP.subjectOfTheContract3"
                      defaultMessage="Կատարողը պարտավորվում է հատկացնել իր  կայքում (կայքերում) առկա գովազդային հատվածները  Գովազդատուի կողմից համակարգում ներբեռնված Գովազդի ցուցադրման համար:
                      "
                    />
                  </div>
                </li>
              </ul>
            </section>
            <section className="section-description">
              <h1 className="section-header">
                <FormattedMessage
                  id="TermsConditionsSSP.sectionHeader3"
                  defaultMessage="3. Պայմանագրի գինը և վճարման կարգը"
                />
              </h1>
              <ul className="tos-concepts">
                <li>
                  <div>
                    <span>3.1</span>
                  </div>
                  <div>
                    <FormattedMessage
                      id="TermsConditionsSSP.paymentOrder1"
                      defaultMessage="Կատարողի վարձատրությունը կազմում է յուրաքանչյուր Գովազդի ցուցադրման արժեքի 35 (երեսունհինգ) տոկոսը (ներառյալ ԱԱՀ):"
                    />
                  </div>
                </li>
                <li>
                  <div>
                    <span>3.2</span>
                  </div>
                  <div>
                    <FormattedMessage
                      id="TermsConditionsSSP.paymentOrder2"
                      defaultMessage="Պատվիրատուի կողմից Կատարողին պայմանագրի գինը վճարվում է Կատարողի հաշվարկային հաշվին փոխանցելու միջոցով::
                      "
                    />
                  </div>
                </li>
                <li>
                  <div>
                    <span>3.3</span>
                  </div>
                  <div>
                    <FormattedMessage
                      id="TermsConditionsSSP.paymentOrder3"
                      defaultMessage="Կատարողը ընթացիկ հաշվից գումարները փոխանցելու հնարավորություն է ստանում, եթե հաշվի գումարը պակաս չէ  50000 (հիսուն հազար) ՀՀ դրամից:"
                    />
                  </div>
                </li>
                <li>
                  <div>
                    <span>3.4</span>
                  </div>
                  <div>
                    <FormattedMessage
                      id="TermsConditionsSSP.paymentOrder4"
                      defaultMessage="Կատարողը համակարգում բացված հաշվից գումարը փոխանցելու համար պետք է կատարի հետևյալ գործողությունը՝ համակարգում առկա է withdraw հատված, որին սեղմելով Կատարողը համակարգին հարցում է ուղարկում իր հաշվին առկա գումարը իր հաշվարկային հաշվին փոխանցելու համար:  
                      Պատվիրատուի կողմից փոխանցումը կատարվում է հարցումը ստանալուց հետո 15 (տասնհինգ) աշխատանքային օրվա ընթացքում:"
                    />
                  </div>
                </li>
                <li>
                  <div>
                    <span>3.5</span>
                  </div>
                  <div>
                    <FormattedMessage
                      id="TermsConditionsSSP.paymentOrder5"
                      defaultMessage="  Կատարողը մինչև հաշվետու ամսվան հաջորդող ամսվա 15-ը դուրս է գրում հաշիվ-ապրանքագիր՝ հաշվետու ամսվա ընթացքում մատուցված ծառայությունների համար։"
                    />
                  </div>
                </li>
                <li>
                  <div>
                    <span>3.6</span>
                  </div>
                  <div>
                    <FormattedMessage
                      id="TermsConditionsSSP.paymentOrder6"
                      defaultMessage="Կատարողի հաշվին կուտակված գումարը կախված է ցուցադրված գովազդի քանակից և հաստատուն չէ։"
                    />
                  </div>
                </li>
              </ul>
            </section>
            <section className="section-description">
              <h1 className="section-header">
                <FormattedMessage
                  id="TermsConditions.sectionHeader4"
                  defaultMessage="4. Կողմերի իրավունքները և պարտականությունները"
                />
              </h1>
              <ul className="tos-rights-and-responsibilities">
                <li>
                  <div>
                    <span>4.1</span>
                  </div>
                  <div>
                    <strong>
                      <FormattedMessage
                        id="TermsConditionsSSP.rightsAndResponsibilities1"
                        defaultMessage=" Պատվիրատուի իրավունք ունի՝"
                      />
                    </strong>
                    <ul className="tos-rights-and-responsibilities-subpoints">
                      <li>
                        <div>
                          <span>4.1.1</span>
                        </div>
                        <div>
                          <FormattedMessage
                            id="TermsConditionsSSP.rightsAndResponsibilitiesSubpoints1"
                            defaultMessage="Ցանկացած ժամանակ ստուգել Կատարողի Ծառայությունների ընթացքը և որակը` առանց միջամտելու նրա գործունեությանը:"
                          />
                        </div>
                      </li>
                      <li>
                        <div>
                          <span>4.1.2</span>
                        </div>
                        <div>
                          <FormattedMessage
                            id="TermsConditionsSSP.rightsAndResponsibilitiesSubpoints2"
                            defaultMessage="Պատվիրատուն իրավունք ունի ցանկացած պահի դադարեցնել Կատարողի կայքի բոլոր կամ առանձին գովազդային հատվածների աշխատանքը, եթե վերջինս չբավարարի Պատվիրատուի գովազդային քաղաքանությանը կամ էֆեկտիվությունը լինի ցածր։"
                          />
                        </div>
                      </li>
                      <li>
                        <div>
                          <span>4.1.3</span>
                        </div>
                        <div>
                          <FormattedMessage
                            id="TermsConditionsSSP.rightsAndResponsibilitiesSubpoints3"
                            defaultMessage="Ցանկացած ժամանակ հրաժարվել պայմանագիրը կատարելուց` Կատարողին վճարելով մինչև պայմանագրի հրաժարման մասին տեղեկություն ստանալը մատուցված Ծառայությունների չափով:"
                          />
                        </div>
                      </li>
                    </ul>
                  </div>
                </li>
                <li>
                  <div>
                    <span>4.2</span>
                  </div>
                  <div>
                    <strong>
                      <FormattedMessage
                        id="TermsConditionsSSP.rightsAndResponsibilities2"
                        defaultMessage=" Պատվիրատուն պարտավոր է՝"
                      />
                    </strong>
                    <ul className="tos-rights-and-responsibilities-subpoints">
                      <li>
                        <div>
                          <span>4.2.1</span>
                        </div>
                        <div>
                          <FormattedMessage
                            id="TermsConditionsSSP.rightsAndResponsibilitiesSubpoints4"
                            defaultMessage="Կատարողին  հնարավորություն տալ մուտք գործել համակարգ:"
                          />
                        </div>
                      </li>
                      <li>
                        <div>
                          <span>4.2.2</span>
                        </div>
                        <div>
                          <FormattedMessage
                            id="TermsConditionsSSP.rightsAndResponsibilitiesSubpoints5"
                            defaultMessage="Համակարգում թույլատրել գրանցել կայք (կայքեր):"
                          />
                        </div>
                      </li>
                    </ul>
                  </div>
                </li>
                <li>
                  <div>
                    <span>4.3</span>
                  </div>
                  <div>
                    <strong>
                      <FormattedMessage
                        id="TermsConditionsSSP.rightsAndResponsibilities3"
                        defaultMessage=" Կատարողն իրավունք ունի՝"
                      />
                    </strong>
                    <ul className="tos-rights-and-responsibilities-subpoints">
                      <li>
                        <div>
                          <span>4.3.1</span>
                        </div>
                        <div>
                          <FormattedMessage
                            id="TermsConditionsSSP.rightsAndResponsibilitiesSubpoints6"
                            defaultMessage="Որոշել Գովազդային հատվածների՝ կայքում տեղադրման տեղերը և քանակը:"
                          />
                        </div>
                      </li>
                      <li>
                        <div>
                          <span>4.3.2</span>
                        </div>
                        <div>
                          <FormattedMessage
                            id="TermsConditionsSSP.rightsAndResponsibilitiesSubpoints7"
                            defaultMessage="Համակարգում դիտելու իր կայքերում տեղադրված գովազդների վերաբերյալ հաշվետվություն:"
                          />
                        </div>
                      </li>
                    </ul>
                  </div>
                </li>
                <li>
                  <div>
                    <span>4.4</span>
                  </div>
                  <div>
                    <strong>
                      <FormattedMessage
                        id="TermsConditionsSSP.rightsAndResponsibilities4"
                        defaultMessage=" Կատարողը պարտավոր է՝"
                      />
                    </strong>
                    <ul className="tos-rights-and-responsibilities-subpoints">
                      <li>
                        <div>
                          <span>4.4.1</span>
                        </div>
                        <div>
                          <FormattedMessage
                            id="TermsConditions.rightsAndResponsibilitiesSubpoints8"
                            defaultMessage="Ծառայությունները մատուցել պատշաճ որակով:"
                          />
                        </div>
                      </li>
                      <li>
                        <div>
                          <span>4.4.2</span>
                        </div>
                        <div>
                          <FormattedMessage
                            id="TermsConditionsSSP.rightsAndResponsibilitiesSubpoints9"
                            defaultMessage=" Համագործակցել Պատվիրատուի հետ, երբ այդպիսի համագործակցությունը անհրաժեշտ է ապահովելու համար Ծառայությունների մատուցումը և Ծառայությունների հետ կապված խնդիրների վերացումը:"
                          />
                        </div>
                      </li>
                      <li>
                        <div>
                          <span>4.4.3</span>
                        </div>
                        <div>
                          <FormattedMessage
                            id="TermsConditionsSSP.rightsAndResponsibilitiesSubpoints10"
                            defaultMessage="Չօգտագործել ծառայությունը գործող օրենսդրության խախտմամբ կամ անօրինական նպատակներով:"
                          />
                        </div>
                      </li>
                      <li>
                        <div>
                          <span>4.4.4</span>
                        </div>
                        <div>
                          <FormattedMessage
                            id="TermsConditionsSSP.rightsAndResponsibilitiesSubpoints11"
                            defaultMessage="Պահպանել Պատվիրատուի բարի համբավը և առևտրային իմիջը սույն Պայմանագրի գործողության ընթացքում և  սույն Պայմանագրի լուծումից  հետո: "
                          />
                        </div>
                      </li>
                      <li>
                        <div>
                          <span>4.4.5</span>
                        </div>
                        <div>
                          <FormattedMessage
                            id="TermsConditionsSSP.rightsAndResponsibilitiesSubpoints12"
                            defaultMessage="Ծառայությունները մատուցել ամբողջ ծավալով:"
                          />
                        </div>
                      </li>
                      <li>
                        <div>
                          <span>4.4.6</span>
                        </div>
                        <div>
                          <FormattedMessage
                            id="TermsConditionsSSP.rightsAndResponsibilitiesSubpoints13"
                            defaultMessage="Պատվիրատուի պահանջով անհատույց վերացնել հայտնաբերված բոլոր թերությունները:"
                          />
                        </div>
                      </li>
                      <li>
                        <div>
                          <span>4.4.7</span>
                        </div>
                        <div>
                          <FormattedMessage
                            id="TermsConditionsSSP.rightsAndResponsibilitiesSubpoints14"
                            defaultMessage="Տեղեկացնել իր կողմում տեխնիկական անսարքության պատճառով առաջացած ծառայության ընդհատման մասին:"
                          />
                        </div>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            </section>
            <section className="section-description">
              <h1 className="section-header">
                <FormattedMessage
                  id="TermsConditionsSSP.sectionHeader5"
                  defaultMessage="5. Պայմանագրի գործողության ժամկետը"
                />
              </h1>
              <ul className="tos-term-of-the-contract">
                <li>
                  <div>
                    <span>5.1</span>
                  </div>
                  <div>
                    <FormattedMessage
                      id="TermsConditionsSSP.termOfTheContract1"
                      defaultMessage="Սույն պայմանագիրն ուժի մեջ է մտնում  հաստատման պահից:"
                    />
                  </div>
                </li>
                <li>
                  <div>
                    <span>5.2</span>
                  </div>
                  <div>
                    <FormattedMessage
                      id="TermsConditionsSSP.termOfTheContract2"
                      defaultMessage="Սույն Պայմանագիրը գործում է անորոշ ժամանակով::
                      "
                    />
                  </div>
                </li>
                <li>
                  <div>
                    <span>5.3</span>
                  </div>
                  <div>
                    <FormattedMessage
                      id="TermsConditionsSSP.termOfTheContract3"
                      defaultMessage="Սույն պայմանագիրը կարող է լուծվել ցանկացած ժամանակ` կողմերի ուղղակի համաձայնությամբ:"
                    />
                  </div>
                </li>
                <li>
                  <div>
                    <span>5.4</span>
                  </div>
                  <div>
                    <FormattedMessage
                      id="TermsConditionsSSP.termOfTheContract4"
                      defaultMessage="Ցանկացած հիմքով, պայմանագիրը լուծելու դեպքում Կատարողին փոխհատուցվում է համակարգում բացված հաշվի ամբողջ գումարը:
                      "
                    />
                  </div>
                </li>
              </ul>
            </section>
            <section className="section-description">
              <h1 className="section-header">
                <FormattedMessage
                  id="TermsConditionsSSP.sectionHeader6"
                  defaultMessage="6. Անհաղթահարելի ուժի ազդեցություն (Ֆորս-Մաժոր)"
                />
              </h1>
              <ul className="tos-force-majeure">
                <li>
                  <div>
                    <span>6.1</span>
                  </div>
                  <div>
                    <FormattedMessage
                      id="TermsConditionsSSP.forceMajeure1"
                      defaultMessage="Սույն պայմանագրով պարտավորություններն ամբողջությամբ կամ մասնակիորեն չկատարելու համար կողմերն ազատվում են պատասխանատվությունից, եթե դա եղել է անհաղթահարելի ուժի ազդեցության արդյունք, որը ծագել է սույն պայմանագիրը կնքելուց հետո, և որը կողմերը չէին կարող կանխատեսել կամ կանխարգելել: Այդպիսի իրավիճակներ են երկրաշարժը, ջրհեղեղը, հրդեհը, պատերազմը, ռազմական և արտակարգ դրություն հայտարարելը, քաղաքկան հուզումները, գործադուլները, հաղորդակցության միջոցների աշխատանքի դադարեցումը, պետական մարմինների ակտերը և այլն, որոնք անհնարին են դարձնում սույն պայմանագրով պարտավորությունների կատարումը: Եթե արտակարգ ուժի ազդեցությունը շարունակվում է 3 (երեք) ամսից ավելի, ապա կողմերից յուրաքանչուրն իրավունք ունի լուծել պայմանագիրը` այդ մասին նախապես տեղյակ պահելով մյուս կողմին:"
                    />
                  </div>
                </li>
              </ul>
            </section>
            <section className="section-description">
              <h1 className="section-header">
                <FormattedMessage
                  id="TermsConditionsSSP.sectionHeader7"
                  defaultMessage="7. Պատասխանատվություն"
                />
              </h1>
              <ul className="tos-responsibility">
                <li>
                  <div>
                    <span>7.1</span>
                  </div>
                  <div>
                    <FormattedMessage
                      id="TermsConditionsSSP.responsibility1"
                      defaultMessage="Սույն պայմանագրով իրենց պարտավորությունների չկատարման կամ անպատշաճ կատարման համար Կողմերը պատասխանատվությունն են կրում` Հայաստանի Հանրապետության գործող օրենսդրությանը համապատասխան: Սույն պայմանագրով իր պարտավորությունները չկատարած կամ անպատշաճ կատարած Կողմը պատասխանատվություն է կրում, եթե չապացուցի, որ պատշաճ կատարումն անհնար է եղել անհաղթահարելի ուժի, այսինքն` տվյալ պայմաններում արտակարգ և անկանխելի հանգամանքների հետևանքով:"
                    />
                  </div>
                </li>
              </ul>
            </section>
            <section className="section-description">
              <h1 className="section-header">
                <FormattedMessage
                  id="TermsConditionsSSP.sectionHeader8"
                  defaultMessage="8. Վեճերի լուծման կարգ"
                />
              </h1>
              <ul className="tos-dispute-resolution-procedure">
                <li>
                  <div>
                    <span>8.1</span>
                  </div>
                  <div>
                    <FormattedMessage
                      id="TermsConditionsSSP.DisputeResolutionProcedure1"
                      defaultMessage="  Սույն պայմանագրից կամ դրա կապակցությամբ ծագող բոլոր վեճերը, տարաձայնությունները և պահանջները, ներառյալ՝ դրա կատարմանը, խախտմանը, դադարեցմանը կամ անվավերությանը վերաբերող, ենթակա են կարգավորման` Կողմերի միջև բանակցությունների և խորհրդակցությունների ճանապարհով, իսկ դրանցում հաջողության չհասնելու դեպքում՝ Հայաստանի Հանրապետության օրենսդրությանը համապատասխան` Հայաստանի Հանրապետության իրավասու դատական ատյաններում:"
                    />
                  </div>
                </li>
              </ul>
            </section>
            <section className="section-description">
              <h1 className="section-header">
                <FormattedMessage
                  id="TermsConditionsSSP.sectionHeader9"
                  defaultMessage="9. Փոփոխություններ"
                />
              </h1>
              <ul className="tos-changes">
                <li>
                  <div>
                    <span>9.1</span>
                  </div>
                  <div>
                    <FormattedMessage
                      id="TermsConditionsSSP.changes1"
                      defaultMessage="Սույն Պայմանագրում փոփոխություններ և լրացումներ կատարվում են Պատվիրատուի կողմից միակողմանի կարգով: "
                    />
                  </div>
                </li>
                <li>
                  <div>
                    <span>9.2</span>
                  </div>
                  <div>
                    <FormattedMessage
                      id="TermsConditionsSSP.changes2"
                      defaultMessage="Փոփոխություններ ու լրացումներ կրած Պայմանագիրը տեղադրվում է Պատվիրատուի պաշտոնական կայքում և ուժ է ստանում կայքում տեղադրելուց 30 (երեսուն) օրացուցային օր հետո:"
                    />
                  </div>
                </li>
                <li>
                  <div>
                    <span>9.3</span>
                  </div>
                  <div>
                    <FormattedMessage
                      id="TermsConditionsSSP.changes3"
                      defaultMessage="Սույն պայմանագրի 9.2 կետում նշված ժամկետի ընթացքում Կատարողի կողմից որևէ գրավոր առարկություն չներկայացնելու դեպքում Պայմանագիրը համարվում է ուժի մեջ մտած, իսկ դրանում ներառված պայմանները՝ պարտադիր կողմերի համար:"
                    />
                  </div>
                </li>
                <li>
                  <div>
                    <span>9.4</span>
                  </div>
                  <div>
                    <FormattedMessage
                      id="TermsConditionsSSP.changes4"
                      defaultMessage="Սույն Պայմանագիրը կնքելով` Կատարողը հաստատում է, որ իրեն տրամադրված է և հասկանալի է Պայմանագրով մատուցվող Ծառայություններին վերաբերող ամբողջ տեղեկատվությունը:"
                    />
                  </div>
                </li>
              </ul>
            </section>
          </section>
        </div>
      </div>
    );
  }
}

export const TermsAndConditionsSSP = injectIntl(WrappedTermsAndConditionsSSP);

TermsAndConditionsSSP.displayName = "TermsAndConditionsSSP";
