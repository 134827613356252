import React from "react";
import { icon_close } from "assets";
import "./style.scss";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { logo_white } from "assets";
import { FacebookIcon, LinkedInIcon } from "../Icons";
import classnames from "classnames";


const SocialMedia = ({ withTerms }: any) => {
  return (
    <div
      className={classnames("footer-social-media-container", {
        "sm-ng": window.location.pathname.includes("ng"),
      })}
    >
      <ul>
        <li>
          <a
            href="https://www.facebook.com/intent.ai.global"
            target="_blank"
            className="privacy"
            rel="noopener noreferrer"
          >
            <FacebookIcon />
          </a>
        </li>
        {/* <li>
          <TwitterIcon />
        </li> */}
        <li>
          <a
            href="https://www.linkedin.com/company/intent-ai/"
            target="_blank"
            className="privacy"
            rel="noopener noreferrer"
          >
            <LinkedInIcon />
          </a>
        </li>
        {/* <li>
          <YoutubeIcon />
        </li> */}
      </ul>
      {withTerms && (
        <p className="footer-rights">
          © {new Date().getFullYear()} Intent LLC. All Rights Reserved
        </p>
      )}
    </div>
  );
};


export default function HambugerMenu({
  tab,
  tabsValues,
  dispatch,
  switchTab,
  open,
  handleClose,
  // onLoginClick,
  // type,
}: any) {
  return (
    <div
      className={`hamburger-menu ${open ? "open" : ""} ${
        window.location.pathname.includes("ng") ? "ng-hamburger" : ""
      }`}
    >
      <img
        onClick={e => {
          e.stopPropagation();
          handleClose();
          document.body.style.overflow = "auto";
        }}
        className="hamburger-close-icon"
        src={icon_close}
        alt=""
      />
      <p className="hamburger-header">Select Website</p>
      <div className="hamburger-tabs">
        <ul>
          {/* <li
            onClick={() => {
              switchTab(tabsValues.ssp)(dispatch);
            }}
            style={{
              opacity: 0.5,
            }}
            className={classNames({
              active: tab === tabsValues.ssp,
            })}
          >
            <Link
              to={{ pathname: "/auth/login?type=1" }}
              className="header-link"
              style={{
                cursor: "default",
              }}
            >
              For Publishers
            </Link>
          </li> */}
          <li
            onClick={() => {
              switchTab(tabsValues.dsp)(dispatch);
            }}
            className={classNames({
              active: tab === tabsValues.dsp,
            })}
          >
            <Link
              to={{ pathname: "/auth/login?type=2" }}
              className="header-link"
            >
              For Advertisers
            </Link>
          </li>
        </ul>
      </div>
      <div className="hamburger-menu-buttons">
        <button>Register Now</button>
        <button className="login-button">
          <a href="https://dsp.intent.ai/auth/login">Login</a>
        </button>
        <a
          className="try-it"
          href={ window.location.pathname.includes("ng") ? "https://calendly.com/hello-intent/intent-ai-product-demo-nigeria" : "https://calendly.com/hello-intent/intent-ai-quick-demo"}
          target="_blank"
          rel="noopener noreferrer"
        >
          <button>Schedule to demo</button>
        </a>
      </div>
      <div
        className={`hamburger-menu-footer       ${
          window.location.pathname.includes("ng") ? "ng-hamburger-footer" : ""
        }`}
      >
        <img className="hamburger-menu-footer-logo" src={logo_white} alt="" />
        <SocialMedia />
      </div>
    </div>
  );
}
