import React from "react";
import { nigeria_flag } from "assets";
import { Link } from "react-router-dom";
import "./style.scss";
import { useWindowSize } from "_constants/useWindiwSize";
import { IntentLogoIcon, PhoneIcon } from "../../components/Icons";
import { airtel } from 'assets';

interface IProps {
  isLoginShow?: boolean;
}

export default function NGHeader({
  isLoginShow,
}: IProps) {
  // const tab: number = useSelector(selectCurrentTab);
  // const dispatch = useDispatch();
  // const [open, setOpen] = React.useState(false);

  const { isTablet } = useWindowSize();
  return (
    <header className="ng-header">
      <div className='logo'>
        <Link to="/">
          <img
            alt='Intent Logo'
            src={airtel}
            width={133}
            height={40}
          />
        </Link>
        <div className='divider' />
        <img
          alt='Nigeria Flag'
          src={nigeria_flag}
          className={'nigeria_flag'}
        />
      </div>
      <div className="header-actions">
        {isLoginShow && !isTablet && (
          <div className='tablet-info'>
            <a href='tel:+2349022434093' className='phone-info'>
              <PhoneIcon />
              <p className='phone-number'>+234 902 243 4093</p>
            </a>
            <div className='divider' />
            <button className="login-button">
              <a href="https://ng-app.intent.ai/auth/login">Login</a>
            </button>
          </div>
        )}
        <a
          className="try-it"
          href="https://share.hsforms.com/1-NQ4BD6BSbazYDRxYUjLFwnpo2u"
          target="_blank"
          rel="noopener noreferrer"
        >
          Schedule a demo
        </a>
      </div>
      {/*<HambugerMenu*/}
      {/*  open={open}*/}
      {/*  handleClose={() => setOpen(false)}*/}
      {/*  tab={tab}*/}
      {/*  dispatch={dispatch}*/}
      {/*  tabsValues={tabsValues}*/}
      {/*  switchTab={switchTab}*/}
      {/*/>*/}
    </header>
  );
}
