import React from "react";
import { injectIntl, InjectedIntl } from "react-intl";
import {
  targeting_ssp_icon,
} from "assets";
import "./style.scss";
interface IProps {
  intl: InjectedIntl;
}

const FeaturesSSP = ({ intl: { formatMessage } }: IProps) => {
  return (
    <section className="features-ssp">
      <div className="features-ssp-inner">
        <div className="features-ssp-title">
          <p>What you will get</p>
        </div>
        <div className="features-ssp-box-container">
          <div className="features-ssp-box">
            <img src={targeting_ssp_icon} alt="" />
            <p className="features-ssp-box-title">Relevant Ads</p>
            <p className="features-ssp-box-description">
              Do not annoy website visitors and show targeted ads
            </p>
          </div>
        </div>
        <div className="features-ssp-box-container">
          <div className="features-ssp-box">
            <img src={targeting_ssp_icon} alt="" />
            <p className="features-ssp-box-title">Bidding Mechanism</p>
            <p className="features-ssp-box-description">
              Maximize the value of every impression
            </p>
          </div>
        </div>
        <div className="features-ssp-box-container">
          <div className="features-ssp-box">
            <img src={targeting_ssp_icon} alt="" />
            <p className="features-ssp-box-title">Maximized Revenue</p>
            <p className="features-ssp-box-description">
              Cash out from as low as ֏ 50,000
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default injectIntl(FeaturesSSP);
