import React from "react";
import "./style.scss";
import { img_background } from "assets";
import { injectIntl, InjectedIntl } from "react-intl";
import { MentionIcon } from "../../components/Icons";

interface IProps {
  intl: InjectedIntl;
}

const NGFeedback = ({ intl: { formatMessage } }: IProps) => {
  // const formatItems = details.map(({ title, description, image, pngImage }) => {
  //   return (
  //     <SupportedFormatsItems
  //       title={formatMessage(title)}
  //       description={formatMessage(description)}
  //       pngImage={pngImage}
  //       img={image}
  //     />
  //   );
  // });
  return (
    <section className="ng-what-people-dsp-wrapper">
      <div className="what-people-dsp-inner">
        <div className="what-people-dsp-title">
          <h1>What people say about us</h1>
        </div>
        <div className="what-people-dsp-title-content">
          <div className="what-people-dsp-name-container">
            <div className="what-people-dsp-person">
              <div>
                <MentionIcon />
              </div>
              <div>
                <p className="what-people-dsp-name">Vahagn N.</p>
                <p className="what-people-dsp-position">
                  Jobs and Staffing
                </p>
              </div>
            </div>
            <p className="what-people-dsp-person-description">
              We have been using various tools for display advertising for years, including some of the most well-known ones. The platform's targeting capabilities have allowed us to reach our ideal audience with precision, resulting in a very attractive CPC. On top of that, the customer support team has been incredibly responsive and helpful. The traffic quality we've received has also been outstanding. We couldn't be happier with the results we've seen using Intent.ai.
            </p>
          </div>
          {/* <div className="what-people-dsp-image-container">
            <div className="what-people-dsp-image">
              <img src={random_guy} alt="" />
            </div>
          </div> */}
        </div>
      </div>
      <img className="what-people-dsp-bg" src={img_background} alt="" loading='lazy' />
      <div className='what-people-dsp-left-bg' />
    </section>
  );
};

export default injectIntl(NGFeedback);
