import React from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { MaiRouter } from "@intent-ai/mandal-npm-lib-v2";
import {
  Login,
  Signup,
  ForgotPassword,
  ResetPassword,
  AboutAds,
  PrivacyPolicy,
  TermsAndConditions,
  TermsAndConditionsSSP,
} from "screens";
import store from "./store";
import { WithNotAuthenticated as WrappedWithNotAuthenticated } from "@intent-ai/mandal-npm-lib-v2/dist/providers";
const redirectToNotfound = () => <Redirect to="/auth/login" />;

const WithNotAuthenticated = WrappedWithNotAuthenticated();

export const paths = {
  id: "/:id",
  signup: "/signup",
  login: "/login",
  auth: "/auth",
  confirmRegistration: "/confirm-registration/:token",
  forgotPassword: "/forgot-password",
  resetPassword: "/reset-password/:key",
  aboutAds: "/about-ads",
  privacyPolicy: "/privacy-policy",
  termsService: "/terms-of-service",
  termsServiceSSP: "/terms-of-service-ssp",
  ngBmp: "/ng-bmp",
};

const routerConfig = {
  routes: [
    {
      path: paths.auth,
      middlewares: [WithNotAuthenticated],
      children: [
        {
          path: paths.signup + paths.id,
          component: Signup,
        },
        {
          path: paths.login,
          component: Login,
        },
        // {
        //   path: paths.confirmRegistration,
        //   component: ConfirmRegistration,
        // },
        {
          path: paths.forgotPassword + paths.id,
          component: ForgotPassword,
        },
        {
          path: paths.resetPassword + paths.id,
          component: ResetPassword,
        },
      ],
    },
    {
      path: paths.aboutAds + paths.id,
      middlewares: [WithNotAuthenticated],
      component: AboutAds,
    },
    {
      path: paths.aboutAds,
      middlewares: [WithNotAuthenticated],
      component: AboutAds,
    },
    {
      path: paths.privacyPolicy,
      middlewares: [WithNotAuthenticated],
      component: PrivacyPolicy,
    },
    {
      path: paths.termsService,
      middlewares: [WithNotAuthenticated],
      component: TermsAndConditions,
    },
    {
      path: paths.termsServiceSSP,
      middlewares: [WithNotAuthenticated],
      component: TermsAndConditionsSSP,
    },
  ],
};

export const Routes = () => {
  return (
    <Switch>
      {MaiRouter(routerConfig, store)}
      <Route render={redirectToNotfound} />
    </Switch>
  );
};
