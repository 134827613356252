import React, { Component } from "react";
import { injectIntl, InjectedIntl } from "react-intl";
import "./style.scss";

interface IProps {
  intl: InjectedIntl;
}

class AboutSSP extends Component<IProps> {
  render() {
    return (
      <section className="dsp-about">
        <div className="traffic-wrapper-inner wrapper-container">
          <div className="traffic-title">
            Display advertising done precise and affordable{" "}
          </div>
          {/* <div className="traffic-description">
            Set up the schedule or frequency when you want your.
          </div> */}
          <div className="traffic-box-container">
            <div className="traffic-box">
              <p>01</p>
              <p>Precise Targeting </p>
              <p>
                Our Patented Technology uses first party data, meaning the
                accuracy of targeting and segmentations is the highest in the
                market.
              </p>
            </div>
            <div className="traffic-box">
              <p>02</p>
              <p>Segmentation flexibility</p>
              <p>
                Hit you competitors' clients, specific app and website users
                with tailored advertising. Mix and match the audiences and
                Interests{" "}
              </p>
            </div>
            <div className="traffic-box">
              <p>03</p>
              <p>Advanced Reporting</p>
              <p>
                Dig deep into your campaign performance reports.Evaluate metrics
                like ad placements, traffic sources, and more. Understand the
                data and manage your campaigns like a pro today{" "}
              </p>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default injectIntl(AboutSSP);
