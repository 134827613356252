import React, { Component } from "react";
import { connect } from "react-redux";
import { getCampaignTargetingInfo, sendReprotAd } from "actions";
import { bindActionCreators, Dispatch } from "redux";
import { RootState } from "types";
import { injectIntl, InjectedIntl, FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { selectTargetedData } from "selectors";
import { match } from "react-router-dom";
import { switchTab } from "actions";
import { ReportAd } from "components";
import { HeaderWhite } from "components/HeaderWhite";
import messages from "./messages";
import "./aboutAds.scss";

const reportAdData = [
  {
    key: 1,
    label: "Misleading",
  },
  {
    key: 2,
    label: "Offensive",
  },
  {
    key: 3,
    label: "Hate Speech",
  },
  {
    key: 4,
    label: "Racism",
  },
  {
    key: 5,
    label: "Repetitive",
  },
  {
    key: 6,
    label: "Vulgar/Racy",
  },
  {
    key: 7,
    label: "Other",
  },
];

interface IMatchParams {
  id: string;
}

interface IProps {
  match: match<IMatchParams>;
  intl: InjectedIntl;
  switchTab: Function;
  targetedData?: Object;
  getCampaignTargetingInfo: Function;
  sendReprotAd: Function;
}

interface IState {
  reportSuccess: boolean;
}

class WrappedAboutAds extends Component<IProps, IState> {
  state = {
    reportSuccess: false,
  };

  componentDidMount() {
    const {
      props: {
        match: {
          params: { id },
        },
        switchTab,
        getCampaignTargetingInfo,
      },
    } = this;
    switchTab(Number(0));
    if (id) {
      getCampaignTargetingInfo(id);
    }
  }

  handleReportAd = (key: number) => () => {
    const {
      props: {
        match: {
          params: { id },
        },
        sendReprotAd,
      },
    } = this;
    const postSuccess = () => this.setState(() => ({ reportSuccess: true }));
    sendReprotAd(id, key, { postSuccess });
  };

  render() {
    const {
      props: {
        targetedData,
        intl: { formatMessage },
        match: {
          params: { id },
        },
      },
      state: { reportSuccess },
      handleReportAd,
    } = this;
    return (
      <div>
        <HeaderWhite />
        <div className="main-sections">
          {id && (
            <ReportAd
              data={reportAdData}
              reportAdAction={handleReportAd}
              reportSuccess={reportSuccess}
            />
          )}
          <section className="about-ad-section create-similar-ads">
            <h1 className="opt-out-section-title">
              {formatMessage(messages.similarAds)}
            </h1>
            <div className="bmp-cards">
              <div className="card card-ssp">
                <div className="card-image">
                  <div className="card-image-texts">
                    <p className="upper-text">
                      {formatMessage(messages.monetize)}
                    </p>
                    <p className="lower-text">
                      {formatMessage(messages.forPublishers)}
                    </p>
                  </div>
                </div>
                <div className="card-description">
                  <p className="card-description-text">
                    {formatMessage(messages.dspCardDescription)}
                  </p>
                </div>
                <div className="learn-more learn-more-ssp">
                  <Link to="/auth/login?type=2">
                    <p>{formatMessage(messages.learnMore)}</p>
                  </Link>
                </div>
              </div>
              <div className="card card-dsp">
                <div className="card-image">
                  <div className="card-image-texts">
                    <p className="upper-text">
                      {formatMessage(messages.advertise)}
                    </p>
                    <p className="lower-text">
                      {formatMessage(messages.forAdvertisers)}
                    </p>
                  </div>
                </div>
                <div className="card-description">
                  <p className="card-description-text">
                    {formatMessage(messages.sspCardDescription)}
                  </p>
                </div>
                <div className="learn-more learn-more-dsp">
                  <Link to="/auth/login?type=1">
                    <p>{formatMessage(messages.learnMore)}</p>
                  </Link>
                </div>
              </div>
            </div>
            <hr className="separator" />
          </section>
          <section className="about-ad-section why-am-i-seeing-this">
            <h1 className="opt-out-section-title">
              {formatMessage(messages.whyThisAd)}
            </h1>
            {targetedData === null || targetedData === {} ? (
              <div className="description-points-wrapper">
                <p className="description-points-texts">
                  <FormattedMessage
                    id="AboutAds.displayedAdExplanation"
                    defaultMessage="Hello {linkToBMP}"
                    values={{
                      linkToBMP: (
                        <Link to="/auth/login?type=2" target="_blank">
                          Intent.AI Behavioral Marketing Platform (BMP)
                        </Link>
                      ),
                    }}
                  />
                </p>
                <p className="description-points-texts">
                  {formatMessage(messages.untargetedExplanation1)}
                </p>
                <p className="description-points-texts">
                  {formatMessage(messages.untargetedExplanation2)}
                </p>
              </div>
            ) : (
              <div className="description-points-wrapper">
                <p className="description-points-texts">
                  <FormattedMessage
                    id="AboutAds.displayedAdExplanation"
                    defaultMessage="Hello {linkToBMP}"
                    values={{
                      linkToBMP: (
                        <Link to="/auth/login?type=2" target="_blank">
                          Intent.AI Behavioral Marketing Platform (BMP)
                        </Link>
                      ),
                    }}
                  />
                </p>
                <p className="description-points-texts">
                  {formatMessage(messages.targetedExplanation1)}
                </p>
                <p className="description-points-texts">
                  {formatMessage(messages.targetedExplanation1)}
                </p>
              </div>
            )}
            <div className="explanation-section">
              <p className="explanation-title">
                {formatMessage(messages.reportAdTitle)}
              </p>
              <ul className="outline-round">
                <li>{formatMessage(messages.reportAdBulletPoint)}</li>
              </ul>
            </div>
            <div className="explanation-section">
              <div className="data-privacy-description-title-wrapper">
                <p className="explanation-title">
                  {formatMessage(messages.dataPrivacy)}
                </p>
                <div className="data-privacy-description">
                  <p className="description-points-texts">
                    {formatMessage(messages.explanationPoint1)}
                  </p>

                  <ul className="outline-round">
                    <li>{formatMessage(messages.contactUs)}</li>
                    <li>{formatMessage(messages.optOutDescription)}</li>
                  </ul>
                </div>
              </div>
            </div>
          </section>
          <section className="about-ad-section opt-out">
            <div className="learn-more learn-more-dsp">
              <p>{formatMessage(messages.optOut)}</p>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  targetedData: selectTargetedData(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  switchTab: bindActionCreators(switchTab, dispatch),
  sendReprotAd: bindActionCreators(sendReprotAd, dispatch),
  getCampaignTargetingInfo: bindActionCreators(
    getCampaignTargetingInfo,
    dispatch
  ),
});

export const AboutAds = injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(WrappedAboutAds)
);

AboutAds.displayName = "AboutAds";
