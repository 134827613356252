// Module: reducers
import { combineReducers } from "redux";
import { reducer as form } from "redux-form";
import { router } from "@intent-ai/mandal-npm-lib-v2/dist/reducers/router.reducer";
import { countries } from "@intent-ai/mandal-npm-lib-v2/dist/reducers/countries.reducer";
import { authentication } from "./auth.reducer";
import { tabs } from "./tabs.reducer";
import { campaign } from "./campaign.reducer";
// import { dictionary } from "./dictionary.reducer";
// declare module "apiresponse" {}

export default combineReducers({
  form,
  router,
  authentication,
  tabs,
  campaign,
  countries,
});
