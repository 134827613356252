import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { injectIntl, InjectedIntl } from "react-intl";
import { match } from "react-router-dom";
import { ResetPassword as GlobalResetPassword } from "@intent-ai/mandal-npm-lib-v2/dist/screens";
import { IRoutePaths, RootState } from "types";
import classnames from "classnames";
import { Header } from "components";
import { tabsValues, DSP_BASE_URL, SSP_BASE_URL } from "_constants";
import { switchTab } from "actions";
import { selectCurrentTab } from "selectors";
import { ForgotPassword } from "screens/ForgotPassword";
import "./style.scss";

interface IMatchParams {
  id: string;
  token: string;
}

interface IProps {
  tab: number;
  switchTab: Function;
  match: match<IMatchParams>;
  routerPaths: IRoutePaths;
  intl: InjectedIntl;
}

class WrappedForgotPassword extends Component<IProps> {
  componentDidMount() {
    const {
      props: {
        match: {
          params: { id },
        },
        switchTab,
      },
    } = this;
    switchTab(Number(id));
  }

  render() {
    const {
      props: {
        match: {
          params: { id },
        },
      },
    } = this;

    const tab = Number(id);
    let props = {};
    if (tab === tabsValues.dsp) {
      props = {
        url: DSP_BASE_URL,
        type: "dsp",
      };
    } else {
      props = {
        url: SSP_BASE_URL,
        type: "ssp",
      };
    }

    return (
      <div
        className={classnames("reset-password-screen", {
          dsp: tab === tabsValues.dsp,
          ssp: tab === tabsValues.ssp,
        })}
      >
        <Header />
        <div className="global-form-container">
          <GlobalResetPassword {...props} />
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  switchTab: bindActionCreators(switchTab, dispatch),
});

const mapStateToProps = (state: RootState) => ({
  tab: selectCurrentTab(state),
});

export const ResetPassword = injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(WrappedForgotPassword)
);

ForgotPassword.displayName = "ForgotPassword";
