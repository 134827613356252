import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import Carousel from "nuka-carousel";
import { MaiRow, Button, Flex } from "@intent-ai/mandal-npm-lib-v2";
import { slideshow1, slideshow2, slideshow3, slideshow4 } from "assets";
import { Link } from "react-router-dom";

export default class PlatformSSP extends Component {
  render() {
    return (
      <section className="bmp-section platform-section">
        <h2 className="section-title">
          <FormattedMessage
            id="Platform.sspTitle"
            defaultMessage="For Premium Publishers"
          />
        </h2>
        <MaiRow className="align-items-center flex-dict-row-desktop flex-dict-column-reverse-mobile">
          <MaiRow.Col
            size={6}
            customClassName="carousel-and-description-container"
          >
            <Flex direction="column" alignItems="center">
              <p className="platform-description">
                <FormattedMessage
                  id="Platform.sspDescription"
                  defaultMessage="Next to your SSP, set up your own fully customizable White Label DSP and have an unlimited control over the advertising displayed on your inventory. Create your network of advertisers, increase your revenue and moderate ads."
                />
              </p>
              <Link to="/auth/signup/2" target="_blank">
                <Button
                  color="ssp"
                  i18nId="Auth.registerNow"
                  customClassName="platform-button"
                />
              </Link>
            </Flex>
          </MaiRow.Col>
          <MaiRow.Col
            size={6}
            customClassName="carousel-and-description-container"
          >
            <div className="w-100">
              <Carousel
                className="platform-carousel"
                enableKeyboardControls
                wrapAround
                transitionMode="fade"
                swiping={false}
                autoplay
                cellAlign="center"
                heightMode="max"
                autoplayInterval={3000}
                disableAnimation={true}
                renderBottomCenterControls={null}
              >
                <img src={slideshow1} alt="Platform" />
                <img src={slideshow2} alt="Platform" />
                <img src={slideshow3} alt="Platform" />
                <img src={slideshow4} alt="Platform" />
              </Carousel>
            </div>
          </MaiRow.Col>
        </MaiRow>
      </section>
    );
  }
}
