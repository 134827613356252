import React from "react";
import classNames from "classnames";
import { icon_menu, icon_menu_black, logo_white } from "assets";
import { tabsValues } from "_constants";
import { useSelector, useDispatch } from "react-redux";
import { selectCurrentTab } from "selectors";
import { switchTab } from "actions";
import { Link } from "react-router-dom";
import "./style.scss";
import { useWindowSize } from "_constants/useWindiwSize";
import HambugerMenu from "components/HambugerMenu/HambugerMenu";
import { PhoneIcon } from "../Icons";

interface IProps {
  showTabs?: boolean;
  type?: number;
  onLoginClick?: () => void;
  isLoginShow?: boolean;
}

export default function Header({
  showTabs,
  type,
  onLoginClick,
  isLoginShow,
}: IProps) {
  const tab: number = useSelector(selectCurrentTab);
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);

  const { isTablet } = useWindowSize();
  return (
    <header>
      {/*{isTablet && (*/}
      {/*  <div*/}
      {/*    className="hamburger-icon-container"*/}
      {/*    onClick={() => {*/}
      {/*      document.body.style.overflow = "hidden";*/}
      {/*      setOpen(true);*/}
      {/*    }}*/}
      {/*  >*/}
      {/*    <img*/}
      {/*      src={*/}
      {/*        window.location.pathname.includes("ng")*/}
      {/*          ? icon_menu_black*/}
      {/*          : icon_menu*/}
      {/*      }*/}
      {/*      alt="icon_menu"*/}
      {/*    />*/}
      {/*  </div>*/}
      {/*)}*/}
      <Link to="/">
        <img className="int-logo" src={logo_white} alt="Logo" />
      </Link>
      {/* <If condition={showTabs}>
        <If.True> */}
      <div className="header-actions">
        {isLoginShow && !isTablet && (
          <>
            <a href='tel:+37455756078' className='phone-info'>
              <PhoneIcon />
              <p className='phone-number'>+374 (55) 756078</p>
            </a>
            <button className="login-button">
              <a href="https://dsp.intent.ai/auth/login">Login</a>
            </button>
          </>
        )}
        <a
          className="try-it"
          href=" https://meetings.hubspot.com/harut-martirosyan/product-demo-armenia"
          target="_blank"
          rel="noopener noreferrer"
        >
          Schedule a demo
        </a>
      </div>
      {/* </If.True>
      </If> */}
      <HambugerMenu
        type={type}
        open={open}
        handleClose={() => setOpen(false)}
        tab={tab}
        onLoginClick={onLoginClick}
        dispatch={dispatch}
        tabsValues={tabsValues}
        switchTab={switchTab}
      />
    </header>
  );
}
