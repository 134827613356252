import { defineMessages } from "react-intl";
export default defineMessages({
  forPublishers: {
    id: "AboutAds.forPublishers",
    defaultMessage: "For Publishers",
  },
  forAdvertisers: {
    id: "AboutAds.forAdvertisers",
    defaultMessage: "For Advertisers",
  },
  similarAds: {
    id: "AboutAds.createSimilarAds",
    defaultMessage: "Create Similar Ads",
  },
  monetize: {
    id: "AboutAds.sspCard.title",
    defaultMessage: "Monetize",
  },
  advertise: {
    id: "AboutAds.dspCard.title",
    defaultMessage: "Advertise",
  },
  dspCardDescription: {
    id: "AboutAds.dspCard.description",
    defaultMessage:
      "Start making money or improve your earnings by setting up ad units ign various formats on your website, while having a complete control over the content. It’s simple! Just register and let our cutting edge technology earn you money.",
  },
  sspCardDescription: {
    id: "AboutAds.sspCard.description",
    defaultMessage:
      "Deliver ads that perfectly resonate with audiences through our optimized AI based targeting and unique fraud prevention technology and spend money only on the audience that is interested in your business or product.",
  },
  learnMore: {
    id: "AboutAds.cards.learnMore",
    defaultMessage: "Learn More",
  },
  whyThisAd: {
    id: "AboutAds.whyAmISeeingThisAd",
    defaultMessage: "Why am I seeing this ad?",
  },
  untargetedExplanation1: {
    id: "AboutAds.untargeted.explanation1",
    defaultMessage:
      "You saw the ad because you visited a website which sells ad units for sponsored ads and the target group was not identified for the ad.",
  },
  untargetedExplanation2: {
    id: "AboutAds.untargeted.explanation2",
    defaultMessage:
      "Keep in mind, we do not use your personal data to show you ads.",
  },
  targetedExplanation1: {
    id: "AboutAds.targeted.explanation1",
    defaultMessage:
      "The ad you saw was based on your activity online and was targeted based on your Gender, Age, Interests, Behaviors, Languages, Behaviors.",
  },
  targetedExplanation2: {
    id: "AboutAds.targeted.explanation2",
    defaultMessage: "Keep in mind, we do not use your personal data.",
  },
  reportAdTitle: {
    id: "AboutAds.reportAd.title",
    defaultMessage:
      "If you're seeing ads that aren't interesting to you, you can:",
  },
  reportAdBulletPoint: {
    id: "AboutAds.reportAd.bulletPoint",
    defaultMessage:
      "Report ads from the advertiser. If you’re seeing an ad with unlawful or irrelevant content you can report an ad and it will be reviewed by Intent.AI Admin.",
  },
  dataPrivacy: {
    id: "AboutAds.explanationSection.title1",
    defaultMessage: "Data Privacy",
  },
  explanationPoint1: {
    id: "AboutAds.explanationSection.point1",
    defaultMessage:
      "Intent.AI uses telecom data to translate mobile network into real time consumer intent prediction platform via AI-based solutions and machine learning. We use this information to show ads that might be interesting to you based on your online performance, but we don't get your personal information. Artificial Intelligence helps us identify your interests and match the most relevant ad for you.",
  },
  contactUs: {
    id: "AboutAds.contactUs",
    defaultMessage:
      "In case of questions or suggestions, please contact INTENT.AI Team at info@intent.ai.",
  },
  optOutDescription: {
    id: "AboutAds.optOutDescription",
    defaultMessage:
      "In case you want to opt out, please click on the button below:",
  },
  optOut: {
    id: "AboutAds.button.optOut",
    defaultMessage: "OPT OUT",
  },
});
