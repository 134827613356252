import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import "./style.scss";
import { MaiRow, Flex } from "@intent-ai/mandal-npm-lib-v2";
import { laptop_ad, yandex_logo, google_logo } from "assets";

export default class AdExchangesDSP extends Component {
  render() {
    return (
      <section className="bmp-section ad-exchanges">
        <h2 className="section-title">
          <FormattedMessage
            id="AdExchanges.dspTitle"
            defaultMessage="Integration with Ad Exchanges"
          />
        </h2>
        <MaiRow
          mp0
          className="flex-dict-row-desktop flex-dict-column-mobile ad-exchanges-row-wrapper"
        >
          <MaiRow.Col size={6} customClassName="ad-exchanges-image-wrapper">
            <Flex alignItems="center" justifyContent="center">
              <img
                className="border-large"
                src={laptop_ad}
                alt="Guy with laptop"
                style={{ width: "100%" }}
              />
            </Flex>
          </MaiRow.Col>
          <MaiRow.Col size={6} customClassName="ad-exchanges-content-wrapper">
            <div className="ad-exchanges-description">
              <p className="ad-exchanges-description-text">
                <FormattedMessage
                  id="AdExchanges.dspDescription"
                  defaultMessage="Intent.AI’s  Ad exchange allows to deliver highly targeted advertising in the online advertising marketplace that connects thousands of media buyers with premium digital publishers from around the world."
                />
              </p>
              <Flex justifyContent="space-evenly" alignItems="center">
                <img
                  src={yandex_logo}
                  alt="Yandex logo"
                  className="ad-exchanges-description-yandex-logo"
                />
                <img
                  src={google_logo}
                  alt="Google logo"
                  style={{ objectFit: "none" }}
                  className="ad-exchanges-description-google-logo"
                />
              </Flex>
            </div>
          </MaiRow.Col>
        </MaiRow>
      </section>
    );
  }
}
