import { auth as ouath } from "@intent-ai/mandal-npm-lib-v2/dist/reducers/auth.reducer";
import {
  AUTH_CHECK_REGISTRATION_TOKEN_FAIL,
  AUTH_CHECK_INVITATION_TOKEN_FAIL,
  AUTH_GET_REGISTRATION_SUCCESS,
  AUTH_GET_REGISTRATION_FAIL,
  AUTH_REGISTER_SUCCESS,
  AUTH_GET_USER_SUCCESS,
  AUTH_GET_USER_FAIL,
  GET_DATA_FROM_DICTIONARY,
  RT_REGISTERED_USER_NOT_VERIFIED,
  RT_SPACE_INVITATION_USER_NOT_VERIFIED,
  AUTH_SET_JWT_TOKEN,
  AUTH_TOKEN_INVALID,
} from "_constants";
import { ReducerUtils } from "@intent-ai/mandal-npm-lib-v2/dist/utils";

const { indexifyByValue, makeInitialStatus, makeSuccess } = ReducerUtils;

const initialState = {
  user: null,
  token: {},
  roles: null,
  isAuthenticated: false,
  isLoaded: false,
  statuses: {
    updateUser: makeInitialStatus(),
    changePassword: makeInitialStatus(),
    changeLanguage: makeInitialStatus(),
    invitationToken: makeInitialStatus(),
  },
};

// TODO: Lintify reducer
const wrappedAuthentication = (state: any = initialState, action: any) => {
  const { type, payload } = action;
  const { statuses } = state;
  switch (type) {
    case GET_DATA_FROM_DICTIONARY:
      return {
        ...state,
        roles: indexifyByValue(payload.roles),
        accountStatuses: indexifyByValue(payload.accountStatuses),
      };
    case AUTH_CHECK_REGISTRATION_TOKEN_FAIL:
      // prettier-ignore
      const { type: { code } } = payload;
      if (code === RT_REGISTERED_USER_NOT_VERIFIED) {
        return {
          ...state,
          user: payload.data,
          registrationFields: payload.data,
        };
      }
      return state;
    case AUTH_CHECK_INVITATION_TOKEN_FAIL:
      // const { type: { code } } = payload;
      if (payload.type.code === RT_SPACE_INVITATION_USER_NOT_VERIFIED) {
        // NOTE: The makeSuccess usage in this case may be counterintuitive
        // the reason tough is that the API returns a failed status in case
        // the invitation token is still not validated.
        return {
          ...state,
          user: payload.data,
          statuses: { ...statuses, invitationToken: makeSuccess() },
          registrationFields: payload.data,
        };
      }
      return state;
    case AUTH_GET_REGISTRATION_SUCCESS:
      return { ...state, user: payload.data };
    case AUTH_GET_REGISTRATION_FAIL:
      return { ...state, user: { error: true } };
    case AUTH_REGISTER_SUCCESS:
      return { ...state, user: payload.data };
    case AUTH_GET_USER_SUCCESS:
      return {
        ...state,
        user: payload.data,
        isAuthenticated: true,
        isLoaded: true,
      };
    case AUTH_GET_USER_FAIL:
      return {
        ...state,
        user: { error: true },
        isAuthenticated: false,
        isLoaded: true,
      };
    case AUTH_SET_JWT_TOKEN:
      return {
        ...state,
        token: payload.token,
      };
    case AUTH_TOKEN_INVALID:
      return {
        ...state,
        isAuthenticated: false,
        isLoaded: true,
      };
    default:
      ouath(action, state);
      return state;
  }
};

export const authentication = wrappedAuthentication;
