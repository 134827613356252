import { group_4 } from "assets";
import React, { Suspense } from "react";
import { MainImageSection } from "./MainImageSection";
import NGFeatures from "./NGFeatures/NGFeatures";
import NGFeedback from "./NGFeedback/NGFeedback";
import NGHeader from "./NGHeader/NGHeader";
import NGLoginDescription from "./NGLoginDescription/NGLoginDescription";
import NGSizeSection from "./NGSizeSection/NGSizeSection";
import { TrustedBySection } from "./NGTrustedBySection";
import UniqueSection from "../modules/ng/components/Features";
import styles from './index.module.css';
import AdTypes from "../modules/ng/components/AdTypes";
import { NG_AD_TYPES, NG_FEATURES } from "../modules/ng/utils";

const NGMainContainer = () => {
  return (
    <div
      className={styles.mainContainer}
    >
      <NGHeader isLoginShow />
      <NGLoginDescription
        title1="Smarter Programmatic Advertising for Brands and Agencies in Nigeria"
        description="Maximize campaign performance with the power of accurate data and customizable targeting options."
      />
      <MainImageSection/>
      <TrustedBySection />
      <Suspense fallback={'Loading...'}>
        <UniqueSection  features={NG_FEATURES}/>
      </Suspense>
      <Suspense fallback={'Loading...'}>
        <NGFeatures />
      </Suspense>
      <Suspense fallback={'Loading...'}>
        <NGSizeSection />
      </Suspense>
      <Suspense fallback={'Loading...'}>
        <NGFeedback />
      </Suspense>
      <img
        src={group_4}
        alt="line"
        className={styles.image}
        loading='lazy'
      />
      <Suspense fallback={'Loading...'}>
        <AdTypes
          adTypes={NG_AD_TYPES}
          adTypesClassName={styles.adTypes}
          bookDemoProps={{
            redirectLink: 'https://share.hsforms.com/1-NQ4BD6BSbazYDRxYUjLFwnpo2u',
            classes: {
              bookDemo: styles.bookDemo,
              button: styles.button,
            }
          }}
        />
      </Suspense>
    </div>
  );
};

export default NGMainContainer;
