import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import Carousel from "nuka-carousel";
import { MaiRow, Button, Flex } from "@intent-ai/mandal-npm-lib-v2";
import { slideshow1, slideshow2, slideshow3, slideshow4 } from "assets";
import "./style.scss";
import { Link } from "react-router-dom";

export default class PlatformDSP extends Component {
  render() {
    return (
      <section className="bmp-section platform-section">
        <h2 className="section-title">
          <FormattedMessage
            id="Platform.dspTitle"
            defaultMessage="For Ad Agencies"
          />
        </h2>
        <MaiRow className="align-items-center flex-dict-row-desktop flex-dict-column-reverse-mobile">
          <MaiRow.Col
            size={6}
            customClassName="carousel-and-description-container"
          >
            <Flex direction="column" alignItems="center">
              <p className="platform-description">
                <FormattedMessage
                  id="Platform.dspDescription"
                  values={{
                    breakingLine: <br />,
                  }}
                  defaultMessage="For agencies at scale, our software can support unlimited client growth by offering a user-friendly platform, through which you can manage multiple client spaces with separate budgets, create numerous ad campaigns, optimize targeting settings and track campaign performance by analyzing real-time reports right through the platform. 
                   We also offer White Label DSP for ad agencies interested in custom-made bespoke technology. Brand it as your own and leave the technical part to us. For more details contact us at info@intent.ai."
                />
              </p>
              <Link to="/auth/signup/1" target="_blank">
                <Button
                  i18nId="Auth.registerNow"
                  customClassName="platform-button"
                />
              </Link>
            </Flex>
          </MaiRow.Col>
          <MaiRow.Col
            size={6}
            customClassName="carousel-and-description-container"
          >
            <div className="w-100">
              <Carousel
                className="platform-carousel"
                enableKeyboardControls
                renderBottomCenterControls={null}
                wrapAround
                transitionMode="fade"
                swiping={false}
                autoplay
                cellAlign="center"
                heightMode="max"
                autoplayInterval={3000}
                disableAnimation={true}
              >
                <img src={slideshow1} alt="Platform" />
                <img src={slideshow2} alt="Platform" />
                <img src={slideshow3} alt="Platform" />
                <img src={slideshow4} alt="Platform" />
              </Carousel>
            </div>
          </MaiRow.Col>
        </MaiRow>
      </section>
    );
  }
}
