import React from "react";
import {
  ng_website_image_desktop_webp,
  ng_website_image_mobile_webp,
  website_image,
  website_nigeria_image_mobile,
} from "assets";
import styles from './index.module.css';

export const MainImageSection = () => {
  return (
    <section className={styles.mainImageSection}>
      <div className={styles.container}></div>
      <picture>
        <source
          media='(min-width: 520px)'
          srcSet={ng_website_image_desktop_webp}
          type='image/webp'
          // @ts-ignore
          width='100%'
        />
        <source
          media='(max-width: 520px)'
          srcSet={ng_website_image_mobile_webp}
          type='image/webp'
          // @ts-ignore
          width='100%'
          height={400}
        />
        <source
          media='(min-width: 520px)'
          srcSet={website_nigeria_image_mobile}
          type='image/png'
          // @ts-ignore
          width='100%'
        />
        <source
          media='(max-width: 520px)'
          srcSet={website_image}
          type='image/png'
          // @ts-ignore
          width='100%'
          height={400}
        />
        <img
          alt=''
          src={website_image}
          width='100%'
          height={400}
        />
      </picture>
      <p className={styles.shortDescription}>Target known users with the only cookieless programmatic ad platform. Our patented AI matches anonymized telco subscriber profiles in real-time allowing you to find your target customer with ultimate precision</p>
    </section>
  );
};
