import React from "react";
import "./style.scss";
import { img_background } from "assets";
import { injectIntl, InjectedIntl } from "react-intl";

interface IProps {
  intl: InjectedIntl;
}

const SupportedFormatsDSP = ({ intl: { formatMessage } }: IProps) => {
  // const formatItems = details.map(({ title, description, image, pngImage }) => {
  //   return (
  //     <SupportedFormatsItems
  //       title={formatMessage(title)}
  //       description={formatMessage(description)}
  //       pngImage={pngImage}
  //       img={image}
  //     />
  //   );
  // });
  return (
    <section className="what-people-dsp-wrapper">
      <div className="what-people-dsp-inner">
        <div className="what-people-dsp-title">
          <h1>Advertising that drives results</h1>
          {/* <p>Set up the schedule or frequency when you want your.</p> */}
        </div>
        <div className="what-people-dsp-title-content">
          <div className="what-people-dsp-name-container">
            <div className="what-people-dsp-person">
              <div>
                <span>“</span>
              </div>
              <div>
                <p className="what-people-dsp-name">Vahagn N.</p>
                <p className="what-people-dsp-position">
                  Executive Director,JSoft LLC.
                </p>
              </div>
            </div>
            <p className="what-people-dsp-person-description">
              I have been using intent.ai for several months now and couldn't be
              happier with the results. The precision of the platform is
              unmatched, and it has allowed me to effectively reach my target
              audience at a lower cost. The platform has greatly improved the
              performance of our ads while also reducing the budget. I highly
              recommend this platform to any business looking to improve their
              ad targeting efforts and save on costs.
            </p>
          </div>
          {/* <div className="what-people-dsp-image-container">
            <div className="what-people-dsp-image">
              <img src={random_guy} alt="" />
            </div>
          </div> */}
        </div>
      </div>
      <img className="what-people-dsp-bg" src={img_background} alt="" />
    </section>
  );
};

export default injectIntl(SupportedFormatsDSP);
