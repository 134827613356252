import { GET_CAMPAIGN_TARGETING_INFO } from "_constants";

interface IState {
  targetedData?: object;
}

const initialState: IState = {
  targetedData: {},
};

export const campaign = (state: IState = initialState, action: any) => {
  const { type, payload } = action;
  switch (type) {
    case GET_CAMPAIGN_TARGETING_INFO:
      return { ...state, targetedData: payload };

    default:
      return state;
  }
};
