import React, { SVGProps, useState } from "react";
import {
  ValidatableInputField,
  ColorPickerField,
} from "@intent-ai/mandal-npm-lib-v2";
import { BANNER_SERVER_URL } from "_constants";
import "./style.scss";
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={48} height={48} {...props}>
    <title>{"5A2C31B3-779B-4D62-B4AB-175E53061510"}</title>
    <g fill="none" fillRule="evenodd">
      <path fill="#F8F8F8" d="M-925-3335H515V914H-925z" />

      <g fillRule="nonzero">
        <path d="M0 0h48v48H0z" />
        <path
          d="m25.414 24 5.657 5.657a1 1 0 0 1-1.414 1.414L24 25.414l-5.657 5.657a1 1 0 0 1-1.414-1.414L22.586 24l-5.657-5.657a1 1 0 0 1 1.414-1.414L24 22.586l5.657-5.657a1 1 0 0 1 1.414 1.414L25.414 24Z"
          fillOpacity={0.5}
          fill="#292F41"
        />
      </g>
    </g>
  </svg>
);

const color = {
  frame_color: "dfe7f0",
  background_color: "ffffff",
  cta_text_color: "ffffff",
  cta_color: "326afc",
  description_color: "666b79",
  title_color: "292f41",
  description2_color: "292F41",
};

const AdFormatsSSP = () => {
  const [width, setWidth] = useState(200);
  const [height, setHeight] = useState(350);

  const getColorsForResponsivePreview = () => {
    let colors = color;
    let values = "";
    Object.entries(colors).forEach(([key, value]) => {
      values += `${key}=${value.includes("#") ? value.substring(1) : value}&`;
    });
    return values;
  };

  const colors = getColorsForResponsivePreview();

  // const isValidate = () => {
  //   if (width >= 450 && height >= 450) {
  //     return true;
  //   }
  //   return false;
  // };

  // const validation = {
  //   touched: isValidate(),
  //   error: {
  //     id: "Validation.widthAndHeightIsBigger",
  //     defaultMessage:
  //       "Invalid size. If one side of the ad unit is equal to 450px, the other side cannot exceed 450px.",
  //   },
  // };

  const handleChange = (event: any, cb: Function) => {
    cb(+event.target.value > 450 ? 450 : event.target.value);
  };
  return (
    <section className="ad-formats-wrapper">
      <h1>Create adaptive ad now</h1>
      <p>Try customizing your placement now</p>
      <div className="ad-formats-min-container">
        <div className="ad-formats-iframe-container">
          <iframe
            src={`${BANNER_SERVER_URL}/preview?type=adaptive&project=ssp&height=${height}&width=${width}&${colors}`}
            frameBorder="0"
            width={`${width}px`}
            height={`${height}px`}
            title="Ad preview"
          />
        </div>
        <div className="ad-formats-form">
          <p className="ad-formats-form-description">
            Choose the placement type, which perfectly matches your site layout
            and design. You can customize colors and styles of the Adaptive ads
            shown on your page. Moreover, use the flexible content settings to
            exclude ad categories you do not prefer.
          </p>
          <p className="ad-formats-form-try-now">
            Try customizing your placement now
          </p>
          <div className="ad-formats-form-inputs">
            <ValidatableInputField
              currentValue={height}
              // meta={validation}
              maxLength={3}
              // withLabel
              // label="Height"
              placeholder="Height"
              onChange={(e: any) => {
                handleChange(e, setHeight);
              }}
            />
            <div>
              <SvgComponent />
            </div>
            <ValidatableInputField
              currentValue={width}
              maxLength={3}
              // withLabel
              // label="Width"
              placeholder="Width"
              onChange={(e: any) => {
                handleChange(e, setWidth);
              }}
            />
          </div>
          <div className="ad-formats-form-color">
            <p>Select button Color</p>
            <ColorPickerField
              label="Click to Customize"
              input={{
                value: color.cta_color,
                onChange: (e: any) => {
                  // setColor({ ...color, cta_color: e });
                  console.log(e);
                },
              }}
            />
          </div>
        </div>
      </div>
    </section>
  );
};
export default AdFormatsSSP;
{
  /* <MaiRow className="ad-formats-container">
        <MaiRow.Col size={4} column customClassName="ad-formats-left-box">
          <h1 className="ad-formats-header">
            <FormattedMessage
              id="AdFormats.title"
              defaultMessage="WIDE VARIETY OF AD FORMATS"
            />
          </h1>
          <h3 className="ad-formats-message">
            <FormattedMessage
              id="AdFormats.message"
              defaultMessage="Set up adaptive Ads"
            />
          </h3>
          <p className="ad-formats-description">
            <FormattedMessage
              id="AdFormats.description"
              defaultMessage="Choose the placement type, which perfectly matches your site layout and design. You can customize colors and styles of the Adaptive ads shown on your page. Moreover, use the flexible content settings to exclude ad categories you do not prefer."
            />
          </p>
        </MaiRow.Col>
        <MaiRow.Col column customClassName="ad-formats-right-box">
          <div className="ad-formats-right-box-inner">
            <div className="responsive-ads-preview">
              <iframe
                src={`${BANNER_SERVER_URL}/preview?type=adaptive&project=ssp&height=${height}&width=${width}&${colors}`}
                frameBorder="0"
                width={`${width}px`}
                height={`${height}px`}
                title="Ad preview"
              />
            </div>
            <p className="customizing">
              <FormattedMessage
                id="AdFormats.customizing"
                defaultMessage="Try customizing your placement now"
              />
            </p>
            <div className="ad-formats-inputs">
              <ValidatableInputField
                currentValue={height}
                // meta={validation}
                maxLength={3}
                withLabel
                label="Height"
                onChange={(e: any) => {
                  handleChange(e, setHeight);
                }}
              />
              <ValidatableInputField
                currentValue={width}
                maxLength={3}
                withLabel
                label="Width"
                onChange={(e: any) => {
                  handleChange(e, setWidth);
                }}
              />
              <ColorPickerField
                right
                label="BUTTON COLOR"
                input={{
                  value: color.cta_color,
                  onChange: (e: any) => {
                    // setColor({ ...color, cta_color: e });
                    console.log(e);
                  },
                }}
              />
              <p className="click-to-customize">
                <FormattedMessage
                  id="AdFormats.clickToCustomize"
                  defaultMessage="Click to Customize"
                />
              </p>
            </div>
          </div>
        </MaiRow.Col>
      </MaiRow> */
}
