import React from "react";
import style from './index.module.css';
// import {
//   tn_main_avif,
//   tn_main_jpg,
//   tn_main_mobile_avif,
//   tn_main_mobile_jpg,
//   tn_main_mobile_webp,
//   tn_main_webp,
// } from "../../assets/tn";
// import { OORedOOIcon } from "../../components/Icons";
import { sa_main_jpg } from "../../assets/sa";

function SAMainPage() {
  return (
      <>
        <div className={style.mainPage}>
          <h1 className={style.heading}>Welcome to the New Era of Digital Advertising in Saudi Arabia</h1>
          <p className={style.description}>Transform Your Advertising Strategy with Intent AI</p>
          <div className={style.actions}>
            <button className={`${style.action} ${style.signUpButton} hs-cta-trigger-button hs-cta-trigger-button-160127419224`}>
              <a
                href="javascript:void(0);"
                target="_self"
                rel="noopener noreferrer"
                className={`${style.actionLink} ${style.signUpLink}`}
              >
                Register Now
              </a>
            </button>
            <button className={style.action}>
              <a
                href="https://share.hsforms.com/118YVycEkSo-v9SJubiSJ5wnpo2u"
                target="_blank"
                rel="noopener noreferrer"
                className={style.actionLink}
              >
                Request a demo
              </a>
            </button>
          </div>
        </div>
        <section className={style.mainImageSection}>
          <div className={style.container}></div>
          <picture>
            <source
              media='(max-width: 750px)'
              srcSet={sa_main_jpg}
              type='image/avif'
            />
            <source
              media='(max-width: 750px)'
              srcSet={sa_main_jpg}
              type='image/webp'
            />
            <source
              media='(max-width: 750px)'
              srcSet={sa_main_jpg}
              type='image/jpg'
            />
            <source
              media='(min-width: 750px)'
              srcSet={sa_main_jpg}
              type='image/avif'
            />
            <source
              media='(min-width: 750px)'
              srcSet={sa_main_jpg}
              type='image/webp'
            />
            <source
              media='(min-width: 750px)'
              srcSet={sa_main_jpg}
              type='image/jpg'
            />
            <img
              alt=''
              src={sa_main_jpg}
              width='100%'
              // height={400}
              className={style.mainImage}
            />
          </picture>
          <p className={style.shortDescriptionTitle}>
            <b>Run Meaningful and Engaging Ads</b>
          </p>
          <p className={style.shortDescription}>
            At Intent AI, we empower businesses to create advertisements that resonate with their audience. Whether
            you’re looking to boost brand awareness, drive sales, or increase engagement, our platform offers everything
            you need to achieve your marketing goals.
          </p>
        </section>
        {/*<section className="trusted-by-section">*/}
        {/*  <article>*/}
        {/*    In Partnership with*/}
        {/*    <OORedOOIcon style={{ marginLeft: 16 }} />*/}
        {/*  </article>*/}
        {/*</section>*/}
      </>
  );
}


export default SAMainPage;
