import React from "react";
import "./style.scss";
import { FormattedMessage } from "react-intl";
import { If } from "@intent-ai/mandal-npm-lib-v2";

interface IReportAdType {
  key: number;
  label: string;
}

interface IProps {
  data: IReportAdType[];
  reportAdAction: Function;
  reportSuccess: boolean;
}

const ReportAd = ({ data, reportAdAction, reportSuccess }: IProps) => {
  return (
    <div className="report-ad">
      <If condition={reportSuccess}>
        <If.True>
          <p className="report-ad-success">
            <FormattedMessage
              id="AboutAds.reportAdSuccess"
              defaultMessage="Thank you for your feedback!"
            />
          </p>
        </If.True>
        <If.False>
          <h3>
            <FormattedMessage
              id="AboutAds.reportAd"
              defaultMessage="Report Ad"
            />
          </h3>
          <ul className="report-ad-list">
            {data.map(({ key, label }) => (
              <li
                key={key}
                className="report-ad-list-item"
                onClick={reportAdAction(key)}
              >
                {label}
              </li>
            ))}
          </ul>
        </If.False>
      </If>
    </div>
  );
};

export default ReportAd;
