import React, { useState } from "react";
import {
  feature_grouping, feature_reporting, feature_support,
  feature_targeting,
  img_feature_1,
  img_feature_2,
  img_feature_3,
  img_feature_4,
} from "assets";

import "./style.scss";
import classnames from "classnames";
const itemsArray = [
  {
    id: 0,
    title: "Precisely Target App Users",
    description: "Find Your Perfect User Segment",
    img: img_feature_1,
    imgWebp: feature_targeting,
    alt: 'Targeting photo',
  },
  {
    id: 1,
    title: "Dynamic User Grouping",
    description: "Pinpoint Your Competitor's Clients and Targeted App/Website Users with Customized Display Advertising Campaigns",
    img: img_feature_2,
    imgWebp: feature_grouping,
    alt: 'Dynamic User Grouping',
  },
  {
    id: 2,
    title: "Advanced Reporting",
    description:
      "Dig deep into your campaign performance reports. Evaluate metrics like ad placements, traffic sources, and more. Understand the data and manage your campaigns easily.",
    img: img_feature_3,
    imgWebp: feature_reporting,
    alt: 'Advanced Reporting',
  },
  {
    id: 3,
    title: <>360<span>&#176;</span> Support</>,
    description: "Our team is available via email, phone, or chat, so you can always reach us in the way that is most convenient for you. With our 360-degree customer support, you can rest assured that you are in good hands with Intent.ai.",
    img: img_feature_4,
    imgWebp: feature_support,
    alt: '360 degree support',
  },
];

const NGFeatures = () => {
  const [selectedId, setSelectedId] = useState<number>(0);

  const handleSelectedIdChange = (id: number) => {
    setSelectedId(id);
  }

  return (
    <section className="ng-features-dsp">
      <div className="access-demand-dsp">
        <p className="access-demand-title">
          Streamlined Programmatic Solution
        </p>
        <p className="access-demand-description">
          Result focused solutions
        </p>
      </div>
      <div className="access-demand-inner">
        <div className="access-demand-left-section">
          <ul>
            {itemsArray.map((item, index) => {
              return (
                <Item
                  item={item}
                  key={item.id}
                  selected={item.id === selectedId}
                  handleSelectedIdChange={handleSelectedIdChange}
                />
              );
            })}
          </ul>
        </div>
        <div className="access-demand-right-section">
          {itemsArray.map(item => (
            <picture
              key={item.id}
            >
              <source srcSet={item.imgWebp} type='image/webp' />
              <source srcSet={item.img} type='image/png' />
              <img
                alt={item.alt}
                src={item.img}
                width='100%'
                height='100%'
                className={classnames({
                  hidden: item.id !== selectedId,
                })}
              />
            </picture>
          ))}
        </div>
      </div>
    </section>
  );
};

export default NGFeatures;

interface IProps {
  item: {
    id: number;
    title: string | React.ReactElement;
    description: string;
  };
  selected: boolean;
  handleSelectedIdChange: (id: number) => void;
}

function Item({ item, selected, handleSelectedIdChange }: IProps) {
  return (
    <li onClick={() => handleSelectedIdChange(item.id)}>
      <p className={classnames({
        open: selected,
      })}>{item.title}</p>
      <p className={classnames({
        open: selected,
      })}>{item.description}</p>
    </li>
  );
}
