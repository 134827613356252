import React, { Component } from "react";
import { injectIntl, InjectedIntl, FormattedMessage } from "react-intl";
import "./PrivacyPolicy.scss";
import { HeaderWhite } from "components/HeaderWhite";

interface IProps {
  intl: InjectedIntl;
}

class WrappedPrivacyPolicy extends Component<IProps> {
  render() {
    return (
      <div className="sections-header-wrapper">
        <HeaderWhite title="PrivacyPolicy.title" />
        <div className="main-sections">
          <section className="sections">
            <p>
              <FormattedMessage
                id="PrivacyPolicy.effectiveDate"
                defaultMessage="Effective date: October 24, 2019"
              />
            </p>

            <div className="section-description">
              <p className="section-description-text">
                <FormattedMessage
                  id="PrivacyPolicy.serviceNote"
                  defaultMessage={`Intent.ai Inc ("us", "we", or "our") operates the
                https://intent.ai website (hereinafter referred to as the
                "Service").`}
                />
              </p>
            </div>

            <div className="section-description">
              <p className="section-description-text">
                <FormattedMessage
                  id="PrivacyPolicy.introNote"
                  defaultMessage="This page informs you of our policies regarding the collection,
                use and disclosure of personal data when you use our Service and
                the choices you have associated with that data."
                />
              </p>
            </div>

            <div className="section-description">
              <p className="section-description-text">
                <FormattedMessage
                  id="PrivacyPolicy.weUseYourData"
                  defaultMessage="We use your data to provide and improve the Service. By using the Service, you agree to the collection and use of information in accordance with this policy. Unless otherwise defined in this Privacy Policy, the terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, accessible from https://intent.ai"
                />
              </p>
            </div>
          </section>
          <section className="sections">
            <h2 className="section-titles">
              <FormattedMessage
                id="PrivacyPolicy.definitions.title"
                defaultMessage="Definitions"
              />
            </h2>
            <ul className="outline-round">
              <li>
                <p>
                  <div className="list-element-title">
                    <FormattedMessage
                      id="PrivacyPolicy.definitions.service"
                      defaultMessage="Service"
                    />
                  </div>
                </p>
                <p className="section-description-text">
                  <FormattedMessage
                    id="PrivacyPolicy.definitions.serviceDescription"
                    defaultMessage="Service is the https://intent.ai website operated by Intent.ai Inc"
                  />
                </p>
              </li>
              <li>
                <p>
                  <div className="list-element-title">
                    <FormattedMessage
                      id="PrivacyPolicy.definitions.personalData"
                      defaultMessage="Personal Data"
                    />
                  </div>
                </p>
                <p className="section-description-text">
                  <FormattedMessage
                    id="PrivacyPolicy.definitions.personalDataDescription"
                    defaultMessage="Personal Data means data about a living individual who can be
                  identified from those data (or from those and other
                  information either in our possession or likely to come into
                  our possession)."
                  />
                </p>
              </li>
              <li>
                <p>
                  <div className="list-element-title">
                    <FormattedMessage
                      id="PrivacyPolicy.definitions.usageData"
                      defaultMessage="Usage Data"
                    />
                  </div>
                </p>
                <p className="section-description-text">
                  <FormattedMessage
                    id="PrivacyPolicy.definitions.usageDataDescription"
                    defaultMessage="Usage Data is data collected automatically either generated by
                  the use of the Service or from the Service infrastructure
                  itself (for example, the duration of a page visit)."
                  />
                </p>
              </li>
              <li>
                <p>
                  <div className="list-element-title">
                    <FormattedMessage
                      id="PrivacyPolicy.definitions.cookies"
                      defaultMessage="Cookies"
                    />
                  </div>
                </p>
                <p className="section-description-text">
                  <FormattedMessage
                    id="PrivacyPolicy.definitions.cookiesDescription"
                    defaultMessage="Cookies are small files stored on your device (computer or
                        mobile device)."
                  />
                </p>
              </li>
              <li>
                <p>
                  <div className="list-element-title">
                    <FormattedMessage
                      id="PrivacyPolicy.definitions.dataController"
                      defaultMessage="Data Controller"
                    />
                  </div>
                </p>
                <p className="section-description-text">
                  <FormattedMessage
                    id="PrivacyPolicy.definitions.dataControllerDescription"
                    defaultMessage="Data Controller means the natural or legal person who (either
                      alone or jointly or in common with other persons) determines
                      the purposes for which and the manner in which any personal
                      information are, or are to be, processed."
                  />
                </p>
                <p className="section-description-text">
                  <FormattedMessage
                    id="PrivacyPolicy.definitions.dataControllerDescription2"
                    defaultMessage="For the purpose of this Privacy Policy, we are a Data
                    Controller of your div."
                  />
                </p>
              </li>
              <li>
                <p>
                  <div className="list-element-title">
                    <FormattedMessage
                      id="PrivacyPolicy.definitions.dataProcessors"
                      defaultMessage="Data Processors (or Service Providers)"
                    />
                  </div>
                </p>
                <p className="section-description-text">
                  <FormattedMessage
                    id="PrivacyPolicy.definitions.dataProcessorsDescription"
                    defaultMessage="Data Processor (or Service Provider) means any natural or
                    legal person who processes the data on behalf of the Data
                    Controller."
                  />
                </p>
                <p className="section-description-text">
                  <FormattedMessage
                    id="PrivacyPolicy.definitions.dataProcessorsDescription2"
                    defaultMessage="We may use the services of various Service Providers in order
                    to process your data more effectively."
                  />
                </p>
              </li>
              <li>
                <p>
                  <div className="list-element-title">
                    <FormattedMessage
                      id="PrivacyPolicy.definitions.dataSubject"
                      defaultMessage="Data Subject (or User)"
                    />
                  </div>
                </p>
                <p className="section-description-text">
                  <FormattedMessage
                    id="PrivacyPolicy.definitions.dataSubjectDescription"
                    defaultMessage="Data Subject is any living individual who is using our Service
                    and is the subject of Personal Data."
                  />
                </p>
              </li>
            </ul>
          </section>
          <section className="sections">
            <h2 className="section-titles">
              <FormattedMessage
                id="PrivacyPolicy.information.title"
                defaultMessage="Information Collection and Use"
              />
            </h2>
            <div className="section-description">
              <p className="section-description-text">
                <FormattedMessage
                  id="PrivacyPolicy.information.types"
                  defaultMessage="We collect several different types of information for various
                    purposes to provide and improve our Service to you."
                />
              </p>
            </div>

            <div className="section-description">
              <h3 className="section-description-title">
                <FormattedMessage
                  id="PrivacyPolicy.information.typesTitle"
                  defaultMessage="Types of Data Collected"
                />
              </h3>
              <div className="section-description">
                <h3 className="section-description-second-title">
                  <FormattedMessage
                    id="PrivacyPolicy.information.personalData"
                    defaultMessage="Personal Data"
                  />
                </h3>
                <p className="section-description-text">
                  <FormattedMessage
                    id="PrivacyPolicy.information.personalDataDescription"
                    defaultMessage={`While using our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you ("Personal Data"). Personally identifiable information may include, but is not limited to:`}
                  />
                </p>
              </div>
            </div>

            <ul className="outline-round">
              <li>
                <FormattedMessage
                  id="PrivacyPolicy.information.email"
                  defaultMessage="Email address"
                />
              </li>{" "}
              <li>
                <FormattedMessage
                  id="PrivacyPolicy.information.name"
                  defaultMessage="First name and last name"
                />
              </li>{" "}
              <li>
                <FormattedMessage
                  id="PrivacyPolicy.information.phone"
                  defaultMessage="Phone number"
                />
              </li>{" "}
              <li>
                <FormattedMessage
                  id="PrivacyPolicy.information.cookeis"
                  defaultMessage="Cookies and Usage Data"
                />
              </li>
            </ul>

            <p className="section-description-text">
              <FormattedMessage
                id="PrivacyPolicy.information.dataUsage"
                defaultMessage="We may use your Personal Data to contact you with newsletters,
                  marketing or promotional materials and other information that may
                  be of interest to you. You may opt out of receiving any, or all,
                  of these communications from us by following the unsubscribe link
                  or the instructions provided in any email we send."
              />
            </p>
            <div className="section-description">
              <h3 className="section-description-second-title">
                <FormattedMessage
                  id="PrivacyPolicy.information.dataUsageTitle"
                  defaultMessage="Usage Data"
                />
              </h3>

              <p className="section-description-text">
                <FormattedMessage
                  id="PrivacyPolicy.information.dataUsageInfo2"
                  defaultMessage={`We may also collect information on how the Service is accessed and
                used ("Usage Data"). This Usage Data may include information such
                as your computer's Internet Protocol address (e.g. IP address),
                browser type, browser version, the pages of our Service that you
                visit, the time and date of your visit, the time spent on those
                pages, unique device identifiers and other diagnostic data.`}
                />
              </p>
            </div>
            <div className="section-description">
              <h3 className="section-description-second-title">
                <FormattedMessage
                  id="PrivacyPolicy.information.trackingTitle"
                  defaultMessage="Tracking & Cookies Data"
                />
              </h3>
              <p className="section-description-text">
                <FormattedMessage
                  id="PrivacyPolicy.information.trackingInfo"
                  defaultMessage="We use cookies and similar tracking technologies to track the
                activity on our Service and we hold certain information."
                />
              </p>
              <p className="section-description-text">
                <FormattedMessage
                  id="PrivacyPolicy.information.cookiesInfo"
                  defaultMessage="Cookies are files with a small amount of data which may include an
                anonymous unique identifier. Cookies are sent to your browser from
                a website and stored on your device. Other tracking technologies
                are also used such as beacons, tags and scripts to collect and
                track information and to improve and analyse our Service."
                />
              </p>
              <p className="section-description-text">
                <FormattedMessage
                  id="PrivacyPolicy.information.cookiesInfo2"
                  defaultMessage="You can instruct your browser to refuse all cookies or to indicate
                when a cookie is being sent. However, if you do not accept
                cookies, you may not be able to use some portions of our Service."
                />
              </p>
              <p className="section-description-text">
                <FormattedMessage
                  id="PrivacyPolicy.information.cookiesExampleTitle"
                  defaultMessage="Examples of Cookies we use:"
                />
              </p>
            </div>

            <ul className="outline-round">
              <li>
                <div className="list-element-title">
                  <FormattedMessage
                    id="PrivacyPolicy.information.cookiesExample1"
                    defaultMessage="Session Cookies."
                  />
                </div>{" "}
                <p className="section-description-text">
                  <FormattedMessage
                    id="PrivacyPolicy.information.cookiesExample1Description"
                    defaultMessage="We use Session Cookies to operate our Service."
                  />
                </p>
              </li>
              <li>
                <div className="list-element-title">
                  <FormattedMessage
                    id="PrivacyPolicy.information.cookiesExample2"
                    defaultMessage="Preference Cookies."
                  />
                </div>{" "}
                <p className="section-description-text">
                  <FormattedMessage
                    id="PrivacyPolicy.information.cookiesExample2Description"
                    defaultMessage="We use Preference Cookies to remember your preferences and
                various settings."
                  />
                </p>
              </li>
              <li>
                <div className="list-element-title">
                  <FormattedMessage
                    id="PrivacyPolicy.information.cookiesExample3"
                    defaultMessage="Security Cookies."
                  />
                </div>{" "}
                <p className="section-description-text">
                  <FormattedMessage
                    id="PrivacyPolicy.information.cookiesExample3Description"
                    defaultMessage="We use Security Cookies for security purposes."
                  />
                </p>
              </li>
            </ul>

            <h2 className="section-titles">
              <FormattedMessage
                id="PrivacyPolicy.useOfData.title"
                defaultMessage="Use of Data"
              />
            </h2>
            <p>
              <div className="list-element-title">
                <FormattedMessage
                  id="PrivacyPolicy.useOfData.info"
                  defaultMessage="Intent.ai Inc uses the collected data for various purposes:"
                />
              </div>
            </p>
            <ul className="outline-round">
              <li>
                <p className="section-description-text">
                  <FormattedMessage
                    id="PrivacyPolicy.useOfData.example1"
                    defaultMessage="To provide and maintain our Service"
                  />
                </p>
              </li>
              <li>
                <p className="section-description-text">
                  <FormattedMessage
                    id="PrivacyPolicy.useOfData.example2"
                    defaultMessage="To notify you about changes to our Service"
                  />
                </p>
              </li>
              <li>
                <p className="section-description-text">
                  <FormattedMessage
                    id="PrivacyPolicy.useOfData.example3"
                    defaultMessage="To allow you to participate in interactive features of our
                    Service when you choose to do so"
                  />
                </p>
              </li>
              <li>
                <p className="section-description-text">
                  <FormattedMessage
                    id="PrivacyPolicy.useOfData.example4"
                    defaultMessage="To provide customer support"
                  />
                </p>
              </li>
              <li>
                <p className="section-description-text">
                  <FormattedMessage
                    id="PrivacyPolicy.useOfData.example5"
                    defaultMessage="To gather analysis or valuable information so that we can
                    improve our Service"
                  />
                </p>
              </li>
              <li>
                <p className="section-description-text">
                  <FormattedMessage
                    id="PrivacyPolicy.useOfData.example6"
                    defaultMessage="To monitor the usage of our Service"
                  />
                </p>
              </li>
              <li>
                <p className="section-description-text">
                  <FormattedMessage
                    id="PrivacyPolicy.useOfData.example7"
                    defaultMessage="To detect, prevent and address technical issues"
                  />
                </p>
              </li>
              <li>
                <p className="section-description-text">
                  <FormattedMessage
                    id="PrivacyPolicy.useOfData.example8"
                    defaultMessage="To provide you with news, special offers and general
                    information about other goods, services and events which we
                    offer that are similar to those that you have already
                    purchased or enquired about unless you have opted not to
                    receive such information"
                  />
                </p>
              </li>
            </ul>
            <div className="section-description">
              <h2 className="section-titles">
                <FormattedMessage
                  id="PrivacyPolicy.legal.title"
                  defaultMessage="Legal Basis for Processing Personal Data under the General Data
                    Protection Regulation (GDPR)"
                />
              </h2>
              <p className="section-description-text">
                <FormattedMessage
                  id="PrivacyPolicy.legal.description"
                  defaultMessage="If you are from the European Economic Area (EEA), Intent.ai Inc
                    legal basis for collecting and using the personal information
                    described in this Privacy Policy depends on the Personal Data we
                    collect and the specific context in which we collect it."
                />
              </p>
              <div className="section-description">
                <p className="section-description-text">
                  <div className="list-element-title">
                    <FormattedMessage
                      id="PrivacyPolicy.legal.info"
                      defaultMessage="Intent.ai Inc may process your Personal Data because:"
                    />
                  </div>
                </p>
                <ul className="outline-round">
                  <li>
                    <p className="section-description-text">
                      <FormattedMessage
                        id="PrivacyPolicy.legal.example1"
                        defaultMessage="We need to perform a contract with you"
                      />
                    </p>
                  </li>
                  <li>
                    <p className="section-description-text">
                      <FormattedMessage
                        id="PrivacyPolicy.legal.example2"
                        defaultMessage="You have given us permission to do so"
                      />
                    </p>
                  </li>
                  <li>
                    <p className="section-description-text">
                      <FormattedMessage
                        id="PrivacyPolicy.legal.example3"
                        defaultMessage="The processing is in our legitimate interests and it is not
                overridden by your rights"
                      />
                    </p>
                  </li>
                  <li>
                    <p className="section-description-text">
                      <FormattedMessage
                        id="PrivacyPolicy.legal.example4"
                        defaultMessage="For payment processing purposes"
                      />
                    </p>
                  </li>{" "}
                  <li>
                    <p className="section-description-text">
                      <FormattedMessage
                        id="PrivacyPolicy.legal.example5"
                        defaultMessage="To comply with the law"
                      />
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </section>
          <section className="sections">
            <h2 className="section-titles">
              <FormattedMessage
                id="PrivacyPolicy.retention.title"
                defaultMessage="Retention of Data"
              />
            </h2>
            <p className="section-description-text">
              <FormattedMessage
                id="PrivacyPolicy.retention.info"
                defaultMessage="Intent.ai Inc will retain your Personal Data only for as long as
                    is necessary for the purposes set out in this Privacy Policy. We
                    will retain and use your Personal Data to the extent necessary to
                    comply with our legal obligations (for example, if we are required
                    to retain your data to comply with applicable laws), resolve
                    disputes and enforce our legal agreements and policies."
              />
            </p>
            <p className="section-description-text">
              <FormattedMessage
                id="PrivacyPolicy.retention.inf2"
                defaultMessage="Intent.ai Inc will also retain Usage Data for internal analysis
                purposes. Usage Data is generally retained for a shorter period of
                time, except when this data is used to strengthen the security or
                to improve the functionality of our Service, or we are legally
                obligated to retain this data for longer periods."
              />
            </p>
          </section>
          <section className="sections">
            <h2 className="section-titles">
              <FormattedMessage
                id="PrivacyPolicy.transfer.title"
                defaultMessage="Transfer of Data"
              />
            </h2>
            <p className="section-description-text">
              <FormattedMessage
                id="PrivacyPolicy.transfer.info1"
                defaultMessage="Your information, including Personal Data, may be transferred to -
                and maintained on - computers located outside of your state,
                province, country or other governmental jurisdiction where the
                data protection laws may differ from those of your jurisdiction."
              />
            </p>
            <p className="section-description-text">
              <FormattedMessage
                id="PrivacyPolicy.transfer.info2"
                defaultMessage="If you are located outside United States and choose to provide
                information to us, please note that we transfer the data,
                including Personal Data, to United States and process it there."
              />
            </p>
            <p className="section-description-text">
              <FormattedMessage
                id="PrivacyPolicy.transfer.info3"
                defaultMessage="Your consent to this Privacy Policy followed by your submission of
                such information represents your agreement to that transfer."
              />
            </p>
            <p className="section-description-text">
              <FormattedMessage
                id="PrivacyPolicy.transfer.info4"
                defaultMessage="Intent.ai Inc will take all the steps reasonably necessary to
                ensure that your data is treated securely and in accordance with
                this Privacy Policy and no transfer of your Personal Data will
                take place to an organisation or a country unless there are
                adequate controls in place including the security of your data and
                other personal information."
              />
            </p>
          </section>

          <section className="sections">
            <h2 className="section-titles">
              <FormattedMessage
                id="PrivacyPolicy.disclosure.title"
                defaultMessage="Disclosure of Data"
              />
            </h2>
            <div>
              <h3 className="section-description-title">
                <FormattedMessage
                  id="PrivacyPolicy.disclosure.subtitle1"
                  defaultMessage="Disclosure for Law Enforcement"
                />
              </h3>
              <p className="section-description-text">
                <FormattedMessage
                  id="PrivacyPolicy.disclosure.info1"
                  defaultMessage="Under certain circumstances, Intent.ai Inc may be required to
                disclose your Personal Data if required to do so by law or in
                response to valid requests by public authorities (e.g. a court or
                a government agency)."
                />
              </p>
            </div>
            <div className="section-description">
              <h3 className="section-description-title">
                <FormattedMessage
                  id="PrivacyPolicy.disclosure.subtitle2"
                  defaultMessage="Legal Requirements"
                />
              </h3>
              <p className="section-description-text">
                <FormattedMessage
                  id="PrivacyPolicy.disclosure.description"
                  defaultMessage="Intent.ai Inc may disclose your Personal Data in the good faith
                belief that such action is necessary to:"
                />
              </p>
            </div>

            <ul className="outline-round">
              <li>
                <p className="section-description-text">
                  <FormattedMessage
                    id="PrivacyPolicy.disclosure.example1"
                    defaultMessage="To comply with a legal obligation"
                  />
                </p>
              </li>
              <li>
                <p className="section-description-text">
                  <FormattedMessage
                    id="PrivacyPolicy.disclosure.example2"
                    defaultMessage="To protect and defend the rights or property of Intent.ai Inc"
                  />
                </p>
              </li>
              <li>
                <p className="section-description-text">
                  <FormattedMessage
                    id="PrivacyPolicy.disclosure.example3"
                    defaultMessage="To prevent or investigate possible wrongdoing in connection
                with the Service"
                  />
                </p>
              </li>
              <li>
                <p className="section-description-text">
                  <FormattedMessage
                    id="PrivacyPolicy.disclosure.example4"
                    defaultMessage="To protect the personal safety of users of the Service or the
                public"
                  />
                </p>
              </li>
              <li>
                <p className="section-description-text">
                  <FormattedMessage
                    id="PrivacyPolicy.disclosure.example5"
                    defaultMessage="To protect against legal liability"
                  />
                </p>
              </li>
            </ul>
          </section>
          <section className="sections">
            <h2 className="section-titles">
              <FormattedMessage
                id="PrivacyPolicy.security.title"
                defaultMessage="Security of Data"
              />
            </h2>
            <p className="section-description-text">
              <FormattedMessage
                id="PrivacyPolicy.security.info"
                defaultMessage="The security of your data is important to us but remember that no
                method of transmission over the Internet or method of electronic
                storage is 100% secure. While we strive to use commercially
                acceptable means to protect your Personal Data, we cannot
                guarantee its absolute security."
              />
            </p>
          </section>
          <section className="sections">
            <h2 className="section-titles">
              <FormattedMessage
                id="PrivacyPolicy.caloppa.title"
                defaultMessage={`Our Policy on "Do Not Track" Signals under the California Online
                Protection Act (CalOPPA)`}
              />
            </h2>
            <p className="section-description-text">
              <FormattedMessage
                id="PrivacyPolicy.caloppa.info1"
                defaultMessage={`We do not support Do Not Track ("DNT"). Do Not Track is a
                preference you can set in your web browser to inform websites that
                you do not want to be tracked.`}
              />
            </p>
            <p className="section-description-text">
              <FormattedMessage
                id="PrivacyPolicy.caloppa.info2"
                defaultMessage="You can enable or disable Do Not Track by visiting the Preferences
                or Settings page of your web browser."
              />
            </p>
          </section>
          <section className="sections">
            <h2 className="section-titles">
              <FormattedMessage
                id="PrivacyPolicy.protection.title"
                defaultMessage="Your Data Protection Rights under the General Data Protection
                Regulation (GDPR)"
              />
            </h2>
            <p className="section-description-text">
              <FormattedMessage
                id="PrivacyPolicy.protection.info1"
                defaultMessage="If you are a resident of the European Economic Area (EEA), you
                have certain data protection rights. Intent.ai Inc aims to take
                reasonable steps to allow you to correct, amend, delete or limit
                the use of your Personal Data."
              />
            </p>
            <p className="section-description-text">
              <FormattedMessage
                id="PrivacyPolicy.protection.info2"
                defaultMessage="If you wish to be informed about what Personal Data we hold about
                you and if you want it to be removed from our systems, please
                contact us."
              />
            </p>
            <p className="section-description-text">
              <FormattedMessage
                id="PrivacyPolicy.protection.info3Description"
                defaultMessage="In certain circumstances, you have the following data protection
                rights:"
              />
            </p>
            <ul className="outline-round">
              <li>
                <p>
                  <div className="list-element-title">
                    <FormattedMessage
                      id="PrivacyPolicy.protection.info3exampleTitle1"
                      defaultMessage="The right to access, update or delete the information we
                have on you."
                    />
                  </div>{" "}
                  <p className="section-description-text">
                    <FormattedMessage
                      id="PrivacyPolicy.protection.info3example1"
                      defaultMessage="Whenever made possible, you can access, update or request
                      deletion of your Personal Data directly within your account
                      settings section. If you are unable to perform these actions
                      yourself, please contact us to assist you."
                    />
                  </p>
                </p>
              </li>
              <li>
                <p>
                  <div className="list-element-title">
                    <FormattedMessage
                      id="PrivacyPolicy.protection.info3exampleTitle2"
                      defaultMessage="The right of rectification."
                    />
                  </div>{" "}
                  <p className="section-description-text">
                    <FormattedMessage
                      id="PrivacyPolicy.protection.info3example2"
                      defaultMessage="You have the right to have your information rectified if
                      that information is inaccurate or incomplete."
                    />
                  </p>
                </p>
              </li>
              <li>
                <p>
                  <div className="list-element-title">
                    <FormattedMessage
                      id="PrivacyPolicy.protection.info3exampleTitle3"
                      defaultMessage="The right to object."
                    />
                  </div>{" "}
                  <p className="section-description-text">
                    <FormattedMessage
                      id="PrivacyPolicy.protection.info3example3"
                      defaultMessage="You have the right to object to our processing of your
                Personal Data."
                    />
                  </p>
                </p>
              </li>
              <li>
                <p>
                  <div className="list-element-title">
                    <FormattedMessage
                      id="PrivacyPolicy.protection.info3exampleTitle4"
                      defaultMessage="The right of restriction."
                    />
                  </div>{" "}
                  <p className="section-description-text">
                    <FormattedMessage
                      id="PrivacyPolicy.protection.info3example4"
                      defaultMessage="You have the right to request that we restrict the
                      processing of your personal information."
                    />
                  </p>
                </p>
              </li>
              <li>
                <p>
                  <div className="list-element-title">
                    <FormattedMessage
                      id="PrivacyPolicy.protection.info3exampleTitle5"
                      defaultMessage="The right to data portability."
                    />
                  </div>{" "}
                  <p className="section-description-text">
                    <FormattedMessage
                      id="PrivacyPolicy.protection.info3example5"
                      defaultMessage="You have the right to be provided with a copy of the
                      information we have on you in a structured, machine-readable
                      and commonly used format."
                    />
                  </p>
                </p>
              </li>
              <li>
                <p>
                  <div className="list-element-title">
                    <FormattedMessage
                      id="PrivacyPolicy.protection.info3exampleTitle6"
                      defaultMessage="The right to withdraw consent."
                    />
                  </div>{" "}
                  <p className="section-description-text">
                    <FormattedMessage
                      id="PrivacyPolicy.protection.info3example6"
                      defaultMessage="You also have the right to withdraw your consent at any time
                      where Intent.ai Inc relied on your consent to process your
                      personal information."
                    />
                  </p>
                </p>
              </li>
            </ul>
            <p className="section-description-text">
              <FormattedMessage
                id="PrivacyPolicy.protection.info4"
                defaultMessage="Please note that we may ask you to verify your identity before responding to such requests."
              />
            </p>

            <p className="section-description-text">
              <FormattedMessage
                id="PrivacyPolicy.protection.info5"
                defaultMessage="You have the right to complain to a Data Protection Authority
                about our collection and use of your Personal Data. For more
                information, please contact your local data protection authority
                in the European Economic Area (EEA)."
              />
            </p>
          </section>
          <section className="sections">
            <h2 className="section-titles">
              <FormattedMessage
                id="PrivacyPolicy.serviceProviders.title"
                defaultMessage="Service Providers"
              />
            </h2>
            <p className="section-description-text">
              <FormattedMessage
                id="PrivacyPolicy.serviceProviders.info1"
                defaultMessage={`We may employ third party companies and individuals to facilitate
                our Service ("Service Providers"), provide the Service on our
                behalf, perform Service-related services or assist us in analysing
                how our Service is used.`}
              />
            </p>
            <p className="section-description-text">
              <FormattedMessage
                id="PrivacyPolicy.serviceProviders.info2"
                defaultMessage="These third parties have access to your Personal Data only to
                perform these tasks on our behalf and are obligated not to
                disclose or use it for any other purpose."
              />
            </p>
            <div className="section-description">
              <h3 className="section-description-title">
                <FormattedMessage
                  id="PrivacyPolicy.analytics.title"
                  defaultMessage="Analytics"
                />
              </h3>
              <p className="section-description-text">
                <FormattedMessage
                  id="PrivacyPolicy.analytics.description"
                  defaultMessage="We may use third-party Service Providers to monitor and analyse
                the use of our Service."
                />
              </p>
            </div>

            <ul className="outline-round">
              <li>
                <p>
                  <div className="list-element-title">
                    <FormattedMessage
                      id="PrivacyPolicy.analytics.info1Title"
                      defaultMessage="Google Analytics"
                    />
                  </div>
                </p>
                <p className="section-description-text">
                  <FormattedMessage
                    id="PrivacyPolicy.analytics.info1"
                    defaultMessage="Google Analytics is a web analytics service offered by Google
                that tracks and reports website traffic. Google uses the data
                collected to track and monitor the use of our Service. This
                data is shared with other Google services. Google may use the
                collected data to contextualise and personalise the ads of its
                own advertising network."
                  />
                </p>
                <p className="section-description-text">
                  <FormattedMessage
                    id="PrivacyPolicy.analytics.info2"
                    defaultMessage="You can opt-out of having made your activity on the Service
                    available to Google Analytics by installing the Google
                    Analytics opt-out browser add-on. The add-on prevents the
                    Google Analytics JavaScript (ga.js, analytics.js and dc.js)
                    from sharing information with Google Analytics about visits
                    activity."
                  />
                </p>{" "}
                <p className="section-description-text">
                  <FormattedMessage
                    id="PrivacyPolicy.analytics.info3"
                    defaultMessage="For more information on the privacy practices of Google,
                    please visit the Google Privacy &amp; Terms web page:"
                  />{" "}
                  <a href="https://policies.google.com/privacy?hl=en">
                    https://policies.google.com/privacy?hl=en
                  </a>
                </p>
              </li>
            </ul>
            <div className="section-description">
              <h3 className="section-description-title">
                <FormattedMessage
                  id="PrivacyPolicy.payments.title"
                  defaultMessage="Payments"
                />
              </h3>
              <p className="section-description-text">
                <FormattedMessage
                  id="PrivacyPolicy.payments.info1"
                  defaultMessage="We may provide paid products and/or services within the Service.
                    In that case, we use third-party services for payment processing
                    (e.g. payment processors)."
                />
              </p>
              <p className="section-description-text">
                <FormattedMessage
                  id="PrivacyPolicy.payments.info2"
                  defaultMessage="We will not store or collect your payment card details. That
                information is provided directly to our third-party payment
                processors whose use of your personal information is governed by
                their Privacy Policy. These payment processors adhere to the
                standards set by PCI-DSS as managed by the PCI Security Standards
                Council, which is a joint effort of brands like Visa, MasterCard,
                American Express and Discover. PCI-DSS requirements help ensure
                the secure handling of payment information."
                />
              </p>
              <p className="section-description-text">
                <FormattedMessage
                  id="PrivacyPolicy.payments.processors"
                  defaultMessage="The payment processors we work with are:"
                />
              </p>
              <ul className="outline-round">
                <li>
                  <p>
                    <div className="list-element-title">
                      <FormattedMessage
                        id="PrivacyPolicy.payments.processorsTypes"
                        defaultMessage="PayPal / Braintree"
                      />
                    </div>
                  </p>
                  <p className="section-description-text">
                    <FormattedMessage
                      id="PrivacyPolicy.payments.policyLink"
                      defaultMessage="Their Privacy Policy can be viewed at"
                    />{" "}
                    <a href="https://www.paypal.com/webapps/mpp/ua/privacy-full">
                      https://www.paypal.com/webapps/mpp/ua/privacy-full
                    </a>
                  </p>
                </li>
              </ul>
            </div>
          </section>
          <section className="sections">
            <h2 className="section-titles">
              <FormattedMessage
                id="PrivacyPolicy.links.title"
                defaultMessage="Links to Other Sites"
              />
            </h2>
            <p className="section-description-text">
              <FormattedMessage
                id="PrivacyPolicy.links.info1"
                defaultMessage="Our Service may contain links to other sites that are not operated
                    by us. If you click a third party link, you will be directed to
                    that third party's site. We divly advise you to review the
                    Privacy Policy of every site you visit."
              />
            </p>
            <p className="section-description-text">
              <FormattedMessage
                id="PrivacyPolicy.links.info2"
                defaultMessage="We have no control over and assume no responsibility for the
                content, privacy policies or practices of any third party sites or
                services."
              />
            </p>
          </section>
          <section className="sections">
            <h2 className="section-titles">
              <FormattedMessage
                id="PrivacyPolicy.childrenPrivacy.title"
                defaultMessage="Children's Privacy"
              />
            </h2>
            <p className="section-description-text">
              <FormattedMessage
                id="PrivacyPolicy.childrenPrivacy.info1"
                defaultMessage={`Our Service does not address anyone under the age of 18
                ("Children").`}
              />
            </p>
            <p className="section-description-text">
              <FormattedMessage
                id="PrivacyPolicy.childrenPrivacy.info2"
                defaultMessage="We do not knowingly collect personally identifiable information
                from anyone under the age of 18. If you are a parent or guardian
                and you are aware that your Child has provided us with Personal
                Data, please contact us. If we become aware that we have collected
                Personal Data from children without verification of parental
                consent, we take steps to remove that information from our
                servers."
              />
            </p>
          </section>
          <section className="sections">
            <h2 className="section-titles">
              <FormattedMessage
                id="PrivacyPolicy.AdvertisingData.collection.title"
                defaultMessage="Advertising Data Collection"
              />
            </h2>
            <p className="section-description-text">
              <FormattedMessage
                id="PrivacyPolicy.AdvertisingData.collection.info"
                defaultMessage={`Intent.ai advertising system collects click and impression data 
                to be able to present aggregated reports to our advertisers.
                Advertisers can never access any individual's impression/click information.
                Intent.ai performs cookie-sync in accordance with Google's Cookie sync technology.`}
              />
            </p>
          </section>
          <section className="sections">
            <h2 className="section-titles">
              <FormattedMessage
                id="PrivacyPolicy.AdvertisingData.storage.title"
                defaultMessage="Advertising Data Storage"
              />
            </h2>
            <p className="section-description-text">
              <FormattedMessage
                id="PrivacyPolicy.AdvertisingData.storage.info"
                defaultMessage={`Impression and click data is being stored for up to 48 hours.
                During 48 hours, the individual impression/click data is being aggregated 
                and only aggregated reports are accessible to advertisers.`}
              />
            </p>
          </section>
          <section className="sections">
            <h2 className="section-titles">
              <FormattedMessage
                id="PrivacyPolicy.AdvertisingData.retention.title"
                defaultMessage="Advertising Data Retention"
              />
            </h2>
            <p className="section-description-text">
              <FormattedMessage
                id="PrivacyPolicy.AdvertisingData.retention.info"
                defaultMessage={`After 48 hours (at most), the system deletes non-aggregated data.
                Intent.ai advertising system, is not limited in time to store the aggregated data.`}
              />
            </p>
          </section>
          <section className="sections">
            <h2 className="section-titles">
              <FormattedMessage
                id="PrivacyPolicy.AdvertisingData.distribution.title"
                defaultMessage="Advertising Data Distribution"
              />
            </h2>
            <p className="section-description-text">
              <FormattedMessage
                id="PrivacyPolicy.AdvertisingData.distribution.info"
                defaultMessage={`Intent.ai advertising system will not provide individual level 
                (even anonymized) information to third parties.`}
              />
            </p>
          </section>
          <section className="sections">
            <h2 className="section-titles">
              <FormattedMessage
                id="PrivacyPolicy.AdvertisingData.optout.title"
                defaultMessage="Advertising Data Opt Out"
              />
            </h2>
            <p className="section-description-text">
              <FormattedMessage
                id="PrivacyPolicy.AdvertisingData.optout.info"
                defaultMessage={`Advertising audience is free to opt out by visiting the Intent.ai "opt out" 
                page, that can be accessed by clicking the "i" icon on the top right corner of the banner.`}
              />
            </p>
          </section>
          <section className="sections">
            <h2 className="section-titles">
              <FormattedMessage
                id="PrivacyPolicy.changes.title"
                defaultMessage="Changes to This Privacy Policy"
              />
            </h2>
            <p className="section-description-text">
              <FormattedMessage
                id="PrivacyPolicy.changes.info1"
                defaultMessage="We may update our Privacy Policy from time to time. We will notify
                you of any changes by posting the new Privacy Policy on this page."
              />
            </p>
            <p className="section-description-text">
              <FormattedMessage
                id="PrivacyPolicy.changes.info2"
                defaultMessage={`We will let you know via email and/or a prominent notice on our
                Service, prior to the change becoming effective and update the
                "effective date" at the top of this Privacy Policy.`}
              />
            </p>
            <p className="section-description-text">
              <FormattedMessage
                id="PrivacyPolicy.changes.info3"
                defaultMessage="You are advised to review this Privacy Policy periodically for any
                changes. Changes to this Privacy Policy are effective when they
                are posted on this page."
              />
            </p>
          </section>
          <section className="sections last-section">
            <h2 className="section-titles">
              <FormattedMessage
                id="PrivacyPolicy.contact.title"
                defaultMessage="Contact Us"
              />
            </h2>
            <p className="section-description-text">
              <FormattedMessage
                id="PrivacyPolicy.contact.info"
                defaultMessage="If you have any questions about this Privacy Policy, please
                contact us:"
              />
            </p>
            <ul className="outline-round">
              <li>
                <p className="section-description-text">
                  <FormattedMessage
                    id="PrivacyPolicy.contact.typeEmail"
                    defaultMessage="By email:"
                  />{" "}
                  info@intent.ai
                </p>
              </li>
            </ul>
          </section>
        </div>
      </div>
    );
  }
}

export const PrivacyPolicy = injectIntl(WrappedPrivacyPolicy);

PrivacyPolicy.displayName = "PrivacyPolicy";
