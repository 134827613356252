import React from 'react';
import styles from './index.module.css';
import classnames from "classnames";

type Props = {
  redirectLink?: string;
  classes?: {
    bookDemo?: string;
    button?: string;
  }
}

const SABookDemo = ({
  classes = {}, redirectLink = ''
                  }: Props) => {
  return (
    <div className={classnames(styles.SABookDemo)}>
      <h4 className={styles.SAHeaderText}>Ready to elevate your advertising game?</h4>
      <p className={styles.SADescriptionText}>Discover how Intent AI can revolutionize your digital marketing strategy. <br />
        Schedule a personalized demo and see our platform in action.</p>
      <a
        href={'https://share.hsforms.com/118YVycEkSo-v9SJubiSJ5wnpo2u'}
        className={classnames(styles.SABookDemoButton)}
        target='_blank'
      >
        <div></div>
        <p>Request a Demo</p>
      </a>
    </div>
  );
}

export default SABookDemo;
