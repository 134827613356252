import React from "react";
import { flag } from "assets/tn";
import { Link } from "react-router-dom";
import { useWindowSize } from "_constants/useWindiwSize";
import { IntentLogoIcon, PhoneIcon } from "../../components/Icons";
import style from './index.module.css';

function TNHeader() {
  const { isTablet } = useWindowSize();
  return (
    <header className={style.tnHeader}>
      <div className={style.logo}>
        <Link to="/">
          <IntentLogoIcon className={style.intentLogo}/>
        </Link>
        <div className={style.divider} />
        <img
          alt='Tunisia Flag'
          src={flag}
          className={style.flag}
          width={32}
          height={21}
        />
      </div>
      <div className={style.headerActions}>
        {!isTablet && (
          <div className={style.tabletInfo}>
            <a href='tel:+14158710605' className={style.phoneInfo}>
              <PhoneIcon />
              <p className={style.phoneNumber}>+ 1 415 871 0605</p>
            </a>
            <div className={style.tabletDivider} />
            <button className={style.loginButton}>
              <a href="https://tn-dsp.intent.ai/auth/login">Login</a>
            </button>
          </div>
        )}
        <a
          className={style.tryIt}
          href="https://share.hsforms.com/1NVnjtOKNQc6yADXtzEjX2wnpo2u"
          target="_blank"
          rel="noopener noreferrer"
        >
          Schedule a demo
        </a>
      </div>
    </header>
  );
}

export default TNHeader;