import React, { Component } from "react";
import classnames from "classnames";
import { match } from "react-router-dom";
import {
  DSP_BASE_URL,
  SSP_BASE_URL,
  DSP_WEB_NAME,
  DSP_WEB_SECRET,
  SSP_WEB_NAME,
  SSP_WEB_SECRET,
  tabsValues,
} from "_constants";
import { If } from "@intent-ai/mandal-npm-lib-v2";
import { Login as GlobalLogin } from "@intent-ai/mandal-npm-lib-v2/dist/screens";
import { LoginDescription, Header } from "components";
import {
  FeaturesSSP,
  FeaturesDSP,
  SupportedFormatsDSP,
  TrafficSSP,
  AccessDemandSSP,
  AdFormatsSSP,
  AdDescription,
} from "containers";
import { injectIntl, InjectedIntl } from "react-intl";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import { selectCurrentTab } from "selectors";
import { switchTab } from "actions";
import { RootState } from "types";
import "./style.scss";
import { Location } from "history";
import {
  HumanIcon,
  CheckIcon,
  TargetIcon,
} from "../../components/LoginDescription/icons";

import { OrderDemoDSP } from "containers/OrderDemoDSP";
import { useWindowSize } from "_constants/useWindiwSize";
import Features from "../../modules/ng/components/Features";
import style from './index.module.css';

interface IMatchParams {
  id: string;
  type: string;
}

interface IProps {
  intl: InjectedIntl;
  tab: number;
  switchTab: Function;
  match: match<IMatchParams>;
  location: Location;
}
const IconsWrapper = () => {
  const { isMobile } = useWindowSize();
  return (
    <>
      <div className="icon-container wh human">
        <HumanIcon />
      </div>

      {!isMobile && (
        <>
          <div className="icon-container wh human2">
            <HumanIcon />
          </div>
          <div className="icon-container wh target">
            <TargetIcon />
          </div>
          <div className="icon-container check wo">
            <CheckIcon />
          </div>
          <div className="icon-container wo human3">
            <HumanIcon />
          </div>
          <div className="icon-container check2 wo">
            <CheckIcon />
          </div>
          <div className="icon-container check3 wo">
            <CheckIcon />
          </div>
          <div className="icon-container wo human4">
            <HumanIcon />
          </div>
        </>
      )}
    </>
  );
};
class WrappedLogin extends Component<IProps> {
  state = {
    isOpen: false,
  };
  setWrapperRef = React.createRef<HTMLDivElement>();
  componentDidMount() {
    const {
      props: {
        location: { search },
        switchTab,
      },
    } = this;
    const searchQuery = new URLSearchParams(search);
    if (searchQuery.get("type")) {
      switchTab(Number(searchQuery.get("type")));
    }
  }

  onLoginClick = () => {
    this.setState({ isOpen: true });
    document.body.style.overflow = "hidden";
  };
  onClose = (e: any) => {
    if (
      !this.setWrapperRef.current ||
      this.setWrapperRef.current.contains(e.target as Node)
    ) {
      return;
    }
    e.stopPropagation();
    document.body.style.overflow = "auto";
    this.setState({ isOpen: false });
  };
  render() {
    const {
      props: {
        tab,
      },
      state: { isOpen },
      onLoginClick,
      onClose,
    } = this;

    let props = {};
    if (tab === tabsValues.dsp) {
      props = {
        url: DSP_BASE_URL,
        appName: DSP_WEB_NAME,
        appSecret: DSP_WEB_SECRET,
        type: "dsp",
        typeKey: tabsValues.dsp,
      };
    } else {
      props = {
        url: SSP_BASE_URL,
        appName: SSP_WEB_NAME,
        appSecret: SSP_WEB_SECRET,
        type: "ssp",
        typeKey: tabsValues.ssp,
      };
    }
    const type = tabsValues.dsp === tab ? 1 : 2;
    console.log('not loaded');
    return (
      <>
        <section
          className={classnames("login-screen", {
            dsp: tab === tabsValues.dsp,
            ssp: tab === tabsValues.ssp,
          })}
        >
          <div className="login-header">
            <Header
              showTabs
              type={type}
              onLoginClick={onLoginClick}
              isLoginShow
            />
          </div>
          <div className={"form-group-wrapper"}>
            <div
              className={`form-group-left-box ${
                tab === tabsValues.ssp ? "ssp" : "dsp"
              }`}
            >
              <IconsWrapper />;
              <If condition={tab === tabsValues.ssp}>
                <If.True>
                  <LoginDescription
                    title1={"Monetize Your Bussiness"}
                    description={
                      "Start making money or improve your earnings by setting up ad units in various formats on your website, while having a complete control over the content. It’s simple! Just register and let our cutting edge technology earn you money."
                    }
                    type={2}
                  />
                </If.True>
                <If.False>
                  <LoginDescription
                    title1={
                      "The most flexible online advertising platform with highest data accuracy"
                    }
                    description={
                      "Reach out to the audience you always wanted to but never had the tool"
                    }
                    type={1}
                  />
                </If.False>
              </If>
            </div>
            {/* <MaiRow.Col
              size={6}
              flexEnd
              customClassName={"form-group-right-box"}
            ></MaiRow.Col> */}
          </div>
          <div className="image-loading" />
        </section>
        {isOpen && (
          <div
            className="overlay"
            onClick={e => {
              onClose(e);
            }}
          >
            <div className="modal" ref={this.setWrapperRef}>
              <GlobalLogin {...props} multi />
            </div>
          </div>
        )}
        <If condition={tab === tabsValues.ssp}>
          <If.True>
            <TrafficSSP />
            <FeaturesSSP />
            <AccessDemandSSP />
            <AdFormatsSSP />
          </If.True>
          <If.False>
            <Features classes={{
              features: style.features,
              featureItemSelected: style.featureSelected,
              featureItemDesktop: style.featureItem,
            }} />
            <FeaturesDSP />
            <OrderDemoDSP />
            <SupportedFormatsDSP />
            <AdDescription />
            {/* <AdFormatsDSP /> */}
          </If.False>
        </If>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  switchTab: bindActionCreators(switchTab, dispatch),
});

const mapStateToProps = (state: RootState) => ({
  tab: selectCurrentTab(state),
});

export const Login = connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(WrappedLogin));

Login.displayName = "Login";
