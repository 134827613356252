import React, { Component } from "react";
import { AdFormats, MaiRow } from "@intent-ai/mandal-npm-lib-v2";
import { FormattedMessage } from "react-intl";

export default class SupportedFormatsSSP extends Component {
  render() {
    return (
      <section className="bmp-section supported-formats section-wrapper">
        <h2 className="section-title supported-formats-title">
          <FormattedMessage
            id="AdFormats.sspTitle"
            defaultMessage="Supported Ad Formats"
          />
        </h2>
        <MaiRow mp0 className="supported-formats-content">
          <MaiRow.Col
            size={8}
            customClassName="supported-formats-content-formats"
          >
            <AdFormats color="ssp" />
          </MaiRow.Col>
          <MaiRow.Col
            size={4}
            customClassName="supported-formats-description-wrapper"
          >
            <div className="supported-formats-description">
              <FormattedMessage
                id="AdFormats.sspDescription"
                defaultMessage="Stay creative and innovative by using various ad formats on your website. Provide an enhanced experience to the user through your ads and stand out amongst others."
              />
            </div>
          </MaiRow.Col>
        </MaiRow>
      </section>
    );
  }
}
