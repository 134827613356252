const { REACT_APP_BASE_URL: BASE_URL } = window;
export const POST = "POST";
export const GET = "GET";
export const PUT = "PUT";
export { BASE_URL };
export const AUTH_TOKEN = window.REACT_APP_AUTH_KEY || "DSP_AUTH_TOKEN";
export const REFRESH_TOKEN = `REFRESH_${AUTH_TOKEN}`;
export const DICT_HASH_KEY = window.REACT_APP_DICT_HASH_KEY || "Dict-Hash";
export const DICT_DB_KEY =
  window.REACT_APP_DICT_DB_KEY || "SSP_dictionary";

export const DSP_BASE_URL =
  window.REACT_APP_DSP_BASE_URL || "https://dsp-api.dev.intent.ai";
export const SSP_BASE_URL = window.REACT_APP_SSP_BASE_URL;
export const DSP_WEB_NAME = window.REACT_APP_DSP_WEB_APP_NAME;
export const DSP_WEB_SECRET = window.REACT_APP_DSP_WEB_APP_SECRET;
export const SSP_WEB_NAME = window.REACT_APP_SSP_WEB_APP_NAME;
export const SSP_WEB_SECRET = window.REACT_APP_SSP_WEB_APP_SECRET;
export const BANNER_SERVER_URL = window.REACT_APP_BANNER_SERVER_URL;
