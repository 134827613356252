import React from "react";
import "./style.scss";
import { logo_1, logo_2, logo_3, logo_4 } from "assets";
interface IProps {
  title1: string;
  description: string;
  type?: number;
}
const signUpType: { [key: string]: string } = {
  "1": "dsp",
  "2": "ssp",
};
export default function LoginDescription({
  title1,
  description,
  type = 1,
}: IProps) {
  return (
    <>
      <div className="login-description">
        <h1>{title1}</h1>
        <p>{description}</p>
        <div className="login-description-buttons ">
          <button className={'hs-cta-trigger-button hs-cta-trigger-button-160566547880'}>
            <a
              href={'javascript:void(0)'}
              target="_self"
              rel="noopener noreferrer"
            >
              Sign up
            </a>
          </button>
          <button>
            <a
              href=" https://meetings.hubspot.com/harut-martirosyan/product-demo-armenia"
              target="_blank"
              rel="noopener noreferrer"
            >
              Schedule a demo
            </a>
          </button>
        </div>
      </div>

      <div className="login-description-partners">
        <p
          style={{
            fontSize: "35px",
          }}
        >
          Trusted by
        </p>
        <div className="partners-logo-wrapper">
          <img src={logo_1} alt="logo_1" />
          <img src={logo_2} alt="logo_2" />
          <img src={logo_3} alt="logo_3" />
          <img src={logo_4} alt="logo_4" />
        </div>
      </div>
    </>
  );
}
