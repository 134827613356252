import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { selectCurrentTab } from "selectors";
import { injectIntl, InjectedIntl } from "react-intl";
import { match } from "react-router-dom";
import { IRoutePaths, RootState } from "types";
import { switchTab } from "actions";
import { ForgotPassword as GlobalForgotPassword } from "@intent-ai/mandal-npm-lib-v2/dist/screens";
import { Header } from "components";
import { tabsValues, DSP_BASE_URL, SSP_BASE_URL } from "_constants";
import classnames from "classnames";

import "./style.scss";

interface IMatchParams {
  id: string;
}

interface IProps {
  tab: number;
  switchTab: Function;
  match: match<IMatchParams>;
  routerPaths: IRoutePaths;
  intl: InjectedIntl;
}

class WrappedForgotPassword extends Component<IProps> {
  componentDidMount() {
    const {
      props: {
        match: {
          params: { id },
        },
        switchTab,
      },
    } = this;
    switchTab(Number(id));
  }

  render() {
    const {
      props: {
        match: {
          params: { id },
        },
      },
    } = this;

    const tab = Number(id);
    let props = {};
    if (tab === tabsValues.dsp) {
      props = {
        url: DSP_BASE_URL,
        type: "dsp",
      };
    } else {
      props = {
        url: SSP_BASE_URL,
        type: "ssp",
      };
    }

    return (
      <>
        <section
          className={classnames("forgot-password-screen", {
            dsp: tab === tabsValues.dsp,
            ssp: tab === tabsValues.ssp,
          })}
        >
          <div>
            <Header />
            <div className="global-form-container">
              <GlobalForgotPassword {...props} />
            </div>
          </div>
        </section>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  switchTab: bindActionCreators(switchTab, dispatch),
});

const mapStateToProps = (state: RootState) => ({
  tab: selectCurrentTab(state),
});

export const ForgotPassword = injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(WrappedForgotPassword)
);

ForgotPassword.displayName = "ForgotPassword";
