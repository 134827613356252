import { ActionUtils } from "@intent-ai/mandal-npm-lib-v2/dist/utils";
import { GET_CAMPAIGN_TARGETING_INFO } from "_constants";

import * as api from "api";

const { buildAsyncActionCreators, handleApiAction } = ActionUtils;

export const getCampaignTargetingInfo = (campaignId: string) =>
  handleApiAction(
    api.getCampaignTargetingInfo(campaignId),
    buildAsyncActionCreators(GET_CAMPAIGN_TARGETING_INFO)
  );

export const sendReprotAd = (campaignId: string, reportReasonKey: number) =>
  handleApiAction(
    api.sendReprotAdRequest(campaignId, reportReasonKey),
    buildAsyncActionCreators(GET_CAMPAIGN_TARGETING_INFO)
  );
