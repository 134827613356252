import React, { Component } from "react";
import { injectIntl, InjectedIntl, FormattedMessage } from "react-intl";
import "../PrivacyPolicy/PrivacyPolicy.scss";
import { HeaderWhite } from "components/HeaderWhite";
import "./style.scss";

interface IProps {
  intl: InjectedIntl;
}

class WrappedTermsAndConditions extends Component<IProps> {
  render() {
    return (
      <div className="sections-header-wrapper tos-wrapper">
        <HeaderWhite title="TermsConditions.title" />
        <div className="main-sections">
          <section className="sections">
            <p>
              <FormattedMessage
                id="TermsConditions.lastUpdated"
                defaultMessage="Թարմացվել է՝ Հուլիսի 13, 2020"
              />
            </p>
            <section className="section-description">
              <h1 className="section-header">
                <FormattedMessage
                  id="TermsConditions.sectionHeader1"
                  defaultMessage="1. Պայմանագրում օգտագործվող հիմնական հասկացություններ"
                />
              </h1>
              <ul className="tos-concepts">
                <li>
                  <div>
                    <span>1.1</span>
                  </div>
                  <div>
                    <span>
                      <FormattedMessage
                        id="TermsConditions.concept1header"
                        defaultMessage="Կատարող - "
                      />
                    </span>
                    <FormattedMessage
                      id="TermsConditions.concept1description"
                      defaultMessage="«Մանդալ Էյ Այ» սահմանափակ պատասխանատվությամբ ընկերություն"
                    />
                  </div>
                </li>
                <li>
                  <div>
                    <span>1.2</span>
                  </div>
                  <div>
                    <span>
                      <FormattedMessage
                        id="TermsConditions.concept2header"
                        defaultMessage="Պատվիրատու -  "
                      />
                    </span>
                    <FormattedMessage
                      id="TermsConditions.concept2description"
                      defaultMessage="համակարգում գրանցված ցանկացած ռեզիդենտ և ոչ ռեզիդենտ ֆիզիկական կամ իրավաբանական անձ, ով ցանկանում է տեղադրել և տարածել իր գովազդը"
                    />
                  </div>
                </li>
                <li>
                  <div>
                    <span>1.3</span>
                  </div>
                  <div>
                    <span>
                      <FormattedMessage
                        id="TermsConditions.concept3header"
                        defaultMessage="Գովազդակիր -  "
                      />
                    </span>
                    <FormattedMessage
                      id="TermsConditions.concept3description"
                      defaultMessage=" իրավաբանական անձ, որը տրամադրում է Կատարողին իրեն պատկանող վեբ կայքի գովազդային հատվածը, համակարգում տեղադրված գովազդի ավտոմատ ցուցադրման համար։"
                    />
                  </div>
                </li>
                <li>
                  <div>
                    <span>1.4</span>
                  </div>
                  <div>
                    <span>
                      <FormattedMessage
                        id="TermsConditions.concept3header"
                        defaultMessage="Համակարգ -  "
                      />
                    </span>
                    <FormattedMessage
                      id="TermsConditions.concept3description"
                      defaultMessage=" Կատարողի ինտերնետային կայք (www. intent.ai), որը հնարավորություն է ընձեռնում Գովազդակիրներին և Պատվիրատուներին գրանցվելու, և վերջիններին հնարավորություն է տալիս ներբեռնելու իրենց գովազդը, համակարգում գրանցված Գովազդակիրների Գովազդային հատվածում ավտոմատ կերպով ցուցադրելու համար։
                      "
                    />
                  </div>
                </li>
                <li>
                  <div>
                    <span>1.5</span>
                  </div>
                  <div>
                    <span>
                      <FormattedMessage
                        id="TermsConditions.concept4header"
                        defaultMessage="Գովազդային հատված` -  "
                      />
                    </span>
                    <FormattedMessage
                      id="TermsConditions.concept4description"
                      defaultMessage=" Գովազդակիրների ինտերնետային կայքում Գովազդի ցուցադրման համար տրամադրվող տիրույթ։"
                    />
                  </div>
                </li>
                <li>
                  <div>
                    <span>1.6</span>
                  </div>
                  <div>
                    <span>
                      <FormattedMessage
                        id="TermsConditions.concept4header"
                        defaultMessage="Գովազդ -  "
                      />
                    </span>
                    <FormattedMessage
                      id="TermsConditions.concept4description"
                      defaultMessage=" Պատվիրատուի կողմից համակարգում տեղադրվող ապրանքների, գաղափարների կամ նախաձեռնությունների մասին տեղեկություն, որը տարածվում (ցուցադրվում) է Գովազդակրերի կայքերի համապատասխան Գովազդային հատվածներում, անորոշ թվով  կամ թիրախավորված անձանց շրջանում, և կոչված է ձևավորելու կամ պահպանելու հետաքրքրությունը տվյալ ֆիզիկական և իրավաբանական անձի, ապրանքների կամ նախաձեռնությունների նկատմամբ։
                      "
                    />
                  </div>
                </li>
              </ul>
            </section>
            <section className="section-description">
              <h1 className="section-header">
                <FormattedMessage
                  id="TermsConditions.sectionHeader2"
                  defaultMessage="2. Պայմանագրի առարկան"
                />
              </h1>
              <ul className="tos-contract-subject">
                <li>
                  <div>
                    <span>2.1</span>
                  </div>
                  <div>
                    <FormattedMessage
                      id="TermsConditions.contractSubject1"
                      defaultMessage="Սույն պայմանագրի պայմաններին համապատասխան, Կատարողը պարտավորվում է վարձատրության դիմաց կազմակերպել Պատվիրատուի կողմից Համակարգում ներբեռնած Գովազդի ցուցադրումը Գովազդակրի Գովազդային հատվածում` (այսուհետ՝ Ծառայություներ) :"
                    />
                  </div>
                </li>
                <li>
                  <div>
                    <span>2.2</span>
                  </div>
                  <div>
                    <FormattedMessage
                      id="TermsConditions.contractSubject2"
                      defaultMessage="Պատվիրատուի հանձնարարությամբ Գովազդը կարող է ցուցադրվել ինչպես Համակարգում գրանցված կայքերի բոլոր այցելուներին, այնպես էլ թիրախավորված անձանց խմբին՝"
                    />
                    <ul className="tos-contract-subject-subpoint">
                      <li>
                        <div>
                          <span>2.2.1</span>
                        </div>
                        <div>
                          <FormattedMessage
                            id="TermsConditions.contractSubjectSubpoint1"
                            defaultMessage="Թիրախավորված անձանց խումբը կազմվում է Պատվիրատու կողմից՝ հաշվի առնելով այդ անձանց դեմոգրաֆիկ տվյալները (սեռ, տարիք), հետաքրքրությունները, պահվածք, և այլ տվյալներ, որոնք անհրաժեշտ կհամարվեն"
                          />
                        </div>
                      </li>
                      <li>
                        <div>
                          <span>2.2.2</span>
                        </div>
                        <div>
                          <FormattedMessage
                            id="TermsConditions.contractSubjectSubpoint2"
                            defaultMessage="Թիրախավորման հաշվարկի հիմքում ընկած են մաթեմատիկական վիճակագրական մոդելներ, որոնք ապահովում են բարձր ճշտություն, սակայն չեն երաշխավորում թիրախավորման բացարձակ (100%) ճշտությունը:"
                          />
                        </div>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            </section>
            <section className="section-description">
              <h1 className="section-header">
                <FormattedMessage
                  id="TermsConditions.sectionHeader3"
                  defaultMessage="3. Վճարումներ ու հաշվարկները"
                />
              </h1>
              <ul className="tos-payments">
                <li>
                  <div>
                    <span>3.1</span>
                  </div>
                  <div>
                    <FormattedMessage
                      id="TermsConditions.payments1"
                      defaultMessage="Գովազդի ցուցադրման արժեքը սահմանում է Կատարողը: Պատվիրատուն կարող է այն դիտել Համակարգի՝ գովազդային արշավի ստեղծման բաժնում։ Համակարգը հուշում է նաև մեկ ամսվա ընթացքում ցուցադրվելիք գովազդի քանակի մոտավոր գնահատականը։՝"
                    />
                    <ul className="tos-payments-subject-subpoint">
                      <li>
                        <div>
                          <span>3.1.1</span>
                        </div>
                        <div>
                          <FormattedMessage
                            id="TermsConditions.paymentsSubpoint1"
                            defaultMessage="Համակարգում առաջարկվում է 1000 (հազար) ցուցադրաման համար սահմանված գինը:"
                          />
                        </div>
                      </li>
                      <li>
                        <div>
                          <span>3.1.2</span>
                        </div>
                        <div>
                          <FormattedMessage
                            id="TermsConditions.paymentsSubpoint2"
                            defaultMessage="Պատվիրատուն իրավունք ունի փոփոխել ցուցադրման հաճախականությունը՝ նվազեցնելով կամ ավելացնելով սույն պայմանագրի 3.1.1 ենթակետում նշված ցուցադրումների քանակը՝ համապատասխանաբար փոփոխելով սահմանված գինը:"
                          />
                        </div>
                      </li>
                      <li>
                        <div>
                          <span>3.1.3</span>
                        </div>
                        <div>
                          <FormattedMessage
                            id="TermsConditions.paymentsSubpoint3"
                            defaultMessage="Համակարգում նշված  արժեքը ներառում է բոլոր հարկերը:"
                          />
                        </div>
                      </li>
                    </ul>
                  </div>
                </li>
                <li>
                  <div>
                    <span>3.2</span>
                  </div>
                  <div>
                    <FormattedMessage
                      id="TermsConditions.contractSubject1"
                      defaultMessage="Սույն պայմանագրի 2.1 կետում նշված Ծառայությունները մատուցվում են կանխավճարի պայմաններով, որն իրականացվում է Պատվիրատուի կողմից՝ Համակարգում իր բանկային քարտը գրանցելով և գումարի փոխանցումը կատարելով։"
                    />
                  </div>
                </li>
                <li>
                  <div>
                    <span>3.3</span>
                  </div>
                  <div>
                    <FormattedMessage
                      id="TermsConditions.contractSubject2"
                      defaultMessage=" Այն դեպքում երբ Պատվիրատուն քարտային գործարք կատարելու հնարավորություն կամ ցանկություն չունի, նա կարող է նախորոք համաձայնության գալով Կատարողի հետ, կատարել բանկային փոխանցում Կատրողի բանկային հաշվին։"
                    />
                  </div>
                </li>
                <li>
                  <div>
                    <span>3.4</span>
                  </div>
                  <div>
                    <FormattedMessage
                      id="TermsConditions.contractSubject3"
                      defaultMessage="Եթե Պատվիրատուն գրանցվում է ֆիզիկական անձանց համար նախատեսված հարթակում, ապա
                      Կատարողը հաշիվ ապրանքնագիր դուրս է գրում միայն ֆիզիկական անձի պահանջի դեպքում։"
                    />
                  </div>
                </li>
                <li>
                  <div>
                    <span>3.5</span>
                  </div>
                  <div>
                    <FormattedMessage
                      id="TermsConditions.contractSubject4"
                      defaultMessage="Եթե Պատվիրատուն գրանցվում է իրավաբանական անձանց համար նախատեսված հարթակում, ապա
                      նա հավաստում է որ հանդիսանում է իր կողմից գրանցված իրավաբանական անձի ներկայացուցիչ, և
                      նույն գրանցման հաշվին կցված բանկային քարտից intent.ai հարթակում կատարվող վճարումները
                      հանդիսանում են վճարումներ նշված իրավաբանական անձին սույն պայմանագրի շրջանակներում
                      մատուցված ծառայությունների համար:"
                    />
                  </div>
                </li>
                <li>
                  <div>
                    <span>3.6</span>
                  </div>
                  <div>
                    <FormattedMessage
                      id="TermsConditions.contractSubject5"
                      defaultMessage="Եթե Պատվիրատուն գրանցվում է իրավաբանական անձանց համար նախատեսված հարթակում, ապա
                      Կատարողը ծառայությունների մատուցման հաշիվ է դուրս գրում ընթացքիկ մատուցված
                      ծառայությունների համար։"
                    />
                  </div>
                </li>
              </ul>
            </section>
            <section className="section-description">
              <h1 className="section-header">
                <FormattedMessage
                  id="TermsConditions.sectionHeader4"
                  defaultMessage="4. Կողմերի իրավունքները և պարտականությունները"
                />
              </h1>
              <ul className="tos-rights-and-responsibilities">
                <li>
                  <div>
                    <span>4.1</span>
                  </div>
                  <div>
                    <strong>
                      <FormattedMessage
                        id="TermsConditions.rightsAndResponsibilities1"
                        defaultMessage=" Պատվիրատուի իրավունք ունի՝"
                      />
                    </strong>
                    <ul className="tos-rights-and-responsibilities-subpoints">
                      <li>
                        <div>
                          <span>4.1.1</span>
                        </div>
                        <div>
                          <FormattedMessage
                            id="TermsConditions.rightsAndResponsibilitiesSubpoints1"
                            defaultMessage=" Համակարգի հաշվետվությունների միջոցով հետևել գովազդային արշավի ընթացքին"
                          />
                        </div>
                      </li>
                      <li>
                        <div>
                          <span>4.1.2</span>
                        </div>
                        <div>
                          <FormattedMessage
                            id="TermsConditions.rightsAndResponsibilitiesSubpoints2"
                            defaultMessage=" Ստուգել իր կողմից արված կանխավճարի մնացորդի չափը:"
                          />
                        </div>
                      </li>
                      <li>
                        <div>
                          <span>4.1.3</span>
                        </div>
                        <div>
                          <FormattedMessage
                            id="TermsConditions.rightsAndResponsibilitiesSubpoints3"
                            defaultMessage=" Փոփոխել թիրախավորման խումբը առնաց Պատվիրատուին տեղեկացնելու և վերջինիս համաձայնությունը ստանալու:"
                          />
                        </div>
                      </li>
                    </ul>
                  </div>
                </li>
                <li>
                  <div>
                    <span>4.2</span>
                  </div>
                  <div>
                    <strong>
                      <FormattedMessage
                        id="TermsConditions.rightsAndResponsibilities2"
                        defaultMessage=" Պատվիրատուն պարտավոր է՝"
                      />
                    </strong>
                    <ul className="tos-rights-and-responsibilities-subpoints">
                      <li>
                        <div>
                          <span>4.2.1</span>
                        </div>
                        <div>
                          <FormattedMessage
                            id="TermsConditions.rightsAndResponsibilitiesSubpoints4"
                            defaultMessage=" Կատարողին տրամադրել ՀՀ օրենսդրության պահանջներին համապատասխան ստեղծված թվային գովազդային նյութեր:"
                          />
                        </div>
                      </li>
                    </ul>
                  </div>
                </li>
                <li>
                  <div>
                    <span>4.3</span>
                  </div>
                  <div>
                    <strong>
                      <FormattedMessage
                        id="TermsConditions.rightsAndResponsibilities3"
                        defaultMessage=" Կատարողն իրավունք ունի՝"
                      />
                    </strong>
                    <ul className="tos-rights-and-responsibilities-subpoints">
                      <li>
                        <div>
                          <span>4.3.1</span>
                        </div>
                        <div>
                          <FormattedMessage
                            id="TermsConditions.rightsAndResponsibilitiesSubpoints5"
                            defaultMessage=" Իրականացնել Գովազդների մոդերացիա և արգելափակել դրանք եթե չեն համապատասխանում իր քաղաքականությանը և ՀՀ օրենսդրության պահանջներին:"
                          />
                        </div>
                      </li>
                    </ul>
                  </div>
                </li>
                <li>
                  <div>
                    <span>4.4</span>
                  </div>
                  <div>
                    <strong>
                      <FormattedMessage
                        id="TermsConditions.rightsAndResponsibilities4"
                        defaultMessage=" Կատարողը պարտավոր է՝"
                      />
                    </strong>
                    <ul className="tos-rights-and-responsibilities-subpoints">
                      <li>
                        <div>
                          <span>4.4.1</span>
                        </div>
                        <div>
                          <FormattedMessage
                            id="TermsConditions.rightsAndResponsibilitiesSubpoints6"
                            defaultMessage=" Կատարողի կողմից Կանխավճարի փոխանցումից  և գովազդային արշավի հրապարակումից հետո ապահովել Գովազդի ցուցադրումը,"
                          />
                        </div>
                      </li>
                      <li>
                        <div>
                          <span>4.4.2</span>
                        </div>
                        <div>
                          <FormattedMessage
                            id="TermsConditions.rightsAndResponsibilitiesSubpoints7"
                            defaultMessage=" Հնարավորություն ընձեռնել Պատվիրատուին հետևելու գովազդային արշավի ընթացքին:"
                          />
                        </div>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            </section>
            <section className="section-description">
              <h1 className="section-header">
                <FormattedMessage
                  id="TermsConditions.sectionHeader4"
                  defaultMessage="5. Գործողությունը և դադարումը"
                />
              </h1>
              <ul className="tos-action-and-termination">
                <li>
                  <div>
                    <span>5.1</span>
                  </div>
                  <div>
                    <FormattedMessage
                      id="TermsConditions.actionAndTermination1"
                      defaultMessage="Սույն պայմանագիրն ուժի մեջ է մտնում Պատվիրատուի կողմից  կանխավճարի փոխանցման պահից  և  գործում է մինչև կանխավճարի սպառումը:"
                    />
                  </div>
                </li>
                <li>
                  <div>
                    <span>5.2</span>
                  </div>
                  <div>
                    <FormattedMessage
                      id="TermsConditions.actionAndTermination2"
                      defaultMessage="Պատվիրատու կողմից Ծառայությունների մատուցման նոր ժամանակահատվածի համար վճարում կատարելու դեպքում Պայմանագիրը համարվում է վերակնքված նույն պայմաններով բացառությամբ՝ Ծառայության դիմաց կատարվող վճարի, եթե նման փոփոխությունները Կատարողը հրապարակել է իր կայքում:"
                    />
                  </div>
                </li>
                <li>
                  <div>
                    <span>5.3</span>
                  </div>
                  <div>
                    <FormattedMessage
                      id="TermsConditions.actionAndTermination3"
                      defaultMessage="Սույն պայմանագիրը կարող է լուծվել ցանկացած ժամանակ` կողմերի ուղղակի համաձայնությամբ:"
                    />
                  </div>
                </li>
                <li>
                  <div>
                    <span>5.4</span>
                  </div>
                  <div>
                    <FormattedMessage
                      id="TermsConditions.actionAndTermination4"
                      defaultMessage="Ցանկացած հիմքով, բացառությամբ Կատարողի կողմից պարտավորությունների չկատարել կամ ոչ պատշաճ կերպով կատարումը, սույն պայմանագրի դադարման դեպքում Պատվիրատուն վճարում է հրաժարագին՝  կանխավճարի մնացորդի չափով:"
                    />
                  </div>
                </li>
              </ul>
            </section>
            <section className="section-description">
              <h1 className="section-header">
                <FormattedMessage
                  id="TermsConditions.sectionHeader5"
                  defaultMessage="6. Գաղտնիությունը"
                />
              </h1>
              <ul className="tos-privacy">
                <li>
                  <div>
                    <span>6.1</span>
                  </div>
                  <div>
                    <FormattedMessage
                      id="TermsConditions.privacy1"
                      defaultMessage="Կողմերը ստանձնում են պայմանագրի հետ կապված մի Կողմից մյուս Կողմին ուղղակի կամ միջնորդավորված կերպով տրամադրված ցանկացած տեղեկատվության և փաստաթղթերի չհրապարակման պարտավորություններ` անկախ նրանից, թե երբ է այդպիսի տեղեկատվությունը տրամադրվել` մինչև պայմանագրի գործողության ժամկետի լրանալը, դրա ընթացքում, թե` ավարտին:"
                    />
                  </div>
                </li>
                <li>
                  <div>
                    <span>6.2</span>
                  </div>
                  <div>
                    <FormattedMessage
                      id="TermsConditions.privacy2"
                      defaultMessage="Տեղեկատվության չհրապարակման պարտավորությունը չի տարածվում հանրամատչելի տեղեկատվության, ինչպես նաև այն տեղեկատվության վրա, որը հայտնի կդառնա ոչ պայմանագրի Կողմի մեղքով:"
                    />
                  </div>
                </li>
              </ul>
            </section>
            <section className="section-description">
              <h1 className="section-header">
                <FormattedMessage
                  id="TermsConditions.sectionHeader6"
                  defaultMessage="7. Պատասխանատվություն"
                />
              </h1>
              <ul className="tos-responsibility">
                <li>
                  <div>
                    <span>7.1</span>
                  </div>
                  <div>
                    <FormattedMessage
                      id="TermsConditions.responsibility1"
                      defaultMessage="Սույն պայմանագրով իրենց պարտավորությունների չկատարման կամ անպատշաճ կատարման համար Կողմերը պատասխանատվությունն են կրում` Հայաստանի Հանրապետության գործող օրենսդրությանը համապատասխան: Սույն պայմանագրով իր պարտավորությունները չկատարած կամ անպատշաճ կատարած Կողմը պատասխանատվություն է կրում, եթե չապացուցի, որ պատշաճ կատարումն անհնար է եղել անհաղթահարելի ուժի, այսինքն` տվյալ պայմաններում արտակարգ և անկանխելի հանգամանքների հետևանքով:"
                    />
                  </div>
                </li>
                <li>
                  <div>
                    <span>7.2</span>
                  </div>
                  <div>
                    <FormattedMessage
                      id="TermsConditions.responsibility2"
                      defaultMessage="Պատվիրատուն պատասխանատվություն է կրում ՀՀ օրենսդրության պահանջներին չհամապատասխանող ինչպես նաև երրորդ անձանց իրավունքները խախտող Գովազդի համար:"
                    />
                  </div>
                </li>
              </ul>
            </section>
            <section className="section-description">
              <h1 className="section-header">
                <FormattedMessage
                  id="TermsConditions.sectionHeader7"
                  defaultMessage="8. Վեճերի լուծում"
                />
              </h1>
              <ul className="tos-dispute-resolution">
                <li>
                  <div>
                    <span>8.1</span>
                  </div>
                  <div>
                    <FormattedMessage
                      id="TermsConditions.disputeResolution"
                      defaultMessage="  Սույն պայմանագրից կամ դրա կապակցությամբ ծագող բոլոր վեճերը, տարաձայնությունները և պահանջները, ներառյալ՝ դրա կատարմանը, խախտմանը, դադարեցմանը կամ անվավերությանը վերաբերող, ենթակա են կարգավորման` Կողմերի միջև բանակցությունների և խորհրդակցությունների ճանապարհով, իսկ դրանցում հաջողության չհասնելու դեպքում՝ Հայաստանի Հանրապետության օրենսդրությանը համապատասխան` Հայաստանի Հանրապետության իրավասու դատական ատյաններում:"
                    />
                  </div>
                </li>
              </ul>
            </section>
            <section className="section-description">
              <h1 className="section-header">
                <FormattedMessage
                  id="TermsConditions.sectionHeader8"
                  defaultMessage="9. Փոփոխություններ"
                />
              </h1>
              <ul className="tos-changes">
                <li>
                  <div>
                    <span>9.1</span>
                  </div>
                  <div>
                    <FormattedMessage
                      id="TermsConditions.changes1"
                      defaultMessage="Սույն Պայմանագրում փոփոխություններ և լրացումներ կատարվում են Կատարողի կողմից :"
                    />
                  </div>
                </li>
                <li>
                  <div>
                    <span>9.2</span>
                  </div>
                  <div>
                    <FormattedMessage
                      id="TermsConditions.changes2"
                      defaultMessage="Փոփոխություններ ու լրացումներ կրած Պայմանագիրը տեղադրվում է Կատարողի պաշտոնական կայքում և ուժ է ստանում կայքում տեղադրելուց 15 (տասնհիգ) օրացուցային օր հետո:"
                    />
                  </div>
                </li>
                <li>
                  <div>
                    <span>9.3</span>
                  </div>
                  <div>
                    <FormattedMessage
                      id="TermsConditions.changes3"
                      defaultMessage="Սույն պայմանագրի 9.2 կետում նշված ժամկետի ընթացքում Պատվիրատուի կողմից որևէ գրավոր առարկություն չներկայացնելու դեպքում Պայմանագիրը համարվում է ուժի մեջ մտած, իսկ դրանում ներառված պայմանները՝ պարտադիր կողմերի համար:"
                    />
                  </div>
                </li>
                <li>
                  <div>
                    <FormattedMessage
                      id="TermsConditions.changes4"
                      defaultMessage="Սույն Պայմանագիրը կնքելով` Պատվիրատուն հաստատում է, որ իրեն տրամադրված է և հասկանալի է Պայմանագրով մատուցվող Ծառայություններին վերաբերող ամբողջ տեղեկությունը:"
                    />
                  </div>
                </li>
              </ul>
            </section>
          </section>
        </div>
      </div>
    );
  }
}

export const TermsAndConditions = injectIntl(WrappedTermsAndConditions);

TermsAndConditions.displayName = "TermsAndConditions";
