import React from "react";
import { limit_allocation } from "assets";
import "./style.scss";

const AccessDemandSSP = () => {
  return (
    <section className="access-demand-wrapper">
      <div className="access-demand-inner">
        <div className="access-demand-left-section">
          <p className="access-demand-title">Main features</p>
          <p className="access-demand-description">
            Set up the schedule or frequency when you want your.
          </p>
          <ul>
            <li>
              <p>100% Traffic Utilization</p>
              <p>
                Set up the schedule or frequency when you want your and then tet
                up the schedule or frequency when you want your..
              </p>
            </li>
            <li>
              <p>Access To Diverse Demand</p>
            </li>
            <li>
              <p>Analytics And Filters</p>
            </li>
          </ul>
        </div>
        <div className="access-demand-right-section">
          <img loading="lazy" src={limit_allocation} alt="" />
        </div>
      </div>
    </section>
  );
};

export default AccessDemandSSP;
