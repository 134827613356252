import React from "react";
import "./style.scss";

import { adaptive1, html1, banner1 } from "assets";

const AdDescription = () => {
  return (
    <section className="ad-description-wrapper">
      <div className="ad-description-inner">
        <div className="ad-description-title-container">
          <h1>Multiple ad formats to help you stand out </h1>
          <p>Provide your target audience with the most attractive ads</p>
        </div>
        <ul className="ad-description-list">
          <li>
            <div className="ad-description-image-container">
              <div>
                <img src={banner1} alt="" />
              </div>
            </div>
            <div>
              <p>Banner Ads</p>
              <p>Use JPEG or PNG files for each of the sizes recommended</p>
            </div>
          </li>
          <li>
            <div className="ad-description-image-container">
              <div>
                <img src={html1} alt="" />
              </div>
            </div>
            <div>
              <p>HTML</p>
              <p>Create and upload animated banners</p>
            </div>
          </li>
          <li>
            <div className="ad-description-image-container">
              <div>
                <img src={adaptive1} alt="" />
              </div>
            </div>
            <div>
              <p>Dynamic Ads</p>
              <p>
                Reduce the manual work, input all the details and enjoy your
                dynamic ads generated for all the sizes.
              </p>
            </div>
          </li>
          {/* <li>
            <img src={adaptive_png} alt="" />
            <div>
              <p>Adaptive Ads</p>
              <p>Input content and bid for placements of any size</p>
            </div>
          </li>
          <li>
            <img src={adaptive_png} alt="" />
            <div>
              <p>Video Ads</p>
              <p>
                Upload MP4 or AVG files for one or multiple sizes recomended.
              </p>
            </div>
          </li>
          <li>
            <img src={adaptive_png} alt="" />
            <div>
              <p>Other Type Ads</p>
              <p>
                A placement size of 320.50 to better support views on mobile
                devicces
              </p>
            </div>
          </li> */}
        </ul>
      </div>
    </section>
  );
};

export default AdDescription;
