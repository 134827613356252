import React from "react";
import "./style.scss";

interface IProps {
  title1?: string;
  description?: string;
  type?: number;
}

export default function NGLoginDescription({
  title1,
  description,
  type = 1,
}: IProps) {
  return (
    <>
      <div className="ng-login-description">
        <h1>{title1}</h1>
        <p>{description}</p>
        <div className="login-description-buttons">
          <button className="signup-button hs-cta-trigger-button hs-cta-trigger-button-160381842334">

            <a
              href={'javascript:void(0)'}
              target="_self"
              rel="noopener noreferrer"
            >
              Sign up
            </a>
          </button>
          <button>
            <a
              href="https://share.hsforms.com/1-NQ4BD6BSbazYDRxYUjLFwnpo2u"
              target="_blank"
              rel="noopener noreferrer"
            >
              Schedule a demo
            </a>
          </button>
        </div>
      </div>

      {/* <div className="login-description-partners">
        <p
          style={{
            fontSize: "35px",
          }}
        >
          Trusted by
        </p>
        <div className="partners-logo-wrapper">
          <img src={logo_1} alt="logo_1" />
          <img src={logo_2} alt="logo_2" />
          <img src={logo_3} alt="logo_3" />
          <img src={logo_4} alt="logo_4" />
        </div>
      </div> */}
    </>
  );
}
