import React from "react";
import style from './index.module.css';
import {
  tn_main_avif,
  tn_main_jpg,
  tn_main_mobile_avif,
  tn_main_mobile_jpg,
  tn_main_mobile_webp,
  tn_main_webp,
} from "../../assets/tn";
import { OORedOOIcon } from "../../components/Icons";

function TNMainPage() {
  return (
      <>
        <div className={style.mainPage}>
          <h1 className={style.heading}>Smarter Programmatic Advertising for Brands and Agencies in Tunisia</h1>
          <p className={style.description}>Maximize campaign performance with the power of accurate data and customizable targeting options.</p>
          <div className={style.actions}>
            <button className={`${style.action} ${style.signUpButton} hs-cta-trigger-button hs-cta-trigger-button-160127419224`}>
              <a
                href="javascript:void(0);"
                target="_self"
                rel="noopener noreferrer"
                className={`${style.actionLink} ${style.signUpLink}`}
              >
                Sign up
              </a>
            </button>
            <button className={style.action}>
              <a
                href="https://share.hsforms.com/1NVnjtOKNQc6yADXtzEjX2wnpo2u"
                target="_blank"
                rel="noopener noreferrer"
                className={style.actionLink}
              >
                Schedule a demo
              </a>
            </button>
          </div>
        </div>
        <section className={style.mainImageSection}>
          <div className={style.container}></div>
          <picture>
            <source
              media='(max-width: 750px)'
              srcSet={tn_main_mobile_avif}
              type='image/avif'
            />
            <source
              media='(max-width: 750px)'
              srcSet={tn_main_mobile_webp}
              type='image/webp'
            />
            <source
              media='(max-width: 750px)'
              srcSet={tn_main_mobile_jpg}
              type='image/jpg'
            />
            <source
              media='(min-width: 750px)'
              srcSet={tn_main_avif}
              type='image/avif'
            />
            <source
              media='(min-width: 750px)'
              srcSet={tn_main_webp}
              type='image/webp'
            />
            <source
              media='(min-width: 750px)'
              srcSet={tn_main_jpg}
              type='image/jpg'
            />
            <img
              alt=''
              src={tn_main_jpg}
              width='100%'
              height={400}
              className={style.mainImage}
            />
          </picture>
          <p className={style.shortDescription}>
            At Intent.ai, we are proud to be an Authorized Buyer for Google, demonstrating our expertise in advertising technology, monetization, targeting and segmentation. As trusted experts, our low-level access to Google's bidding mechanism allows us to provide unparalleled flexibility and unbeatable pricing in the market      </p>
        </section>
        <section className="trusted-by-section">
          <article>
            In Partnership with
            <OORedOOIcon style={{ marginLeft: 16 }}/>
          </article>
        </section>
      </>
  );
}


export default TNMainPage;
