import { TAB_CHANGE } from "_constants";

// // interface IDictionaryState {
// //   [key: string]: object;
// // }
// interface IDictionaryState {
//   data: object;
// }

interface IState {
  tab: number;
}

const initialState: IState = {
  tab: 1,
};

export const tabs = (state: IState = initialState, action: any) => {
  const { type, payload } = action;
  switch (type) {
    case TAB_CHANGE:
      return { ...state, tab: payload };

    default:
      return state;
  }
};
