import React, { lazy, Suspense } from "react";
import SAHeader from "../SAHeader";
import SAMainPage from "../SAMainPage";
import style from './index.module.css';
import { SA_AD_TYPES, SA_FEATURES } from "../../modules/ng/utils";

const Features = lazy(() => import('../../modules/ng/components/Features'));
const SASolutions = lazy(() => import('../SASolutions'));
const SANumberIndicators = lazy(() => import('../SANumberIndicators'));
const SAFeedback = lazy(() => import('../SAFeedback'));
const AdTypes = lazy(() => import('../../modules/ng/components/AdTypes'));

function SAMainContainer () {
  return (
    <div style={{
      background: '#f8f8f8',
      overflow: 'hidden',
    }}>
      <SAHeader />
      <SAMainPage />
      <Suspense fallback={<p>Loading...</p>}>
        <Features
          title={'Why Choose Intent AI as Your Digital Advertising Partner?'}
          description={'Here’s why businesses trust us with their advertising needs:'}
          classes={{
            features: style.features,
            featureItemDesktop: style.featureItemDesktop,
            featureItemSelected: style.featureItemSelected,
            countrySymbol: style.tnSymbol,
          }}
          features={SA_FEATURES}
        />
        <SASolutions />
        {/*<SANumberIndicators />*/}
        {/*<SAFeedback />*/}
        <AdTypes
          contentType={'SA'}
          title={'Start Your Campaigns with a Variety of Ad Formats'}
          description={'Our platform supports a wide range of ad formats to ensure your message reaches your audience in the most effective way:'}
          adTypes={SA_AD_TYPES}
          adTypesClassName={style.adTypes}
          bookDemoProps={{
            redirectLink: 'https://share.hsforms.com/1NVnjtOKNQc6yADXtzEjX2wnpo2u',
            classes: {
              bookDemo: style.bookDemo,
              button: style.button,
            }
          }}
        />
      </Suspense>
    </div>
  )
}

export default SAMainContainer;