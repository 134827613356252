import React, { Component } from "react";
import classnames from "classnames";
import { match } from "react-router-dom";
import { Signup as GlobalSignup } from "@intent-ai/mandal-npm-lib-v2/dist/screens";
import { Header } from "components";
import { selectCurrentTab } from "selectors";
import { RootState } from "types";
import { connect } from "react-redux";
import { tabsValues, DSP_BASE_URL, SSP_BASE_URL } from "_constants";
import { bindActionCreators, Dispatch } from "redux";
import { switchTab } from "actions";
import "./style.scss";
import { injectIntl, InjectedIntl, defineMessages } from "react-intl";

const messages = defineMessages({
  descriptionDSP: {
    id: "Auth.descriptionDSP",
    defaultMessage: "Get the most of your ad campaigns!",
  },
  descriptionSSP: {
    id: "Auth.descriptionSSP",
    defaultMessage: "Get the most of your website monetization tool!",
  },
});

interface IMatchParams {
  id: string;
}

interface IProps {
  tab: number;
  switchTab: Function;
  match: match<IMatchParams>;
  intl: InjectedIntl;
}

class Signup extends Component<IProps> {
  componentDidMount() {
    const {
      props: {
        match: {
          params: { id },
        },
        switchTab,
      },
    } = this;
    switchTab(Number(id));
  }

  render() {
    const {
      props: {
        intl: { formatMessage },
        tab,
      },
    } = this;
    let props = {};
    if (tab === tabsValues.dsp) {
      props = {
        url: DSP_BASE_URL,
        type: "dsp",
        description: formatMessage(messages.descriptionDSP),
      };
    } else {
      props = {
        url: SSP_BASE_URL,
        type: "ssp",
        description: formatMessage(messages.descriptionSSP),
      };
    }
    return (
      <div
        className={classnames("signup-screen", {
          dsp: tab === tabsValues.dsp,
          ssp: tab === tabsValues.ssp,
        })}
      >
        <Header />
        <div className="signu-form">
          <GlobalSignup {...props} />
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  switchTab: bindActionCreators(switchTab, dispatch),
});

const mapStateToProps = (state: RootState) => ({
  tab: selectCurrentTab(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Signup));
