import React, { lazy, Suspense } from "react";
import TNHeader from "../TNHeader";
import TNMainPage from "../TNMainPage";
import style from './index.module.css';
import { TN_AD_TYPES, TN_FEATURES } from "../../modules/ng/utils";

const Features = lazy(() => import('../../modules/ng/components/Features'));
const TNSolutions = lazy(() => import('../TNSolutions'));
const TNNumberIndicators = lazy(() => import('../TNNumberIndicators'));
const TNFeedback = lazy(() => import('../TNFeedback'));
const AdTypes = lazy(() => import('../../modules/ng/components/AdTypes'));

function TNMainContainer () {
  return (
    <div style={{
      background: '#f8f8f8',
      overflow: 'hidden',
    }}>
      <TNHeader />
      <TNMainPage />
      <Suspense fallback={<p>Loading...</p>}>
        <Features classes={{
            features: style.features,
            featureItemDesktop: style.featureItemDesktop,
            featureItemSelected: style.featureItemSelected,
            countrySymbol: style.tnSymbol,
          }}
          features={TN_FEATURES}
        />
        <TNSolutions />
        <TNNumberIndicators />
        <TNFeedback />
        <AdTypes
          adTypes={TN_AD_TYPES}
          adTypesClassName={style.adTypes}
          bookDemoProps={{
            redirectLink: 'https://share.hsforms.com/1NVnjtOKNQc6yADXtzEjX2wnpo2u',
            classes: {
              bookDemo: style.bookDemo,
              button: style.button,
            }
          }}
        />
      </Suspense>
    </div>
  )
}

export default TNMainContainer;