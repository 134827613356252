export { default as profile_default } from "./images/profile_default.jpg";
export { default as inventory } from "./images/inventory.svg";
export { default as inventory_chosen } from "./images/inventory_chosen.svg";
export { default as logo_gray } from "./images/logo_gray.svg";
export { default as logo_black } from "./images/logo_black.svg";
export { default as arrow_right } from "./images/arrow_right.svg";
export { default as copy_chosen } from "./images/copy_chosen.svg";
export { default as checked_green } from "./images/checked_green.svg";
export { default as warning_pink } from "./images/warning_pink.svg";
export { default as image_icon } from "./images/image_icon.svg";
export { default as bg_dsp } from "./images/bg_dsp.jpg";
export { default as bg_ssp } from "./images/bg_ssp.jpg";
export { default as login_bg } from "./images/login_bg.jpg";
export { default as success_background } from "./images/success_background.jpg";
export { default as logo_white } from "./images/full_white_logo.svg";
export { default as card_img_1 } from "./images/card_img_1.jpg";
export { default as card_img_2 } from "./images/card_img_2.jpg";
export { default as card_img_3 } from "./images/card_img_3.jpg";
export { default as card_img_4 } from "./images/card_img_4.jpg";
export { default as credit_card } from "./images/credit-card.svg";
export { default as bidding_dsp_icon } from "./images/bidding_dsp_icon.svg";
export { default as location_dsp_icon } from "./images/location_dsp_icon.svg";
export { default as security_dsp_icon } from "./images/security_dsp_icon.svg";
export { default as bidding_ssp_icon } from "./images/bidding_ssp_icon.svg";
export { default as confidence_ssp_icon } from "./images/confidence_ssp_icon.svg";
export { default as targeting_ssp_icon } from "./images/targeting_ssp_icon.svg";
export { default as platform_image_1 } from "./images/platform_image_1.png";
export { default as laptop_ad } from "./images/laptop_ad.jpg";
export { default as yandex_logo } from "./images/yandex_direct.jpg";
export { default as google_logo } from "./images/google_adsense.png";
export { default as limit_allocation } from "./images/limit_allocation.png";
export { default as access_demand } from "./images/access_demand.webp";
export { default as access_demand_png } from "./images/access_demand_png.png";
export { default as analytics } from "./images/analytics.webp";
export { default as analytics_png } from "./images/analytics_png.png";
export { default as map_image } from "./images/map_image.webp";
export { default as map_image_png } from "./images/map_image_png.png";
export { default as report_image } from "./images/report_image.webp";
export { default as report_image_png } from "./images/report_image_png.png";
export { default as frequency } from "./images/frequency.webp";
export { default as frequency_png } from "./images/frequency_png.png";
export { default as placement } from "./images/placement.webp";
export { default as placement_png } from "./images/placement_png.png";
export { default as slideshow1 } from "./images/slideshow1.png";
export { default as slideshow2 } from "./images/slideshow2.png";
export { default as slideshow3 } from "./images/slideshow3.png";
export { default as slideshow4 } from "./images/slideshow4.png";
export { default as logo } from "./images/logo.svg";
export { default as footer } from "./images/footer.jpg";
export { default as cardImageDsp } from "./images/card-image-dsp.jpg";
export { default as cardImageSsp } from "./images/card-image-ssp.jpg";
export { default as allocation } from "./images/allocation.webp";
export { default as banner } from "./images/banner.webp";
export { default as mobile } from "./images/mobile.webp";
export { default as html } from "./images/html.webp";
export { default as adaptive } from "./images/adaptive.webp";
export { default as banner_png } from "./images/banner.png";
export { default as mobile_png } from "./images/mobile.png";
export { default as html_png } from "./images/html.png";
export { default as adaptive_png } from "./images/adaptive.png";
export { default as bitmap } from "./images/Bitmap.png";
export { default as random_guy } from "./images/random_guy.webp";
export { default as dsp_bg } from "./images/dsp_bg.jpg";
export { default as adaptive1 } from "./images/adaptive1.svg";
export { default as html1 } from "./images/html1.svg";
export { default as banner1 } from "./images/banner1.svg";
export { default as icon_menu } from "./images/icon_menu.svg";
export { default as icon_menu_black } from "./images/icon_menu.svg";

export { default as icon_close } from "./images/icon_close.svg";
export { default as img_feautred } from "./images/img-feautred.webp";
export { default as logo_1 } from "./images/logo-1.webp";
export { default as logo_2 } from "./images/logo-2.webp";
export { default as logo_3 } from "./images/logo-3.webp";
export { default as logo_4 } from "./images/logo-4.webp";
export { default as logo_5 } from "./images/logo-5.webp";
export { default as img_background } from "./images/img_background.webp";
export { default as group } from "./images/group.webp";
export { default as group_4 } from "./images/group-4.webp";
export { default as website_image } from "./images/nigeria-website-image.png";
export { default as airtel_logo } from "./images/airtel-logo.png";
export { default as img_feature_1 } from './images/img-feature-1.png';
export { default as img_feature_2 } from './images/img-feature-2.png';
export { default as img_feature_3 } from './images/img-feature-3.png';
export { default as img_feature_4 } from './images/img-feature-4.png';
export { default as img_default_feature } from './images/default_feature.png';
export { default as website_nigeria_image_mobile } from './images/nigeria-website-image-mobile.png';
export { default as nigeria_flag } from './images/nigeria_flag.png';
export { default as ng_orange_pattern } from './images/ng_orange_pattern.png';
export { default as ng_banner_ads } from './images/ng_banner_ads.png';
export { default as ng_html_ads } from './images/ng_html_ads.png';
export { default as ng_dynamic_ads } from './images/ng_dynamic_ads.png';
export { default as ng_banner_ads_mobile } from './images/ng_banner_ads_mobile.png';
export { default as ng_html_ads_mobile } from './images/ng_html_ads_mobile.png';
export { default as ng_dynamic_ads_mobile } from './images/ng_dynamic_ads_mobile.png';
export { default as ng_flag_mobile } from './images/nigeria_flag_mobile.png';
export { default as ng_website_image_desktop_webp } from './images/nigeria-website-image.webp';
export { default as ng_website_image_mobile_webp } from './images/nigeria-website-image-mobile.webp';
export { default as airtel_logo_webp } from './images/airtel-logo.webp';
export { default as feature_targeting } from './images/feature_targeting.webp';
export { default as feature_grouping } from './images/feature_grouping.webp';
export { default as feature_reporting } from './images/feature_reporting.webp';
export { default as feature_support } from './images/feature_support.webp';
export { default as ng_banner_ads_webp } from './images/ng_banner_ads.webp';
export { default as ng_banner_ads_mobile_webp } from './images/ng_banner_ads_mobile.webp';
export { default as ng_html_ads_webp } from './images/ng_html_ads.webp';
export { default as ng_html_ads_mobile_webp } from './images/ng_html_ads_mobile.webp';
export { default as ng_dynamic_ads_webp } from './images/ng_dynamic_ads.webp';
export { default as ng_dynamic_ads_mobile_webp } from './images/ng_dynamic_ads_mobile.webp';
export { default as airtel } from './images/airtel.svg';
export { default as ng_voice_ads } from './images/voice_ads.png';

