import React from "react";
import { img_feautred } from "assets";

import "./style.scss";
const itemsArray = [
  {
    title: "Target specific App users",
    description:
      "Do you want to target the users of a specific app? We have got you covered",
  },
  {
    title: "Segmentation flexibility",
    description:
      "Hit your competitors' clients, specific app and website users with tailored advertising. Mix and match the resources and interests",
  },
  {
    title: "Advanced Reporting",
    description:
      "Dig deep into your campaign performance reports. Evaluate metrics like ad placements, traffic sources, and more. Understand the data and manage your campaigns easily",
  },
  {
    title: "Target selected website visitors",
    description: "Target any website's visitors with ease",
  },
  {
    title: "Manage your ads' placement",
    description:
      "Enjoy the exclusive advantage of managing which websites display your ads",
  },
  {
    title: "2 platforms 1 ad account",
    description:
      "Eliminate the stress of managing two separate ad accounts with mismatched reporting data by utilizing one central management point for all data",
  },
  {
    title: "Easy and fast audience management",
    description:
      "Effortlessly share audience groups between multiple ad campaigns with just a couple of clicks",
  },
  {
    title: "Expert assistance in creative design and campaign management",
    description:
      "Full support for A-Z set-up and campaign creation offered by our experts.",
  },
];
const FeaturesDSP = () => {
  return (
    <section className="features-dsp">
      <div className="access-demand-dsp">
        <p className="access-demand-title">
          Digital Advertising has never been easier{" "}
        </p>
        <p className="access-demand-description">
          Created with advertisers in mind.
        </p>
      </div>
      <div className="access-demand-inner">
        <div className="access-demand-left-section">
          <ul>
            {itemsArray.map((item, index) => {
              return (
                <Item
                  title={item.title}
                  description={item.description}
                  key={item.title}
                />
              );
            })}
          </ul>
        </div>
        <div className="access-demand-right-section">
          <img loading="lazy" src={img_feautred} alt="" />
        </div>
      </div>
    </section>
  );
};

export default FeaturesDSP;


interface IProps {
  title: string;
  description: string;
}

function Item({ title, description }: IProps) {
  const [isOpen, setIsOpen] = React.useState(false);
  return (
    <li onClick={() => setIsOpen(isOpen => !isOpen)}>
      <p className={isOpen ? "open" : ""}>{title}</p>
      <p className={isOpen ? "open" : ""}>{description}</p>
    </li>
  );
}
