// @ts-nocheck
export { default as flag } from './images/sa/sa-flag.png';
export { default as sa_main_jpg } from './images/sa/img_home_1.png';
export { default as sa_main_webp } from './images/sa/main.webp';
export { default as sa_main_avif } from './images/sa/main.avif';
export { default as sa_main_mobile_jpg } from './images/sa/main_mobile.jpg';
export { default as sa_main_mobile_webp } from './images/sa/main_mobile.webp';
export { default as sa_main_mobile_avif } from './images/sa/main_mobile.avif';
export { default as sa_solution_1_webp } from './images/sa/solution-1.webp';
export { default as sa_solution_1_avif } from './images/sa/solution-1.avif';
export { default as sa_solution_2_webp } from './images/sa/solution-2.webp';
export { default as sa_solution_2_avif } from './images/sa/solution-2.avif';
export { default as sa_solution_3_webp } from './images/sa/solution-3.webp';
export { default as sa_solution_3_avif } from './images/sa/solution-3.avif';
export { default as sa_solution_4_webp } from './images/sa/solution-4.webp';
export { default as sa_solution_4_avif } from './images/sa/solution-4.avif';
export { default as sa_video_png } from './images/sa/ic_video.png';
export { default as sa_native_png } from './images/sa/ic_mobile.png';
export { default as sa_banner_png } from './images/sa/ic_html.png';
export { default as sa_banner_webp } from './images/sa/banner.webp';
export { default as sa_banner_avif } from './images/sa/banner.avif';
export { default as sa_banner_mobile_png } from './images/sa/banner_mobile.png';
export { default as sa_banner_mobile_webp } from './images/sa/banner_mobile.webp';
export { default as sa_banner_mobile_avif} from './images/sa/banner_mobile.avif';
export { default as sa_html_png } from './images/sa/ic_adaptive.png';
export { default as sa_html_webp } from './images/sa/html.webp';
export { default as sa_html_avif } from './images/sa/html.avif';
export { default as sa_html_mobile_png } from './images/sa/html_mobile.png';
export { default as sa_html_mobile_webp } from './images/sa/html_mobile.webp';
export { default as sa_html_mobile_avif } from './images/sa/html_mobile.avif';
export { default as sa_dynamic_ads_png } from './images/sa/dynamic_ads.png';
export { default as sa_dynamic_ads_webp } from './images/sa/dynamic_ads.webp';
export { default as sa_dynamic_ads_avif } from './images/sa/dynamic_ads.avif';
export { default as sa_dynamic_ads_mobile_png } from './images/sa/dynamic_ads_mobile.png';
export { default as sa_dynamic_ads_mobile_webp } from './images/sa/dynamic_ads_mobile.webp';
export { default as sa_dynamic_ads_mobile_avif } from './images/sa/dynamic_ads_mobile.avif';
export { default as sa_airtel } from './images/sa/airtel-logo-red.png';
export { default as sa_ooredoo } from './images/sa/logo-2.png';
export { default as sa_kimprinski } from './images/sa/logo-3.png';
export { default as sa_pepsi } from './images/sa/logo-4.png';
export { default as sa_betway } from './images/sa/logo-7.png';
export { default as sa_menu } from './images/sa/logo-5.png';
export { default as sa_google } from './images/sa/airtel-logo-red-copy.png';
export { default as sa_verve } from './images/sa/verve.png';
export { default as sa_smatoo } from './images/sa/logo-3-copy.png';
export { default as sa_pubnative } from './images/sa/pubnative.png';
export { default as sa_taboola } from './images/sa/logo-5-copy.png';




