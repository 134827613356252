import React from 'react';
import styles from './index.module.css';
import { AD_TYPES, SA_AD_EXCHANGE_PARTNERS, SA_ADV_PARTNERS } from "../../utils";
import { BookDemo } from "../index";
import classnames from "classnames";
import style from "../../../../sa-bmp/SAMainPage/index.module.css";
import SABookDemo from "../../../../sa-bmp/components/SABookDemo";

type Props = {
  title?: string;
  description?: string;
  contentType?: 'SA' | 'NG' | 'TN';
  adTypes?: Array<{
    id: number;
    title: string;
    description: string;
    image: string;
    imageWebp?: string;
    imageAvif?: string;
    alt: string;
    mobImage?: string;
    mobImageWebp?: string;
    mobImageAvif?: string;
    size: number;
    mobSize: number;
  }>;
  adTypesClassName?: string;
  bookDemoProps?: {
    redirectLink?: string;
    classes?: {
      bookDemo?: string;
      button?: string;
    }
  }
}

const AdTypes = ({
  adTypes = AD_TYPES,
  title,
  description,
  contentType,
  adTypesClassName = '',
  bookDemoProps = {},
  }: Props) => {
  return (
    <section className={classnames(styles.typesOfAds, adTypesClassName)}>
      {contentType === "SA" ? (
        <>
          <h1 className={styles.headline}>Start Your Campaigns with</h1>
          <h1 className={styles.headline}>a Variety of Ad Formats</h1>
          <p className={styles.description}>Our platform supports a wide range of ad formats to ensure your message</p>
          <p className={styles.description}>reaches your audience in the most effective way:</p>
          <button className={style.action} style={{marginTop: '24px'}}>
            <a
              href="https://docs.intent.ai/"
              target="_blank"
              rel="noopener noreferrer"
              className={style.actionLink}
            >
              Learn More
            </a>
          </button>
        </>
      ) : (
        <>
          <h1 className={styles.headline}>Multiple ad formats to help you stand out</h1>
          <p className={styles.description}>Provide your target audience with the most attractive ads</p>
        </>
      )
      }
      <div className={styles.adBox}>
        {adTypes.map(ad => (
          <div key={ad.id} className={styles.adType}>
                <picture>
                  {ad.imageAvif && (
                    <source
                      srcSet={ad.imageAvif}
                      media='(min-width: 520px)'
                      type='image/avif'
                      //@ts-ignore
                      width={ad.size}
                    />
                  )}
                  <source
                    srcSet={ad.imageWebp}
                    media='(min-width: 520px)'
                    type='image/webp'
                    //@ts-ignore
                    width={ad.size}
                  />
                  {ad.mobImageAvif && (
                    <source
                      srcSet={ad.mobImageAvif}
                      media='(max-width: 520px)'
                      type='image/avif'
                      //@ts-ignore
                      width={ad.mobSize}
                    />
                  )}
                  <source
                    srcSet={ad.mobImageWebp}
                    media='(max-width: 520px)'
                    type='image/webp'
                    //@ts-ignore
                    width={ad.mobSize}
                  />
                  <source
                    srcSet={ad.image}
                    media='(min-width: 520px)'
                    type='image/png'
                    //@ts-ignore
                    width={ad.size}
                  />
                  <source
                    srcSet={ad.mobImage}
                    media='(max-width: 520px)'
                    type='image/png'
                    //@ts-ignore
                    width={ad.mobSize}
                  />
                  <img
                    src={ad.image}
                    alt={ad.alt}
                    width={ad.size}
                    height='100%'
                  />
                </picture>
                <div className={styles.contentWrapper}>
                  <h4 className={styles.adTitle}>{ad.title}</h4>
                  <p className={styles.adDescription}>{ad.description}</p>
                </div>
              </div>
            ))}
          </div>
          {contentType === 'SA' ? (
            <div>
              <p className={styles.description}
                 style={{ fontSize: '24px', textAlign: 'center', marginTop: '90px', marginBottom: '30px' }}>Our Partner
                Advertiser:</p>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                {SA_ADV_PARTNERS.map(item => (
                  <img
                    src={item.image}
                    alt={item.alt}
                  />
                ))}
              </div>
              <div style={{
                backgroundColor: '#eff0f0',
                marginTop: '90px',
                padding: '45px 68px',
                width: '100%',
                borderRadius: '38px'
              }}>
                <p style={{
                  color: '#202124',
                  fontSize: '36px',
                  lineHeight: '40px',
                  fontWeight: 500,
                  textAlign: 'center',
                }}>Raise your visibility and scalability, drive quality traffic to <br /> your website, and position
                  your brand for success in the <br /> Saudi market.</p>
              </div>
              <p className={styles.description}
                 style={{ fontSize: '24px', textAlign: 'center', marginTop: '70px', marginBottom: '30px' }}>Our Partner Ad Exchanges:</p>
              <div style={{ display: 'flex', justifyContent: 'space-between', padding: '0 90px' }}>
                {SA_AD_EXCHANGE_PARTNERS.map(item => (
                  <img
                    src={item.image}
                    alt={item.alt}
                  />
                ))}
              </div>
              <SABookDemo />
            </div>

          ) : (
            <div className={styles.bookDemo}>
              <BookDemo {...bookDemoProps} />
            </div>
          )}
    </section>
  )
}

export default AdTypes;