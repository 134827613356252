import React from "react";
import { airtel_logo, airtel_logo_webp } from "assets";
import "./style.scss";
import { IntentLogoIcon } from "../../components/Icons";

export const TrustedBySection = () => {
  return (
    <section className="trusted-by-section">
      <article>
        Powered by
        <IntentLogoIcon style={{ marginLeft: 20 }} />
      </article>
    </section>
  );
};
