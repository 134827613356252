import React from "react";
import "./style.scss";

const TrafficSSP = () => {
  return (
    <section className="traffic-wrapper ">
      <div className="traffic-wrapper-inner wrapper-container">
        <div className="traffic-title">Why we are unique</div>
        <div className="traffic-description">
          Set up the schedule or frequency when you want your.
        </div>
        <div className="traffic-box-container">
          <div className="traffic-box">
            <p>01</p>
            <p>100% Traffic Utilization</p>
            <p>Leave no empty placements</p>
          </div>
          <div className="traffic-box">
            <p>02</p>
            <p>Access To Diverse Demand</p>
            <p>Get high competition for your ad units</p>
          </div>
          <div className="traffic-box">
            <p>03</p>
            <p>Analytics And Filters</p>
            <p>Manage websites where your ads appear</p>
          </div>
        </div>
      </div>
    </section>
  );
};
export default TrafficSSP;
