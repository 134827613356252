import React, { FunctionComponent, useMemo } from "react";
import { Routes } from "Routes";
import { connect } from "react-redux";
import { switchTab } from "actions/tabs.actions";
import { Dispatch, bindActionCreators } from "redux";
import { RootState } from "types";
import { tabsValues } from "_constants";
import classNames from "classnames";
import { selectCurrentTab } from "selectors";
import { Footer } from "components/Footer";
// import { useLocation } from "react-router";
import './style.scss';
import NGMainContainer from "../../ng-bmp/NGMainContainer";
import TNMainContainer from "../../tns-bmp/TNMainContainer";
import SAMainContainer from "../../sa-bmp/SAMainContainer";

interface IProps {
  switchTab: Function;
  tab: number;
}

const componentPerCountry = {
  ng: NGMainContainer,
  am: Routes,
  tn: TNMainContainer,
  sa: SAMainContainer,
  'airtelads': NGMainContainer,
}

const MainContainer: FunctionComponent<IProps> = ({ tab }) => {

  const Component = useMemo(() => {
    if (window.location.hostname.split('.')[0] === 'localhost') {
      return componentPerCountry['am'];
    }
    // @ts-ignore
    return componentPerCountry[window.location.hostname.split('.')[0] || 'am'];
  }, []);

  const footerProps = useMemo(() => {
    const prefix = window.location.hostname.split('.')[0];
    if (prefix === 'ng' || prefix === 'airtelads') {
      return {
        classes: {
          footer: 'footer-tn',
        },
        compact: true,
        companyDetailsPerCountry: (
          <div className='footer-company-details-trusted'>
            <p className='trusted-by-airtel'>In Partnership With telcos</p>
          </div>
        ),
        linkedInURL: 'https://www.linkedin.com/company/intent-ai-nigeria/',
        phone: '+234 902 243 4093',
        formattedPhoneNum: '+2349022434093',
      };
    }

    if (prefix === 'tn') {
      return {
        classes: {
          footer: 'footer-tn',
          socialIcon: 'social-icon-tn',
        },
      }
    }

    if (prefix === 'sa') {
      return {
        classes: {
          footer: 'footer-sa',
          socialIcon: 'social-icon-tn',
        },
      }
    }

    return {
      phone: '+374 (55) 756078',
      formattedPhoneNum: '+37455756078',
    }
  }, []);

  return (
    <div
      className={classNames("main-container", {
        dsp: tab === tabsValues.dsp,
        ssp: tab === tabsValues.ssp,
      })}
    >
      <main>
        <Component />
      </main>
      <Footer
        facebookURL='https://www.facebook.com/intent.ai.global'
        linkedInURL='https://www.linkedin.com/company/intent-ai/'
        privacyPolicyURL='/privacy-policy'
        termsOfServiceURL='/terms-of-service'
        {...footerProps}
      />
    </div>
  );
}

const mapStateToProps = (state: RootState) => ({
  tab: selectCurrentTab(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  switchTab: bindActionCreators(switchTab, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MainContainer);
