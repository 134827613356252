// Async action postfyxes
export const START_POST = "_START";
export const FAIL_POST = "_FAIL";
export const SUCCESS_POST = "_SUCCESS";

export const DICTIONARY_LOADED_SUCCESS = "DICTIONARY_LOADED_SUCCESS";
export const DICTIONARY_FIELD_GET = "DICTIONARY_FIELD_GET";

/**
 * NOTE: Actiontype formula: [reducer_name][...nested_data_names][action]
 */

// Authentication
export const AUTH_CHECK_INVITATION_TOKEN_FAIL =
  "AUTH_CHECK_INVITATION_TOKEN_FAIL";
export const AUTH_CHECK_REGISTRATION_TOKEN_FAIL =
  "AUTH_CHECK_REGISTRATION_TOKEN_FAIL";
export const AUTH_REGISTER_SUCCESS = "AUTH_REGISTER_SUCCESS";
export const AUTH_SET_JWT_TOKEN = "AUTH_SET_JWT_TOKEN";
export const AUTH_TOKEN_INVALID = "AUTH_TOKEN_INVALID";
export const RT_SPACE_INVITATION_USER_NOT_VERIFIED =
  "RT_SPACE_INVITATION_USER_NOT_VERIFIED";
export const GET_DATA_FROM_DICTIONARY = "GET_DATA_FROM_DICTIONARY";
export const AUTH_GET_REGISTRATION = "AUTH_GET_REGISTRATION";
export const AUTH_GET_REGISTRATION_START = "AUTH_GET_REGISTRATION_START";
export const AUTH_GET_REGISTRATION_SUCCESS = "AUTH_GET_REGISTRATION_SUCCESS";
export const AUTH_GET_REGISTRATION_FAIL = "AUTH_GET_REGISTRATION_FAIL";

export const AUTH_GET_USER = "AUTH_GET_USER";
export const AUTH_GET_USER_SUCCESS = "AUTH_GET_USER_SUCCESS";
export const AUTH_GET_USER_FAIL = "AUTH_GET_USER_FAIL";

export const TAB_CHANGE = "TAB_CHANGE";

export const GET_CAMPAIGN_TARGETING_INFO = "GET CAMPAIGN TARGETING INFO";
